<script setup lang="ts">
import { InputTypes } from "../../libs/types/AppTypes";
import { computed, ref } from "vue";

interface Props {
  id: string;
  type?: InputTypes;
  prefix?: string;
  modelValue?: string | number | null;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  errorMessage?: string;
  invalid?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: InputTypes.text,
  placeholder: "Placeholder",
  disabled: false,
  readonly: false,
  errorMessage: "Error",
  prefix: "€",
  modelValue: null,
});

const value = computed({
  get() {
    if (props.modelValue === null) return "";
    return !isActive.value
      ? `${props.modelValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      : props.modelValue;
  },
  set(value) {
    //if you want to remove letters just add .replace(/[A-Za-z]/g, "")
    const onlyNumbers = Number(value?.toString().replaceAll(",", ""));
    emit("update:modelValue", onlyNumbers);
    emit("keyPress", onlyNumbers);
  },
});

const currencySymbol = computed(() => {
  if (props.prefix === "EUR") {
    return "€";
  } else if (props.prefix === "GBP") {
    return "£";
  } else {
    return "€";
  }
});

const isActive = ref(false);

const emit = defineEmits<{
  (e: "update:modelValue", payload: unknown): void;
  (e: "keyPress", payload: unknown): void;
}>();

const invalidCss = computed(() => {
  return props.invalid ? "border-red" : "border-grey-400";
});

function onKeydown(event: KeyboardEvent) {
  const key = event.key;
  // Allow numbers, the backspace key, the delete key, and the arrow keys
  if (
    /^\d$/.test(key) ||
    key === "Backspace" ||
    key === "Delete" ||
    key === "ArrowLeft" ||
    key === "ArrowRight"
  ) {
    return true;
  } else {
    event.preventDefault();
  }
}
const showPrefix = computed(() => {
  return props.readonly || !isActive.value;
});
</script>
<template>
  <div class="relative">
    <span
      v-if="showPrefix"
      class="absolute text-xs lg:text-sm top-[.5625rem] left-[.625rem] lg:top-[.8125rem] lg:left-[.7125rem]"
      :class="{ 'text-black': readonly }"
    >
      {{ currencySymbol }}
    </span>
    <input
      :id="id"
      :data-cy="id"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      @focus="isActive = true"
      @blur="isActive = false"
      @keydown="onKeydown"
      class="w-full border bg-white text-xs lg:text-sm focus:outline-none placeholder:text-black placeholder:disabled:text-grey-500 disabled:text-grey-500 disabled:bg-grey-200 read-only:bg-grey-200 disabled:cursor-not-allowed read-only:pointer-events-none"
      :class="[invalidCss, showPrefix ? 'py-2 pr-2 pl-5 lg:py-3 lg:pr-3 lg:pl-6' : 'p-2 lg:p-3']"
      v-model="value"
    />
    <div v-show="invalid" class="text-red text-xs md:text-sm text-left absolute">
      {{ errorMessage }}
    </div>
  </div>
</template>
