<script setup lang="ts">
import { computed, ref } from "vue";
import { InputTypes } from "@/libs/types/AppTypes";

interface Props {
  id: string;
  suffix: string;
  modelValue?: string | number | null;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  errorMessage?: string;
  invalid?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "Placeholder",
  disabled: false,
  readonly: false,
  errorMessage: "Error",
  modelValue: null,
});

const value = computed({
  get() {
    if (props.modelValue === null) return "";
    return !isActive.value
      ? `${props.modelValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${props.suffix}`
      : props.modelValue;
  },
  set(value) {
    //if you want to remove letters just add .replace(/[A-Za-z]/g, "")
    const onlyNumbers = Number(value.toString().replaceAll(",", "").replace(props.suffix, ""));
    emit("update:modelValue", onlyNumbers);
    emit("keyPress", onlyNumbers);
  },
});
const isActive = ref(false);

const emit = defineEmits<{
  (e: "update:modelValue", payload: string | number): void;
  (e: "keyPress", payload: string | number): void;
}>();

const invalidCss = computed(() => {
  return props.invalid ? "border-red" : "border-grey-400";
});

function onKeydown(event: KeyboardEvent) {
  const key = event.key;
  // Allow numbers, the backspace key, the delete key, and the arrow keys
  if (
    /^\d$/.test(key) ||
    key === "Backspace" ||
    key === "Delete" ||
    key === "ArrowLeft" ||
    key === "ArrowRight"
  ) {
    return true;
  } else {
    event.preventDefault();
  }
}
</script>
<template>
  <div>
    <input
      :id="id"
      :data-cy="id"
      :type="InputTypes.text"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      @focus="isActive = true"
      @blur="isActive = false"
      @keydown="onKeydown"
      class="w-full border bg-white p-2 lg:p-3 text-xs lg:text-sm focus:outline-none placeholder:text-black placeholder:disabled:text-grey-500 disabled:text-grey-500 disabled:bg-grey-200 disabled:cursor-not-allowed read-only:bg-grey-200 read-only:pointer-events-none"
      :class="[invalidCss]"
      v-model="value"
    />
    <div v-show="invalid" class="text-red text-xs lg:text-sm text-left absolute">
      {{ errorMessage }}
    </div>
  </div>
</template>
