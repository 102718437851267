class Permissions {
  private perm: string[] = [];

  get list(): string[] {
    return this.perm;
  }

  set list(perm: string[]) {
    this.perm = perm;
  }
}

export const permissions = new Permissions();
