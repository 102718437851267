<script setup lang="ts">
import { computed, ref } from "vue";

interface Props {
  id: string;
  modelValue?: string | number | null;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "",
  disabled: false,
  modelValue: null,
});

const emit = defineEmits<{
  (e: "update:modelValue", payload: number): void;
  (e: "keyPress", payload: number): void;
}>();

const invalidCss = computed(() => {
  return props.invalid ? "border-red" : "border-grey-400";
});

const isActive = ref(false);

const value = computed({
  get() {
    if (props.modelValue === null) return "";
    return !isActive.value
      ? `${props.modelValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      : props.modelValue;
  },
  set(value) {
    //if you want to remove letters just add .replace(/[A-Za-z]/g, "")
    const onlyNumbers = Number(value?.toString().replaceAll(",", ""));
    emit("update:modelValue", onlyNumbers);
    emit("keyPress", onlyNumbers);
  },
});
</script>
<template>
  <div class="relative">
    <input
      type="text"
      :id="`table-input-${id}`"
      :data-cy="`table-input-${id}`"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="[invalidCss]"
      @focus="isActive = true"
      @blur="isActive = false"
      class="w-full border border-grey-400 h-8 p-3 bg-white text-xs lg:text-sm focus:outline-none placeholder:text-black disabled:text-black focus:border-yellow-dark disabled:bg-grey-100 disabled:cursor-not-allowed"
      v-model="value"
    />
  </div>
</template>
