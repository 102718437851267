<script setup lang="ts">
import type { InputTypes } from "../../libs/types/AppTypes";
import { computed } from "vue";

interface Props {
  id: string;
  type: InputTypes;
  modelValue?: unknown;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  errorMessage?: string;
  invalid?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "Placeholder",
  disabled: false,
  readonly: false,
  errorMessage: "Error",
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
    emit("keyPress", value);
  },
});

const emit = defineEmits<{
  (e: "update:modelValue", payload: unknown): void;
  (e: "keyPress", payload: unknown): void;
}>();

const invalidCss = computed(() => {
  return props.invalid ? "border-red" : "border-grey-400";
});
</script>
<template>
  <div>
    <input
      :id="id"
      :data-cy="id"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      class="w-full border bg-white p-2 lg:p-3 text-xs lg:text-sm focus:outline-none placeholder:text-black placeholder:disabled:text-grey-500 disabled:text-grey-500 disabled:bg-grey-200 disabled:cursor-not-allowed read-only:bg-grey-200 readonly:pointer-events-none"
      :class="[invalidCss]"
      v-model="value"
    />
    <div v-show="invalid" class="text-red text-xs lg:text-sm text-left absolute">
      {{ errorMessage }}
    </div>
  </div>
</template>
