<script lang="ts" setup>
import type { ISortPayload } from "@/libs/types/AppTypes";
import { computed, ref, watch } from "vue";

/** ascending is true, descending means false */

interface IProps {
  sortedProperty: string;
  activeColumn: string | null;
  dataType: "string" | "number";
}

const props = withDefaults(defineProps<IProps>(), {
  dataType: "string",
});

const emit = defineEmits<{ (e: "sort", payload: ISortPayload): void }>();

const active = ref<null | boolean>(null);

const sort = (ascending: boolean) => {
  active.value = ascending;

  emit("sort", {
    property: props.sortedProperty,
    ascending,
    dataType: props.dataType,
  });
};

watch(
  () => props.activeColumn,
  (newVal) => {
    if (newVal !== props.sortedProperty) active.value = null;
  },
);

const activeIsNullCss = computed(() => (active.value === null ? "text-grey-600" : ""));
</script>
<template>
  <section class="flex flex-col">
    <font-awesome-icon
      :icon="['fas', 'chevron-up']"
      class="text-sm cursor-pointer"
      :class="[active === true ? 'text-black' : 'text-grey-600', activeIsNullCss]"
      @click="sort(true)"
    />

    <font-awesome-icon
      :icon="['fas', 'chevron-down']"
      class="text-sm cursor-pointer"
      :class="[active === false ? 'text-black' : 'text-grey-600', activeIsNullCss]"
      @click="sort(false)"
    />
  </section>
</template>
