import { Subject } from "rxjs";

export interface IToastNotification {
  type: ToastNotificationType;
  message: string;
}

export enum ToastNotificationType {
  Error = "error",
  Warning = "warning",
  Success = "success",
}

class ToastNotification {
  private signal = new Subject<IToastNotification>();

  get signal$() {
    return this.signal;
  }
}

const toastNotificationService = new ToastNotification();

export default toastNotificationService;
