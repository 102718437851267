<script setup lang="ts">
import { useRouter } from "vue-router";

const route = useRouter();

function goBack() {
  route.back();
}
</script>
<template>
  <section
    class="w-7 h-7 lg:w-9 lg:h-9 bg-white flex items-center justify-center cursor-pointer"
    @click="goBack"
  >
    <font-awesome-icon :icon="['fas', 'angle-left']"></font-awesome-icon>
  </section>
</template>
