<script setup lang="ts">
import { Subject, takeUntil } from "rxjs";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import type { IToastNotification } from "../../services/ToastNotification/ToastNotification";
import toastNotificationService, {
  ToastNotificationType,
} from "../../services/ToastNotification/ToastNotification";

const isOpened = ref(false);
const message = ref<string | null>(null);
const type = ref(ToastNotificationType.Success);

const componentDestroy = new Subject<string>();

const close = () => (isOpened.value = false);

onMounted(() => {
  toastNotificationService.signal$
    .pipe(takeUntil(componentDestroy))
    .subscribe((toast: IToastNotification) => {
      message.value = toast.message;
      type.value = toast.type;
      isOpened.value = true;
      setTimeout(() => (isOpened.value = false), 10000);
    });
});

onBeforeUnmount(() => {
  componentDestroy.next("");
});

const typeCss = computed(() => {
  switch (type.value) {
    case ToastNotificationType.Success:
      return {
        border: "border-green",
        icon: "circle-check",
        color: "text-green",
      };
    case ToastNotificationType.Error:
      return {
        border: "border-red",
        icon: "circle-xmark",
        color: "text-red",
      };
    case ToastNotificationType.Warning:
      return {
        border: "border-yellow",
        icon: "circle-exclamation",
        color: "text-yellow",
      };

    default:
      return {
        border: "border-green",
        icon: "circle-check",
        color: "text-green",
      };
  }
});
</script>
<template>
  <div
    ref="container"
    id="toast-notification"
    data-cy="toast-notification"
    class="toast-container fixed bg-black text-white"
    v-show="isOpened"
  >
    <section
      style="max-width: 406px"
      class="p-6 border-l-4 flex items-center"
      :class="[typeCss.border]"
    >
      <p class="mb-0 text-sm mr-4">
        {{ message }}
      </p>
      <font-awesome-icon :icon="['far', typeCss.icon]" :class="[typeCss.color]" @click="close" />
    </section>
  </div>
</template>

<style scoped>
.toast-container {
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  transition-delay: 2s;
  z-index: 999;
}
</style>
