<script setup lang="ts">
import { computed } from "vue";

interface Props {
  id: string;
  modelValue?: boolean;
  disabled?: boolean;
  label: string;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  id: "id",
  modelValue: false,
  label: "some label",
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const emit = defineEmits<{ (e: "update:modelValue", payload: boolean): void }>();
</script>
<template>
  <div class="flex items-center w-full">
    <label :for="id" class="flex items-center cursor-pointer">
      <!-- toggle -->
      <div class="relative">
        <!-- input -->
        <input
          type="checkbox"
          :id="id"
          :data-cy="id"
          :disabled="disabled"
          class="sr-only"
          v-model="value"
        />
        <!-- line -->
        <div class="switch-container block bg-grey-300 rounded-full h-6 w-12"></div>
        <!-- dot -->
        <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
      </div>
      <div class="text-sm ml-3">{{ label }}</div>
    </label>
  </div>
</template>
<style scoped>
input:checked ~ .dot {
  transform: translateX(130%);
  background-color: #fff;
}
input:checked ~ .switch-container {
  background-color: #308dff;
}
input:disabled ~ .switch-container {
  background-color: #e2e2e2;
  cursor: not-allowed;
}
input:checked:disabled ~ .dot {
  background-color: #fff;
  cursor: not-allowed;
}

input:checked:disabled ~ .switch-container {
  background-color: #808285;
}
</style>
