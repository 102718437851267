<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import type { Constants } from "@/services/Constants/Constants";
import { computed, inject } from "vue";
import { useRoute } from "vue-router";
import { router } from "@/router/routes";
import ProfileDropdown from "@/components/ProfileDropdown.vue";
import UserManagementDropdown from "@/components/UserManagementDropdown.vue";

import { usePermission } from "@/composables/usePermission";
import {
  MenuCategory,
  Acquisition,
  Wholesale,
  FleetAdmin,
  MarketData,
  Reporting,
  FleetControl,
  Tools,
} from "@/libs/types/AppTypes";

import type { Permission } from "@/libs/types/AppTypes"; // Ensure this is correct

const constants = inject("constants") as Constants;

const route = useRoute();

type MenuCategoryPermissionMap = {
  [key in MenuCategory]: Permission[];
};

const menuCategoryPermissions: MenuCategoryPermissionMap = {
  [MenuCategory.MENU_ACQUISITION]: [
    Acquisition.NEWCARS,
    Acquisition.USEDCARS,
    Acquisition.AUCTIONS,
  ],
  [MenuCategory.MENU_FLEET_CONTROL]: [
    FleetControl.FLEETANALYSIS,
    FleetControl.SALESPERFORMANCE,
    FleetControl.FLEETPLANNING,
    FleetControl.FLEETDEPRECIATION,
  ],
  [MenuCategory.MENU_MARKET_DATA]: [
    MarketData.GEOSEARCH,
    MarketData.GEOSALES,
    MarketData.GEOANALYSIS,
    MarketData.NEWCARSALES,
  ],
  [MenuCategory.MENU_FLEET_ADMIN]: [
    FleetAdmin.FLEETADMINISTRATION,
    FleetAdmin.ANOMALYDETECTION,
    FleetAdmin.UPDATELOG,
    FleetAdmin.FLEETSETTINGS,
  ],
  [MenuCategory.MENU_WHOLESALE]: [Wholesale.WHOLESALE],
  [MenuCategory.MENU_REPORTING]: [Reporting.QREVIEW, Reporting.ABSVALUATIONS],
  [MenuCategory.MENU_TOOLS]: [Tools.VINDECODE],
};

const hasAccessToMenuCategory = (menuCategory: MenuCategory) => {
  const permissionSet = menuCategoryPermissions[menuCategory];
  return permissionSet.some((permission) => role.hasAccess(permission));
};

const showSettings = computed(() => {
  return constants.userInfo.superAdmin;
});

const role = usePermission();

const navBarLinks = {
  home: ["Home"],
  wholesale: ["Wholesale"],
  acquisition: [
    "OffersDashboard",
    "OffersBySupplier",
    "OffersList",
    "ProcessOffer",
    "UsedCars",
    "AuctionsDashboard",
    "AuctionListByStatus",
    "ProcessAuction",
  ],
  vinCheck: ["VinDecode"],
  fleetAdmin: ["FleetSettings", "FleetAdministration", "FleetUpdateLog", "FleetAnomalyDetection"],
  fleetControl: ["FleetAnalysis", "FleetDepreciation", "FleetPlanning", "SalesPerformance"],
  maketData: [
    "NewCarSales",
    "MarketMonitor",
    "MarketBenchmark",
    "GeoSearch",
    "GeoSales",
    "GeoAnalysis",
  ],
  tools: ["VinDecode", "FleetManager"],
  reporting: ["Abs", "QReport"],
};

const currentRoute = computed(() => {
  return route.name as string;
});

const goToHome = () => router.push({ name: "Home" });

const goToAquisition = () => router.push({ name: "OffersDashboard" });
const goToUsedCars = () => router.push({ name: "UsedCars" });

const goToAuctions = () => router.push({ name: "AuctionsDashboard" });

const goToVinCheck = () => router.push({ name: "VinCheck" });

const goToNewCarSales = () => router.push({ name: "NewCarSales" });
const goToGeoSearch = () => router.push({ name: "GeoSearch" });
const goToGeoAnalysis = () => router.push({ name: "GeoAnalysis" });
const goToGeoSales = () => router.push({ name: "GeoSales" });
const goToMarketMonitor = () => router.push({ name: "MarketMonitor" });
const goToMarketBenchmark = () => router.push({ name: "MarketBenchmark" });

const goToFleetAdministration = () => router.push({ name: "FleetAdministration" });
const goToFleetAnalysis = () => router.push({ name: "FleetAnalysis" });
const goToForecasting = () => router.push({ name: "Forecasting" });
const goToAnomalyDetection = () => router.push({ name: "FleetAnomalyDetection" });
const goToFleetSettings = () => router.push({ name: "FleetSettings" });
const goToFleetDepreciation = () => router.push({ name: "FleetDepreciation" });
const goToFleetPlanning = () => router.push({ name: "FleetPlanning" });
const goToFleetUpdateLog = () => router.push({ name: "FleetUpdateLog" });
const goToSalesPerformance = () => router.push({ name: "SalesPerformance" });

const goToWholesale = () => router.push({ name: "Wholesale" });

const goToCreateValuation = () => router.push({ name: "CreateValuation" });
const goToViewValuations = () => router.push({ name: "ViewValuations" });

const goToQReport = () => router.push({ name: "QReport" });
</script>
<template>
  <section class="bg-black fixed w-full z-10">
    <div class="container flex items-center justify-between">
      <div class="flex">
        <img
          class="cursor-pointer"
          src="../../assets/img/hertz-logo.svg"
          width="77"
          height="21"
          alt="hertz logo"
          aria-details="logo"
          @click="goToHome"
        />
        <nav>
          <ul class="flex">
            <li
              class="cursor-pointer border-b-4"
              :class="{ 'border-b-yellow': navBarLinks.acquisition.includes(currentRoute) }"
              v-if="hasAccessToMenuCategory(MenuCategory.MENU_ACQUISITION)"
            >
              <div>
                <Menu
                  as="div"
                  class="relative"
                  :class="[
                    navBarLinks.acquisition.includes(currentRoute) ? 'text-yellow' : 'text-white',
                  ]"
                >
                  <div class="px-4 my-6 lg:px-9 flex items-center border-r border-black-dimmed-3">
                    <MenuButton class="flex items-center hover:text-yellow">
                      <div class="mr-4 lg:mr-9 text-xs lg:text-sm">Acquisition</div>
                      <font-awesome-icon
                        :icon="['fas', 'angle-down']"
                        class="icon w-[2rem]"
                      ></font-awesome-icon>
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 w-full z-10 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <MenuItem v-if="role.hasAccess(Acquisition.NEWCARS)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToAquisition"
                        >
                          New cars
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(Acquisition.USEDCARS)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToUsedCars"
                        >
                          Used cars
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(Acquisition.AUCTIONS)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToAuctions"
                        >
                          Auctions
                        </div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </li>

            <li
              class="cursor-pointer border-b-4"
              :class="{ 'border-b-yellow': navBarLinks.wholesale.includes(currentRoute) }"
              @click="goToWholesale"
              v-if="hasAccessToMenuCategory(MenuCategory.MENU_WHOLESALE)"
            >
              <div class="px-4 my-6 lg:px-9 flex items-center border-r border-black-dimmed-3">
                <div
                  class="text-xs lg:text-sm hover:text-yellow"
                  :class="[
                    navBarLinks.wholesale.includes(currentRoute) ? 'text-yellow' : 'text-white',
                  ]"
                  v-if="role.hasAccess(Wholesale.WHOLESALE)"
                >
                  Wholesale
                </div>
              </div>
            </li>

            <!-- fleet admin-->
            <li
              class="cursor-pointer border-b-4"
              :class="{ 'border-b-yellow': navBarLinks.fleetAdmin.includes(currentRoute) }"
              v-if="hasAccessToMenuCategory(MenuCategory.MENU_FLEET_ADMIN)"
            >
              <div>
                <Menu
                  as="div"
                  class="relative"
                  :class="[
                    navBarLinks.fleetAdmin.includes(currentRoute) ? 'text-yellow' : 'text-white',
                  ]"
                >
                  <div class="px-4 my-6 lg:px-9 flex items-center border-r border-black-dimmed-3">
                    <MenuButton class="flex items-center hover:text-yellow">
                      <div class="mr-4 lg:mr-9 text-xs lg:text-sm">Fleet admin</div>
                      <font-awesome-icon
                        :icon="['fas', 'angle-down']"
                        class="icon w-[.7rem]"
                      ></font-awesome-icon>
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 w-full z-10 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <MenuItem v-if="role.hasAccess(FleetAdmin.FLEETADMINISTRATION)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToFleetAdministration"
                        >
                          Fleet administration
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(FleetAdmin.ANOMALYDETECTION)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToAnomalyDetection"
                        >
                          Anomaly detection
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(FleetAdmin.FLEETSETTINGS)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToFleetSettings"
                        >
                          Fleet settings
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(FleetAdmin.UPDATELOG)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToFleetUpdateLog"
                        >
                          Update log
                        </div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </li>

            <!-- fleet control -->
            <li
              class="cursor-pointer border-b-4"
              :class="{ 'border-b-yellow': navBarLinks.fleetControl.includes(currentRoute) }"
              v-if="hasAccessToMenuCategory(MenuCategory.MENU_FLEET_CONTROL)"
            >
              <div>
                <Menu
                  as="div"
                  class="relative"
                  :class="[
                    navBarLinks.fleetControl.includes(currentRoute) ? 'text-yellow' : 'text-white',
                  ]"
                >
                  <div class="px-4 my-6 lg:px-9 flex items-center border-r border-black-dimmed-3">
                    <MenuButton class="flex items-center hover:text-yellow">
                      <div class="mr-4 lg:mr-9 text-xs lg:text-sm">Fleet control</div>
                      <font-awesome-icon
                        :icon="['fas', 'angle-down']"
                        class="icon w-[.7rem]"
                      ></font-awesome-icon>
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 w-full z-10 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <MenuItem v-if="role.hasAccess(FleetControl.FLEETANALYSIS)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToFleetAnalysis"
                        >
                          Fleet analysis
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(FleetControl.SALESPERFORMANCE)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToSalesPerformance"
                        >
                          Sales performance
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(FleetControl.FLEETPLANNING)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToFleetPlanning"
                        >
                          Fleet planning
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(FleetControl.FLEETDEPRECIATION)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToFleetDepreciation"
                        >
                          Fleet depreciation
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(FleetControl.FORECASTING)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToForecasting"
                        >
                          Forecasting
                        </div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </li>
            <!-- market data -->
            <li
              class="cursor-pointer border-b-4"
              :class="{ 'border-b-yellow': navBarLinks.maketData.includes(currentRoute) }"
              v-if="hasAccessToMenuCategory(MenuCategory.MENU_MARKET_DATA)"
            >
              <div>
                <Menu
                  as="div"
                  class="relative"
                  :class="[
                    navBarLinks.maketData.includes(currentRoute) ? 'text-yellow' : 'text-white',
                  ]"
                >
                  <div class="px-4 my-6 lg:px-9 flex items-center border-r border-black-dimmed-3">
                    <MenuButton class="flex items-center hover:text-yellow">
                      <div class="mr-4 lg:mr-9 text-xs lg:text-sm">Market Data</div>
                      <font-awesome-icon
                        :icon="['fas', 'angle-down']"
                        class="icon w-[.7rem]"
                      ></font-awesome-icon>
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 w-full z-10 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <MenuItem v-if="role.hasAccess(MarketData.GEOSEARCH)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToGeoSearch"
                        >
                          Geo Search
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(MarketData.GEOSALES)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToGeoSales"
                        >
                          Geo Sales
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(MarketData.GEOANALYSIS)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToGeoAnalysis"
                        >
                          Geo Analysis
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(MarketData.NEWCARSALES)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToNewCarSales"
                        >
                          New cars sales
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(MarketData.MARKETMONITOR)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToMarketMonitor"
                        >
                          Market monitor
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(MarketData.MARKETBENCHMARK)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToMarketBenchmark"
                        >
                          Market benchmark
                        </div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </li>

            <li
              class="cursor-pointer border-b-4"
              :class="{ 'border-b-yellow': navBarLinks.tools.includes(currentRoute) }"
              v-if="hasAccessToMenuCategory(MenuCategory.MENU_TOOLS)"
            >
              <div>
                <Menu
                  as="div"
                  class="relative"
                  :class="[navBarLinks.tools.includes(currentRoute) ? 'text-yellow' : 'text-white']"
                >
                  <div class="px-4 my-6 lg:px-9 flex items-center border-r border-black-dimmed-3">
                    <MenuButton class="flex items-center hover:text-yellow">
                      <div class="mr-4 lg:mr-9 text-xs lg:text-sm">Tools</div>
                      <font-awesome-icon
                        :icon="['fas', 'angle-down']"
                        class="icon w-[.7rem]"
                      ></font-awesome-icon>
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 w-full z-10 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <MenuItem v-if="role.hasAccess(Tools.VINDECODE)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToVinCheck"
                        >
                          VIN&nbsp;Decode
                        </div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </li>
            <li
              class="cursor-pointer border-b-4"
              :class="{ 'border-b-yellow': navBarLinks.reporting.includes(currentRoute) }"
              v-if="hasAccessToMenuCategory(MenuCategory.MENU_REPORTING)"
            >
              <div>
                <Menu
                  as="div"
                  class="relative"
                  :class="[
                    navBarLinks.reporting.includes(currentRoute) ? 'text-yellow' : 'text-white',
                  ]"
                >
                  <div class="px-4 my-6 lg:px-9 flex items-center border-r border-black-dimmed-3">
                    <MenuButton class="flex items-center hover:text-yellow">
                      <div class="mr-4 lg:mr-9 text-xs lg:text-sm">Reporting</div>
                      <font-awesome-icon
                        :icon="['fas', 'angle-down']"
                        class="icon w-[.7rem]"
                      ></font-awesome-icon>
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 w-full z-10 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <MenuItem v-if="role.hasAccess(Reporting.ABSVALUATIONS)">
                        <div class="group">
                          <div
                            class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          >
                            ABS Valuation
                            <font-awesome-icon
                              :icon="['fas', 'angle-right']"
                              class="icon w-[.7rem]"
                            ></font-awesome-icon>
                            <ul
                              class="bg-white transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-left w-full top-0 left-full"
                            >
                              <li
                                class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300 border-l-2 border-solid"
                                @click="goToCreateValuation"
                              >
                                Create valuation
                              </li>

                              <li
                                class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300 border-l-2 border-solid"
                                @click="goToViewValuations"
                              >
                                View valuations
                              </li>
                            </ul>
                          </div>
                        </div>
                      </MenuItem>
                      <MenuItem v-if="role.hasAccess(Reporting.QREVIEW)">
                        <div
                          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
                          @click="goToQReport"
                        >
                          Q Review
                        </div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <section class="flex items-center justify-between" aria-details="account">
        <UserManagementDropdown v-if="showSettings" />
        <ProfileDropdown />
      </section>
    </div>
  </section>
</template>
