<script lang="ts" setup>
import { computed, inject, onBeforeMount, reactive, ref, watch } from "vue";
import type { IEquipment, ITranslatedConstant } from "@/libs/types/AppTypes";
import type { HttpClient } from "@/services/HttpClient/HttpClient";

interface IProps {
  disabled?: boolean;
  accessories?: IEquipment[];
}

const http = inject("httpClient") as HttpClient;

const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
  accessories: () => [],
});

onBeforeMount(() => {
  http
    .getFleetAccessories()
    .then((response) => (fleetAccessories.value = response))
    .catch((err) => `Could not get accessories because ${err}`);
});

const fleetAccessories = ref<ITranslatedConstant[]>([]);

const hasPredefinedValues = computed(() => props.accessories?.length > 1);

const mappedAccessories = computed(() => {
  if (!hasPredefinedValues.value) return [];
  return props.accessories.map((acc) => {
    const label = fleetAccessories.value.find((a) => a.code === acc.code)?.label;
    return {
      code: acc.code,
      value: acc.value,
      label,
    };
  });
});

const createRefMap = () => {
  const map = new Map();
  hasPredefinedValues.value
    ? mappedAccessories.value.forEach((acc) => map.set(acc.code, acc.value))
    : fleetAccessories.value.forEach((acc) => map.set(acc.code, false));

  return Object.fromEntries(map);
};

let models = reactive(createRefMap());

watch(
  () => props.accessories,
  () => {
    models = reactive(createRefMap());
  },
);

const equipment = () => {
  return { ...models };
};

defineExpose({ equipment });
</script>
<template>
  <div class="grid grid-cols-2 2xl:grid-cols-4 gap-2">
    <!-- to be used when you do not have any implicit values, so all will be set to false -->
    <template v-if="!hasPredefinedValues">
      <AppCustomSwitchInput
        v-for="(acc, idx) in fleetAccessories"
        :key="idx"
        :id="acc.code"
        :label="acc.label"
        :disabled="disabled"
        v-model="models[`${acc.code}`]"
        class="mb-5"
      />
    </template>
    <!-- to be used when you already have some predefined values true or false -->
    <template v-else>
      <AppCustomSwitchInput
        v-for="(acc, idx) in mappedAccessories"
        :key="idx"
        :id="acc.code"
        :label="acc.label"
        :disabled="disabled"
        v-model="models[`${acc.code}`]"
        class="mb-5"
      />
    </template>
  </div>
</template>
