<script setup lang="ts">
import type { IAppTable, ISortPayload } from "@/libs/types/AppTypes";
import AppPagination from "./AppPagination.vue";
import AppSortIcons from "@/components/AppSortIcons.vue";
import imagePath from "@/assets/img/table-empty-state.svg";
import { ref } from "vue";

interface IProps {
  data: any[];
  id: string;
  fields: IAppTable[];
  paginated?: boolean;
  records?: number;
  rowsPerPage?: number;
  currentPage?: number;
  hoverColor?: string;
  maxHeight?: string;
  serverSorting?: boolean;
  checkedIds?: string[];
  invalidFields?: number[];
}

interface IAppPagination {
  resetToFirstPage(): void;
}

const props = withDefaults(defineProps<IProps>(), {
  data: () => [],
  fields: () => [],
  paginated: false,
  records: 1,
  rowsPerPage: 0,
  currentPage: 1,
  hoverColor: "hover:bg-yellow",
  maxHeight: "max-height: 600px",
  serverSorting: false,
  checkedIds: () => [],
  invalidFields: () => [],
});

const emit = defineEmits<{
  (evt: "pageChange", payload: number): void;
  (evt: "rowClick", payload: any): void;
  (evt: "serverSorting", payload: ISortPayload): void;
  (evt: "frontEndSorting", payload: ISortPayload): void;
}>();

//emit events
const pageChange = (page: number) => emit("pageChange", page);
const rowClick = (data: any) => emit("rowClick", data);

//change selected row css in case you use a checkbox component inside table
const selectedRowCss = (id: string) => (props.checkedIds.includes(id) ? "bg-yellow" : "");

// sorting
const sortedColumn = ref<string | null>(null);
const pagination = ref<IAppPagination>();

const onTableSort = (data: ISortPayload) => {
  //in order to make the column sorting arrows active
  //we need to know which property is currently being sorted so we can change color
  sortedColumn.value = data.property;

  //emit the sort evt
  if (props.serverSorting) {
    emit("serverSorting", data);
    pagination.value!.resetToFirstPage();
    return;
  }

  emit("frontEndSorting", data);
};
</script>
<template>
  <div>
    <section class="overflow-y-auto custom-scrollbar" :style="maxHeight">
      <table
        class="min-w-full border-separate"
        :id="`table-id-${id}`"
        :data-cy="`table-id-${id}`"
        style="border-spacing: 0"
      >
        <caption class="hidden">
          The table
        </caption>
        <thead class="bg-grey-300">
          <tr>
            <th
              v-for="(col, idx) in fields"
              :key="idx"
              scope="col"
              class="sticky top-0 z-9 py-5 px-4 text-left text-sm bg-white text-grey-600 whitespace-nowrap"
              :class="[col.column.css]"
            >
              <!-- if col is sortable -->
              <template v-if="col.sortable">
                <div class="flex items-center">
                  <div class="mr-2">
                    <AppSortIcons
                      :sorted-property="col.row.label"
                      :data-type="col.row.dataType"
                      :active-column="sortedColumn as string"
                      @sort="onTableSort"
                    />
                  </div>
                  <slot :name="col.column.label">{{ col.column.label }}</slot>
                </div>
              </template>
              <!-- else render just the slot -->
              <template v-else>
                <slot :name="col.column.label">{{ col.column.label }}</slot>
              </template>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr
            v-for="(row, i) in data"
            :key="i"
            :class="[hoverColor, { 'bg-red-200': invalidFields.includes(i) }]"
          >
            <td
              v-for="(cell, cellIdx) in fields"
              :key="cellIdx"
              class="border-b border-grey-200 whitespace-nowrap py-3 px-3 text-sm"
              :class="[cell.row.css, selectedRowCss(row.id)]"
              @click="rowClick(row)"
            >
              <slot :name="cell.row.label" :rowData="data[i]" :cellIdx="cellIdx">
                <!-- this is where the content gets rendered. if no slot is specified via template, it will only render the data -->
                {{ row[cell.row.label] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <div
      v-if="!data.length"
      :style="{ backgroundImage: `url(${imagePath})` }"
      style="height: 18.75rem"
      class="w-full bg-cover bg-center"
    >
      <div class="h-full flex flex-col items-left justify-center pl-9">
        <p class="font-bold text-xl pb-9">Sorry, no results were generated.</p>
        <div>
          <slot name="empty-state-content"></slot>
        </div>
      </div>
    </div>
    <section class="mt-4 flex justify-between items-center">
      <div>
        <slot name="footer"></slot>
      </div>
      <!-- pagination -->
      <div class="text-right" v-if="paginated">
        <AppPagination
          ref="pagination"
          :items-per-page="rowsPerPage"
          :total-records="records"
          :current-page="currentPage"
          @pageChange="pageChange"
        />
      </div>
    </section>
  </div>
</template>
