<script setup lang="ts">
// @ts-ignore
import Datepicker from "@vuepic/vue-datepicker";
import { computed } from "vue";

interface Props {
  id: string;
  modelValue?: any;
  disabled?: boolean;
  label?: string;
  from?: Date;
  to?: Date;
  placeholder?: string;
  yearPicker?: boolean;
  errorMessage?: string;
  invalid?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  id: "id",
  modelValue: "",
  placeholder: "Select date",
  yearPicker: false,
  invalid: false,
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
    emit("keyPress", value);
  },
});

const errorCss = computed(() => {
  return props.invalid ? "!border-red" : " border-transparent";
});

const format = (date: Date) => {
  if (props.yearPicker) return `${date.getFullYear()}`;

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const emit = defineEmits<{
  (e: "update:modelValue", payload: unknown): void;
  (e: "keyPress", payload: string | number | null): void;
  (e: "cleared"): void;
}>();

const cleared = () => emit("cleared");
</script>
<template>
  <div class="items-center" :class="{ 'mt-6': label }">
    <div class="text-grey-700 text-xs sm:text-sm">{{ label }}</div>
    <div class="flex w-full">
      <div class="w-full">
        <Datepicker
          v-model="value"
          :id="id"
          :data-cy="id"
          :minDate="from"
          :maxDate="to"
          :format="format"
          :placeholder="placeholder"
          :disabled="disabled"
          :enableTimePicker="false"
          :yearPicker="yearPicker"
          class="border"
          :class="[errorCss]"
          @cleared="cleared"
        />
        <p v-if="invalid" class="text-red absolute text-sm">{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

.dp__theme_light {
  --dp-background-color: #fff;
  --dp-border-color: #000000;
  --dp-border-color-hover: #dedede;
  --dp-primary-color: #ff7500;
  --dp-success-color: #ff7500;
}

.dp__input {
  border-radius: 0;
  border-width: 0;
  padding: 0.25rem 0 0.25rem 2rem;
  border: 1px solid #dedede;
  font-size: 12px;
  color: #000;

  @media (min-width: 1024px) {
    padding: 0.63rem 0 0.63rem 2rem;
    font-size: 14px;
  }
}

.dp__input:disabled {
  cursor: not-allowed;
  background-color: #f4f4f4;
}
</style>
