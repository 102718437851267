<script setup lang="ts">
import { computed, ref } from "vue";

interface Props {
  itemsPerPage: number;
  totalRecords: number;
  currentPage: number;
}

const props = withDefaults(defineProps<Props>(), {
  itemsPerPage: 0,
  totalRecords: 1,
  currentPage: 1,
});

const pageCount = ref(props.currentPage);

const pages = computed(() => Math.ceil(props.totalRecords / props.itemsPerPage));

const canGoToNextPage = computed(() => props.currentPage + 1 <= pages.value);
const canGoToPrevPage = computed(() => props.currentPage - 1 >= 1);

const goToNextPage = () => {
  if (canGoToNextPage.value) {
    pageCount.value++;
    emit("pageChange", pageCount.value);
  }
};

const goToPrevPage = () => {
  if (canGoToPrevPage.value) {
    pageCount.value--;
    emit("pageChange", pageCount.value);
  }
};

const emit = defineEmits<{ (evt: "pageChange", payload: number): void }>();

const pageChange = (evt: Event) => {
  const element = evt.target as HTMLSelectElement;

  pageCount.value = parseInt(element.value);

  emit("pageChange", parseInt(element.value));
};

//needed for pagination when you want to reset to the first page
//eg: after the table was sortedit must be set to page 1
const resetToFirstPage = () => (pageCount.value = 1);

defineExpose({ resetToFirstPage });
</script>
<template>
  <div class="inline-flex">
    <div class="flex items-center border border-grey-300 bg-white text-sm">
      <select
        id="pagination"
        class="w-auto bg-white p-2 text-xs lg:text-sm focus:outline-none disabled:text-grey-500 disabled:bg-grey-200 disabled:cursor-not-allowed"
        :value="pageCount"
        @change="pageChange"
      >
        <option v-for="page in pages" :key="page" :data-cy="page" :value="page">
          {{ page }}
        </option>
      </select>
      <div class="mx-3">of {{ pages }} pages</div>
      <button
        class="py-3 px-4 border-l border-r border-grey-300 hover:bg-grey-100 cursor-pointer disabled:cursor-not-allowed"
        :disabled="!canGoToPrevPage"
        @click="goToPrevPage"
      >
        <font-awesome-icon
          :icon="['fas', 'angle-left']"
          :class="{ 'text-grey-300': !canGoToPrevPage }"
          class="text-base"
        >
        </font-awesome-icon>
      </button>
      <button
        class="py-3 px-4 hover:bg-grey-100 cursor-pointer disabled:cursor-not-allowed"
        :disabled="!canGoToNextPage"
        @click="goToNextPage"
      >
        <font-awesome-icon
          class="text-base"
          :class="{ 'text-grey-300': !canGoToNextPage }"
          :icon="['fas', 'angle-right']"
        ></font-awesome-icon>
      </button>
    </div>
  </div>
</template>
