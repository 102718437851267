<script setup lang="ts">
import { Disclosure } from "@headlessui/vue";
import { computed, useSlots, provide } from "vue";

interface Props {
  expandedElement?: number;
}

const props = withDefaults(defineProps<Props>(), {
  expandedElement: 0,
});

// ugly but I have no types for this
const slots = useSlots()
  ?.default?.()
  .map((slot) => slot.props!.idx);

const activeTab = computed(() => {
  if (isNaN(props.expandedElement)) return NaN;
  // refactor this
  return slots!.length > 1 ? slots![props.expandedElement] : props.expandedElement;
});

provide("activeTab", activeTab);
</script>
<template>
  <div class="w-full">
    <Disclosure as="div" class="w-full mx-auto">
      <slot></slot>
    </Disclosure>
  </div>
</template>
