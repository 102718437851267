<script setup lang="ts">
import { computed } from "vue";

interface Props {
  id: string;
  modelValue?: string | null;
  groupName: string;
  label: string;
  disabled?: boolean;
  val?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: "radio",
  groupName: "radio-group",
  isChecked: false,
  disabled: false,
  modelValue: "",
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const emit = defineEmits<{ (e: "update:modelValue", payload: string | null): void }>();
</script>
<template>
  <label
    class="radio-container h-[1.18rem] flex items-center relative pl-[1.93rem] m-2 cursor-pointer select-none"
  >
    <input
      type="radio"
      class="absolute opacity-0 cursor-pointer disabled:cursor-not-allowed"
      :id="id"
      :data-cy="id"
      :name="groupName"
      :disabled="disabled"
      :value="val"
      v-model="value"
    />
    <span
      class="radio-checkmark absolute top-0 left-0 h-[1.1rem] w-[1.1rem] rounded-full bg-grey-300 after:hidden after:absolute after:m-[.09rem] after:h-[.9rem] after:w-[.9rem] after:rounded-full after:bg-orange"
    ></span>
    <span class="label text-sm text-black">{{ label }}</span>
  </label>
</template>
<style scoped>
/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #308dff;
  border: 0.28rem solid #e2e2e2;
}

.radio-container input:disabled ~ .radio-checkmark {
  border: 0.09rem solid #f4f4f4;
  background-color: #f4f4f4;
  cursor: not-allowed;
}
.radio-container input:disabled ~ .label {
  color: #000;
  cursor: not-allowed;
}
.radio-container input:disabled:checked ~ .label {
  color: #f4f4f4;
  cursor: not-allowed;
}
.radio-container input:disabled ~ .radio-checkmark:after {
  background-color: #7a85ab;
  cursor: not-allowed;
}
</style>
