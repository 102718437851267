<script setup lang="ts">
import { ref } from "vue";
import type { FileType } from "@/libs/types/AppTypes";
import { toastError } from "@/helpers/herlpers";

interface Props {
  accept: FileType[];
  id: string;
  multiple?: boolean;
  minSize?: number;
  maxSise?: number;
}

const props = withDefaults(defineProps<Props>(), {
  multiple: false,
  minSize: 20480,
  maxSise: 7340032,
});

const emits = defineEmits<{ (e: "fileLoaded", payload: FileList): void }>();

const hiddenFileUploadInput = ref<HTMLInputElement>();

const acceptedTypes = props.accept.toString();

const onFileInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const files = target.files as FileList;
  const isTypeAccepted = Object.values(files).every((file) => acceptedTypes.includes(file.type));
  const fileSizeAccepted = Object.values(files).every(
    (file) => file.size >= props.minSize && file.size <= props.maxSise,
  );

  if (!isTypeAccepted) {
    toastError("This file type is not accepted");
    // reset the input value
    target.value = "";
    return;
  }

  if (!fileSizeAccepted) {
    toastError("The file size must be between 20KB and 7MB ");
    // reset the input value
    target.value = "";
    return;
  }

  if (files.length && isTypeAccepted) emits("fileLoaded", files);
};

const onOpenUploadDialog = () => hiddenFileUploadInput.value!.click();
</script>
<template>
  <div>
    <label for="customFile" hidden>some label</label>
    <input
      type="file"
      :id="`file-upload-${id}`"
      :data-cy="`file-upload-${id}`"
      class="bg-red"
      ref="hiddenFileUploadInput"
      @input="onFileInput"
      :accept="acceptedTypes"
      :multiple="multiple"
      hidden
    />
    <div>
      <slot :openUploadDialog="onOpenUploadDialog"></slot>
    </div>
  </div>
</template>
