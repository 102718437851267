import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router";
import { msalInstance } from "@/auth/authConfig";
import { CustomNavigationClient } from "@/router/NavigationClient";

import LoginView from "@/views/LoginView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

import { usePermission } from "@/composables/usePermission";

import configureRouterHooks from "@/router/router-hooks";
import {
  Acquisition,
  Admin,
  FleetAdmin,
  FleetControl,
  MarketData,
  Reporting,
  Tools,
  Wholesale,
} from "@/libs/types/AppTypes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      requiresAuth: true,
      title: "Home",
    },
  },
  {
    path: "/wholesale",
    name: "Wholesale",
    component: () => import("@/views/WholesaleView.vue"),
    meta: {
      requiresAuth: true,
      title: "Wholesale",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Wholesale.WHOLESALE)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/fleet-manager",
    name: "FleetManager",
    component: () => import("@/views/FleetManagerView.vue"),
    meta: {
      requiresAuth: true,
      title: "Fleet Manager",
    },
  },
  {
    path: "/fleet-administration",
    name: "FleetAdministration",
    component: () => import("@/views/FleetAdministrationView.vue"),
    meta: {
      requiresAuth: true,
      title: "Fleet Administration",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(FleetAdmin.FLEETADMINISTRATION)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/fleet-update-log",
    name: "FleetUpdateLog",
    component: () => import("@/views/FleetUpdateLogView.vue"),
    meta: {
      requiresAuth: true,
      title: "Fleet Update log",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(FleetAdmin.UPDATELOG)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/fleet-anomaly-detection",
    name: "FleetAnomalyDetection",
    component: () => import("@/views/FleetAnomalyDetectionView.vue"),
    meta: {
      requiresAuth: true,
      title: "Fleet Anomaly Detection",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(FleetAdmin.ANOMALYDETECTION)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/fleet-analysis",
    name: "FleetAnalysis",
    component: () => import("@/views/FleetAnalysisView.vue"),
    meta: {
      requiresAuth: true,
      title: "Fleet Analysis",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(FleetControl.FLEETANALYSIS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/forecasting",
    name: "Forecasting",
    component: () => import("@/views/ForecastingView.vue"),
    meta: {
      requiresAuth: true,
      title: "Forecasting",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(FleetControl.FORECASTING)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/fleet-control",
    name: "FleetControl",
    component: () => import("@/views/FleetControlView.vue"),
    meta: {
      requiresAuth: true,
      title: "Fleet Control",
    },
  },
  {
    path: "/fleet-settings",
    name: "FleetSettings",
    component: () => import("@/views/FleetSettingsView.vue"),
    meta: {
      requiresAuth: true,
      title: "Fleet Settings",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(FleetAdmin.FLEETSETTINGS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/fleet-depreciation",
    name: "FleetDepreciation",
    component: () => import("@/views/FleetDepreciationView.vue"),
    meta: {
      requiresAuth: true,
      title: "Fleet Depreciation",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(FleetControl.FLEETDEPRECIATION)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/fleet-planning",
    name: "FleetPlanning",
    component: () => import("@/views/FleetPlanningView.vue"),
    meta: {
      requiresAuth: true,
      title: "Fleet Planning",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(FleetControl.FLEETPLANNING)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/new-car-sales",
    name: "NewCarSales",
    component: () => import("@/views/NewCarSalesView.vue"),
    meta: {
      requiresAuth: true,
      title: "New car sales",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(MarketData.NEWCARSALES)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/market-monitor",
    name: "MarketMonitor",
    component: () => import("@/views/MarketMonitorView.vue"),
    meta: {
      requiresAuth: true,
      title: "Market monitor",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(MarketData.MARKETMONITOR)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/market-benchmark",
    name: "MarketBenchmark",
    component: () => import("@/views/MarketBenchmarkView.vue"),
    meta: {
      requiresAuth: true,
      title: "Market benchmark",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(MarketData.MARKETBENCHMARK)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/geo-search",
    name: "GeoSearch",
    component: () => import("@/views/GeoSearchView.vue"),
    meta: {
      requiresAuth: true,
      title: "Geo Search",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(MarketData.GEOSEARCH)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/geo-sales",
    name: "GeoSales",
    component: () => import("@/views/GeoSalesView.vue"),
    meta: {
      requiresAuth: true,
      title: "Geo Sales",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(MarketData.GEOSALES)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/geo-analysis",
    name: "GeoAnalysis",
    component: () => import("@/views/GeoAnalysisView.vue"),
    meta: {
      requiresAuth: true,
      title: "Geo Analysis",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(MarketData.GEOANALYSIS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/offers/offers-dashboard",
    name: "OffersDashboard",
    component: () => import("@/views/OffersDashboardView.vue"),
    meta: {
      requiresAuth: true,
      title: "Offers dashboard",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Acquisition.NEWCARS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/offers/used-cars",
    name: "UsedCars",
    component: () => import("../views/UsedCarsView.vue"),
    meta: {
      requiresAuth: true,
      title: "Used Cars",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Acquisition.USEDCARS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/offers/view-by-supplier",
    name: "OffersBySupplier",
    component: () => import("@/views/OffersBySupplierView.vue"),
    meta: {
      requiresAuth: true,
      title: "Offers view by supplier",
    },
  },
  {
    path: "/offers/list-view",
    name: "OffersList",
    component: () => import("@/views/OffersListView.vue"),
    meta: {
      requiresAuth: true,
      title: "Offers list",
    },
  },
  {
    path: "/offers/list-view/process",
    name: "ProcessOffer",
    component: () => import("@/views/ProcessOfferView.vue"),
    meta: {
      requiresAuth: true,
      title: "Process offer",
    },
  },
  {
    path: "/auctions/dashboard",
    name: "AuctionsDashboard",
    component: () => import("@/views/AuctionsDashboardView.vue"),
    meta: {
      requiresAuth: true,
      title: "Auctions dashboard",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Acquisition.AUCTIONS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/auctions/view-by-status",
    name: "AuctionListByStatus",
    component: () => import("@/views/AuctionListByStatusView.vue"),
    meta: {
      requiresAuth: true,
      title: "Auctions list by status",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Acquisition.AUCTIONS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/auctions/process",
    name: "ProcessAuction",
    component: () => import("@/views/ProcessAuctionView.vue"),
    meta: {
      requiresAuth: true,
      title: "Auctions list by status",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Acquisition.AUCTIONS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/vin-check",
    name: "VinCheck",
    component: () => import("@/views/VinCheckView.vue"),
    meta: {
      requiresAuth: true,
      title: "VIN decode",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Tools.VINDECODE)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/sales-performance",
    name: "SalesPerformance",
    component: () => import("@/views/SalesPerformanceView.vue"),
    meta: {
      requiresAuth: true,
      title: "Sales performance",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(FleetControl.SALESPERFORMANCE)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: {
      requiresAuth: false,
      title: "Login",
    },
  },
  {
    path: "/rules-and-permissions",
    name: "RulesAndPermissions",
    component: () => import("@/views/RulesAndPermissionsView.vue"),
    meta: {
      requiresAuth: true,
      title: "Roles and permissions",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Admin.SUPERADMIN)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/admin-user-management",
    name: "AdminUserManagement",
    component: () => import("@/views/AdminUserManagementView.vue"),
    meta: {
      requiresAuth: true,
      title: "Admin user management",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Admin.SUPERADMIN)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/sign-in-logs",
    name: "SignInLogs",
    component: () => import("@/views/SignInLogsView.vue"),
    meta: {
      requiresAuth: true,
      title: "Sign in logs",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Admin.SUPERADMIN)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/abs",
    name: "Abs",
    component: () => import("@/views/AbsView.vue"),
    meta: {
      requiresAuth: true,
      title: "Abs",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Reporting.ABSVALUATIONS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/abs/view-valuations",
    name: "ViewValuations",
    component: () => import("@/views/ViewValuationsView.vue"),
    meta: {
      requiresAuth: true,
      title: "View valuations",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Reporting.ABSVALUATIONS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/abs/create-valuation",
    name: "CreateValuation",
    component: () => import("@/views/CreateValuationView.vue"),
    meta: {
      requiresAuth: true,
      title: "Create valuation",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Reporting.ABSVALUATIONS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/abs/process-valuation/:id",
    name: "ProcessValuation",
    component: () => import("@/views/ProcessValuationView.vue"),
    meta: {
      requiresAuth: true,
      title: "Process Valuation",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Reporting.ABSVALUATIONS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/abs/valuation-detail/:id",
    name: "ValuationDetail",
    component: () => import("@/views/ValuationDetailView.vue"),
    meta: {
      requiresAuth: true,
      title: "Valuation Detail",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Reporting.ABSVALUATIONS)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/q-report",
    name: "QReport",
    component: () => import("@/views/QReportView.vue"),
    meta: {
      requiresAuth: true,
      title: "Q Report",
    },
    beforeEnter: (to, from, next) => {
      const role = usePermission();

      if (role.hasAccess(Reporting.QREVIEW)) next();
      else next({ name: "Home" });
    },
  },
  {
    path: "/ui-elements",
    name: "UiElements",
    component: () => import("@/views/UiElementsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
    meta: {
      requiresAuth: false,
      title: "Not found",
    },
  },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL), // see https://vitejs.dev/guide/env-and-mode.html for more details about env
  routes,
  // set scroll to top on each page navigation
  scrollBehavior() {
    return { top: 0 };
  },
});

configureRouterHooks(router);

//This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);
