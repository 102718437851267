<script lang="ts" setup>
import { ButtonTheme } from "../libs/types/AppTypes";
import imagePath from "../assets/img/not-found.png";
import { useRouter } from "vue-router";

const router = useRouter();

const goToHomePage = () => router.push({ path: "/" });
</script>
<template>
  <main class="h-screen flex items-center">
    <section class="container xl:pl-20 2xl:pl-44">
      <h2 class="text-xl font-bold mb-4">404</h2>
      <h1 class="text-6xl font-bold mb-9">Something went missing.</h1>
      <p class="text-sm mb-12 2xl:pr-20">
        Sorry, the page you are looking for doesn't exist or has been moved.Here are some helpful
        links:
      </p>
      <AppActionButton
        id="back-to-main-page"
        label="Back to main page"
        :theme="ButtonTheme.Yellow"
        @click="goToHomePage"
      />
    </section>
    <section class="w-full h-full">
      <div
        :style="{ backgroundImage: `url(${imagePath})` }"
        class="h-full w-full bg-cover"
        alt="not-found"
      ></div>
    </section>
  </main>
</template>
