<script setup lang="ts">
import { computed, inject, onUpdated, onMounted, ref } from "vue";

interface IProps {
  idx: number;
}

const props = withDefaults(defineProps<IProps>(), {
  idx: 0,
});

onMounted(() => {
  pannelHeight.value = pannel.value!.scrollHeight;
});

onUpdated(() => {
  pannelHeight.value = pannel.value!.scrollHeight;
});

const pannel = ref<HTMLElement>();
const pannelHeight = ref(0);
const activeTabIdx = ref(inject("activeTab"));

const isActive = computed(() => props.idx === activeTabIdx.value);

const activeCss = computed(() => (isActive.value ? pannelHeight.value : 0));

const emit = defineEmits<{ (e: "tabChange", payload: number): void }>();

const onTabChange = (payload: number) => {
  isActive.value ? emit("tabChange", NaN) : emit("tabChange", payload);
};

const dataCY = `$i-${props.idx}`;
</script>
<template>
  <div>
    <dl>
      <dt class="flex items-center" @click="onTabChange(props.idx)">
        <div
          class="py-3 lg:py-5 px-6 lg:px-9 border-b border-grey-500 bg-grey-300 text-left text-sm font-bold w-full flex justify-between items-center cursor-pointer"
          :class="{ '!bg-black text-white': isActive }"
        >
          <slot name="title"></slot>
          <div>
            <font-awesome-icon
              v-if="isActive"
              :icon="['fas', 'dash']"
              class="w-3"
            ></font-awesome-icon>
            <font-awesome-icon
              v-if="!isActive"
              :icon="['fas', 'plus-large']"
              class="w-3"
            ></font-awesome-icon>
          </div>
        </div>
      </dt>
    </dl>

    <dd
      ref="pannel"
      :id="dataCY"
      :data-cy="dataCY"
      class="overflow-hidden transition-all duration-300 bg-white"
      :style="{ 'max-height': `${activeCss}px` }"
    >
      <div class="p-6 lg:p-9">
        <slot name="body"></slot>
      </div>
    </dd>
  </div>
</template>
