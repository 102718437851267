import type { StringLiteral } from "typescript";

export enum ButtonTheme {
  Blue = "bg-blue text-white border border-transparent hover:bg-blue-100 disabled:bg-grey-100 disabled:text-grey-500",
  Green = "bg-green text-white border border-transparent hover:bg-green-dark disabled:bg-grey-100 disabled:text-grey-500",
  TransparentGreen = "bg-transparent text-green border border-green disabled:bg-grey-100 disabled:text-grey-500 disabled:border-grey-100",
  Yellow = "bg-yellow text-black border border-transparent hover:bg-yellow-dark disabled:bg-grey-100 disabled:text-grey-500",
  Transparent = "bg-transparent text-black border border-black hover:bg-black-dimmed-1 disabled:bg-grey-100 disabled:text-grey-500 disabled:border-grey-100",
  Black = "bg-black text-white border border-transparent hover:bg-black-dimmed-2 disabled:bg-grey-100 disabled:text-grey-500",
}

export enum TapedCardTheme {
  Grey = "bg-grey-300",
  Black = "bg-black text-white",
}

export enum CardTheme {
  Default = "bg-white px-6 py-6 lg:px-9 lg:py-9",
  Grey200 = "bg-grey-200 py-3 px-6",
  Grey100 = "bg-grey-100 p-6",
  Yellow100 = "bg-yellow-100 p-6",
  Yellow100ExtraSmall = "bg-yellow-100 p-3",
  YellowExtraSmall = "bg-yellow p-3",
  Small = "bg-white px-6 py-3",
  ExtraSmall = "bg-white p-3",
  SmallError = "bg-red text-white px-6 py-3",
}

export enum PillTheme {
  Red = "bg-red text-white",
  LightGreen = "bg-green-200 text-white",
  Green = "bg-green text-white",
  GreyWhite = "bg-grey-300 text-white",
  GreyBlack = "bg-grey-300 text-black",
  Yellow = "bg-yellow text-black",
  Blue = "bg-blue text-white",
  Magenta = "bg-magenta-100 text-white",
  LightMagenta = "bg-magenta-200 text-magenta-100",
  YellowBlack = "bg-black text-yellow",
  YellowWhite = "bg-yellow text-white",
  Brown = "bg-brown text-white",
  DarkGreen = "bg-green-100 text-white",
  MediumBlue = "bg-blue-200 text-white",
  DarkBlue = "bg-blue-300 text-white",
  DarkGrey = "bg-grey-700 text-white",
  Pink = "bg-pink text-white",
  Orange = "bg-orange text-white",
}

export enum ButtonTabTheme {
  Yellow = "border md:text-sm px-4 lg:px-7 py-2 lg:py-3",
  Black = "border py-2 px-3",
  Transparent = "py-3 px-6",
}

export enum InputTypes {
  text = "text",
  number = "number",
  password = "password",
  email = "email",
  tel = "tel",
}

export enum SupplierType {
  OEM = "OEM",
  DEALER = "DEALER",
  BROKER = "BROKER",
  AUCTION = "AUCTION",
}

export enum OfferValidationStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

export enum CarOfferStatus {
  INITIATED = "INITIATED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REQUESTED_COUNTER_OFFER = "REQUESTED_COUNTER_OFFER",
  SENT_COUNTER_OFFER = "SENT_COUNTER_OFFER",
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
}

export enum OfferStatus {
  INITIATED = "INITIATED",
  PUBLISHED = "PUBLISHED",
  REQUESTED_COUNTER_OFFER = "REQUESTED_COUNTER_OFFER",
  SENT_COUNTER_OFFER = "SENT_COUNTER_OFFER",
  REJECTED = "REJECTED",
  REQUESTED_VALIDATION = "REQUESTED_VALIDATION",
  ACCEPTED = "ACCEPTED",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
}

// sunt doar anuite key din enum OfferStatus. refactorizeaza-l
export enum NewOffers {
  PUBLISHED = "PUBLISHED",
}

export enum OngoingOffers {
  REQUESTED_COUNTER_OFFER = "REQUESTED_COUNTER_OFFER",
  SENT_COUNTER_OFFER = "SENT_COUNTER_OFFER",
  REQUESTED_VALIDATION = "REQUESTED_VALIDATION",
  ACCEPTED = "ACCEPTED",
}

export enum ExpiredOffers {
  EXPIRED = "EXPIRED",
}

export enum ClosedOffers {
  CONFIRMED = "CONFIRMED",
}

export enum RejectedOffers {
  REJECTED = "REJECTED",
}

export enum PendingOffers {
  ACCEPTED = "ACCEPTED",
}

export enum Roles {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
}

export interface IVinResponse {
  acceleration: IPropertyDetails;
  batteryCapacity: null;
  bodyType: IMappedProperty;
  co2Nedc: number;
  co2Wltp: number;
  colourCode: string;
  colourDescription: string;
  consumptionNedc: IPropertyDetails;
  consumptionWltp: IPropertyDetails;
  deliveryCountry: string;
  doors: number;
  drivingWheel: IMappedProperty;
  electric: boolean;
  electricRange: null;
  electricRangeCity: null;
  engineVolume: IPropertyDetails;
  firstRegistrationDate: string;
  frontTyreNotation: string;
  fuelType: IMappedProperty;
  gearbox: IMappedProperty;
  generation: string;
  height: IPropertyDetails;
  hybrid: boolean;
  length: IPropertyDetails;
  make: string;
  manufactureEnd: number;
  manufactureStart: number;
  manufacturedYear: number;
  model: string;
  numberOfCylinder: number;
  numberOfGears: number;
  oemVehicle: {
    typeDescription: string;
    trimLineName: string | null;
    makeDescription: string;
    modelDescription: string;
    fuelTypeLabel: string;
  };
  optionalEquipments: IEquipmentDetails[];
  pollutionNorm: string;
  powerHP: number;
  powerKW: number;
  priceBrutto: number;
  priceNetto: number;
  rearTyreNotation: string;
  responseId: string;
  responseStatus: {
    mariaMatchedModel: boolean;
    mariaNormalized: boolean;
    statusCode: string;
    statusMessage: string;
    success: boolean;
    vinDecoded: boolean;
  };
  seats: number;
  standardEquipments: IEquipmentDetails[];
  submodel: string;
  tankCapacity: IPropertyDetails;
  topSpeed: IPropertyDetails;
  torque: IPropertyDetails;
  trim: string;
  variant: string;
  vinCode: string;
  vinCountryRequest: string;
  vinLanguageRequest: string;
  weight: IPropertyDetails;
  width: IPropertyDetails;
}

export interface IPropertyDetails {
  value: number;
  unit: string;
}

export interface IMappedProperty {
  name: string;
  mapping: string;
  originalDescription: string;
}

export interface IEquipmentDetails {
  name: string;
  originalDescription: string;
  standard: boolean;
  packageFlag: boolean;
  priceBrutto?: number | null;
  currency?: string | null;
  equipmentItems: IEquipmentItems[] | null;
}

export interface IEquipmentItems {
  name: string;
  originalDescription: string;
  equipmentCategory: string;
}

export interface IOffersBySupplierType {
  oem: number;
  dealer: number;
  broker: number;
}

export interface IOffersByStatus {
  value: string;
  occurrence: number;
}

export interface IOfferListPayload {
  draw: number;
  page: number;
  itemsPerPage: number;
  searchFields: string[];
  search: string;
  query: {
    $and: [
      {
        status: {
          $in:
            | NewOffers[]
            | OngoingOffers[]
            | ExpiredOffers[]
            | ClosedOffers[]
            | RejectedOffers[]
            | PendingOffers[];
        };
      },
      { supplierType: { $eq: SupplierType } },
      { outdated: { $ne: boolean } },
    ];
  };
  sort: [{ property: string; ascending: boolean }, { property: string; ascending: boolean }];
}

export interface IOfferListResponse {
  draw: number;
  recordsTotal: number;
  error: null | string;
  data: IOfferListData[];
}

export interface IOfferListData {
  offerId: string;
  number: number;
  name: string;
  version: number;
  status: OfferStatus;
  type: string;
  publishedAt: string;
  expiresAt: string;
  supplierName: string;
  supplierType: SupplierType;
  carsDestination: string[];
  carsLocation: string;
  noOfCarOffers: number;
  noOfCars: number;
  localOffer: boolean;
}

export interface ICarOffers {
  id: string;
  number: string;
  tag: string;
  version: number;
  status: CarOfferStatus;
  depreciationStatus: string;
  carClass: string;
  carGroup: null | string;
  // TODO discuta despre tipul de date, posibil sa fie un enum
  carSegment: null | string;
  car: ICar;
  equipment: IEquipment[];
  carPrices: ICarPrices[];
}

export interface IEquipment {
  code: Equipment;
  value: boolean;
}

export enum Equipment {
  DIGITAL_DISPLAY = "DIGITAL_DISPLAY",
  NAVIGATION = "NAVIGATION",
  HEATED_SEATS = "HEATED_SEATS",
  PARKING_AID = "PARKING_AID",
  APPLE_PLAY_CONNECT = "APPLE_PLAY_CONNECT",
  LEATHER_INTERIOR = "LEATHER_INTERIOR",
  HEAT_PUMP = "HEAT_PUMP",
  PANORAMA = "PANORAMA",
  XENON = "XENON",
  ADAPTIVE_CRUISE = "ADAPTIVE_CRUISE",
  TOWBAR = "TOWBAR",
  CAMERA = "CAMERA",
  DRIVER_ASSIST = "DRIVER_ASSIST",
}

export interface ICar {
  make: string;
  logo: string;
  photo: string;
  model: string;
  variant: string;
  generation: string;
  submodel: string;
  trim: string;
  fuelType: string;
  gearbox: string;
  bodyType: string;
  drivingWheel: string;
  doors: number;
  seats: number;
  firstRegistrationDate: string;
  mileage: number;
  mileageUnit: string;
  manufacturedYear: number;
  manufactureStart: number;
  manufactureEnd: number;
  enginePower: number;
  enginePowerUnit: string;
  engineVolume: number;
  engineVolumeUnit: string;
  co2Nedc: string;
  co2NedcUnit: string;
  co2Wltp: string;
  co2WltpUnit: string;
  consumptionNedc: string;
  consumptionNedcUnit: string;
  consumptionWltp: string;
  consumptionWltpUnit: string;
  batteryCapacity: string;
  batteryCapacityUnit: string;
  weight: number;
  weightUnit: string;
  tankCapacity: number;
  tankCapacityUnit: string;
  rimSize: string;
  color: string;
  electric: boolean;
  hybrid: boolean;
}

export interface ICarPrices {
  analysisDocumentId: string;
  carOfferId: string;
  number: string;
  tag: string;
  version: number;
  createdAt: string;
  modifiedAt: string;
  units: number;
  registration: string;
  registrationCost: number;
  firstRegistrationDate: string;
  mileage: number;
  manufacturedYear: number;
  holdingPeriod: number;
  estimatedDeliveryPeriod: {
    year: number;
    quarter: string;
  };
  color: string;
  carPrice: number;
  equipmentPrice: number;
  transportPrice: number;
  totalCarPrice: number;
  discount: number;
  ecoBonus: number;
  volumeBonus: number;
  malus: number;
  netCapPrice: number;
  totalNetCapPrice: number;
  //TODO posibil sa trebuiasca un enum
  baseCurrency: string;
  notes: INotes[];
  //TODO discuta - posibil sa fie altceva
  documents: IDocument[];
}

export interface IDocument {
  name: string;
  id: string;
}

export interface INotes {
  value: string;
  addedAt: string;
  author: string;
}

export interface IOfferByNumberResponse {
  analysingCountry: string;
  offerId: string;
  offerNumber: number;
  offerName: string;
  offerVersion: number;
  offerStatus: OfferStatus;
  offerValidationStatus: OfferValidationStatus;
  offerCreatedAt: string;
  offerModifiedAt: string;
  offerPublishedAt: string;
  offerExpiresOn: string;
  offerContact: string;
  carsDestination: string[];
  carsLocation: string;
  //TODO discuta despre offer type RISK etc sloboz
  offerType: string;
  supplierName: string;
  supplierRating: number;
  carOffers: ICarOffers[];
}

export interface IMakeModelValidationResponse {
  id: string;
  carOfferId: string;
  createdAt: string;
  recommendedColours: Record<string, number>;
  whitelistValidation: {
    modelOk: boolean;
    validationFailedMessage: string;
  };
  countryCarClassVolumeValidation: {
    volumeOk: boolean;
    validationFailedMessage: string;
    riskTable: Array<{
      carClass: string;
      volumePercent: number;
      actualVolume: number;
      targetVolume: number;
      projectedVolume: number | null;
      analysedCarClass: boolean;
      volumeInTarget: boolean;
    }>;
  };
  mandatoryAccessoriesValidation: {
    mandatoryAccessoriesOk: boolean;
    mandatoryEquipment: Array<{ code: string; value: boolean }>;
    validationFailedMessage: string;
  };
  topSalesByAccessory: Array<{
    accessory: string;
    percent: number;
  }>;
}

export interface IDepreciationAnalysisResponse {
  carOfferId: string;
  carOfferNumber: string;
  carOfferVersion: number;
  carOfferStatus: string;
  localCountry: string;
  carClass: string;
  targetDepreciation: number;
  estimatedDepreciation: number;
  baseCurrency: string;
  //TODO discuss about status enum
  depreciationStatus: string;
  depreciations: DepreciationPeriods[];
  otherValidCountries: string[];
  depreciationByCountries: DeprectiationByCountry[];
  depreciationByOtherCountries: DeprectiationByCountry[];
  depreciationDerivatives: IDepreciationDerivative[];
  topSalesByFuelType: Array<{
    countryCode: string;
    topFuelTypeList: Array<{ fuelType: string; percent: number }>;
  }>;
  topSalesByVariant: Array<{
    countryCode: string;
    topVariantList: Array<{ make: string; model: string; percent: number; variant: string }>;
  }>;
  countryToBeSoldIn: string;
  holdingPeriodBeforeSelling: number;
  netCapPrice: number;
}

export interface IDepreciationDerivative {
  enginePower: number;
  fuelType: string;
  gearbox: string;
  variant: string;
  depreciations: IDepreciation[];
  isCurrentVariant: boolean;
}

export interface IDepreciation {
  marketValue: number;
  monthDepreciation: number;
  period: number;
  residualValue: number;
  residualValuePercent: number;
}

export interface DepreciationPeriods {
  marketValue: number;
  monthDepreciation: number;
  period: number;
  residualValue: number;
  residualValuePercent: number;
}

export interface DeprectiationByCountry {
  country: string;
  targetDepreciation: number;
  depreciations: DepreciationPeriods[];
  marketValueWithoutVat: number;
  quantitySold: number;
  salesFactor: number;
}

export interface ISimilarOffersInSegmentPayload {
  draw: number;
  page: number;
  itemsPerPage: number;
  searchFields: [];
  search: string;
  query: {
    $and: [
      { destinations: { $in: string[] } },
      { carClass: { $eq: string } },
      { estimatedDepreciationPeriod: { $eq: number } },
      { status: { $eq: CarOfferStatus } },
      { outdated: { $ne: boolean } },
    ];
  };
  sort: Array<{ property: string; ascending: boolean }>;
}

export interface ISimilarOffersInCarClassResponse {
  draw: number;
  recordsTotal: number;
  error: string | null;
  data: Array<{
    offerDate: string;
    carOfferDate: string;
    supplierName: string;
    carClass: string;
    make: string;
    model: string;
    registration: string;
    depreciation: number;
    baseCurrency: string;
    carsDestination: string[];
    depreciationPeriod: number;
    holdingPeriod: number;
    offerNumber: number;
    carOfferNumber: string;
    carOfferVersion: number;
    carOfferStatus: CarOfferStatus;
  }>;
}

export interface ISimilarOffersInCountriesPayload {
  draw: number;
  page: number;
  itemsPerPage: number;
  searchFields: [];
  search: string;
  query: {
    $and: [
      { "car.make": { $eq: string } },
      { "car.model": { $eq: string } },
      { "car.fuelType": { $eq: string } },
      { "car.gearbox": { $eq: string } },
      { "car.enginePower": { $eq: number } },
      { "car.manufacturedYear": { $eq: number } },
      { estimatedDepreciationPeriod: { $eq: number } },
      { status: { $eq: CarOfferStatus } },
      { destination: { $ne: string } },
    ];
  };
  sort: Array<{ property: string; ascending: boolean }>;
}

export interface ISimilarOffersInCountriesResponse {
  draw: number;
  recordsTotal: number;
  error: string | null;
  data: Array<{
    carOfferDate: string;
    carsDestination: string[];
    make: string;
    model: string;
    variant: string;
    generation: string;
    submodel: string;
    trim: string;
    fuelType: string;
    gearbox: string;
    enginePower: number;
    registration: string;
    netCapPrice: number;
    baseCurrency: string;
    offerNumber: number;
    carOfferNumber: string;
    carOfferVersion: number;
    carOfferStatus: CarOfferStatus;
    holdingPeriod: number;
    manufacturedYear: number;
    mileage: number;
  }>;
}

export interface IHertzError {
  code: string;
  message: string;
}

export interface IAppTable {
  column: {
    label: string;
    css?: string;
  };
  row: {
    label: string;
    css?: string;
    dataType?: "string" | "number";
  };
  sortable?: boolean;
}

export interface ISortPayload {
  property: string;
  ascending: boolean;
  dataType: "string" | "number";
}

export interface ICarGroups {
  id: string;
  code: string;
  van: boolean;
}

export interface IDepreciationPeriods {
  label: string;
  value: number;
}

export interface ITranslatedConstant {
  code: string;
  label: string;
}

export interface ISetSegmentPayload {
  carOfferNumber: string;
  carClass: string;
  carGroup: string;
  carSegment: string;
}

export interface IRejectSubOfferPayload {
  carOfferNumber: string;
  note?: string;
}

export interface IRequestCounterOfferPayload {
  carOfferNumber: string;
  units: number;
  holdingPeriod: number;
  transportPrice: number;
  discount: number;
  ecoBonus: number;
  volumeBonus: number;
  malus: number;
  note?: string | null;
}

export interface ICountry {
  name: string;
  alpha2Code: string;
}

export interface ICountryExtra extends ICountry {
  alpha3Code: string;
}

export enum FileType {
  PDF = "application/pdf",
  JPG = "image/jpeg",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  DOC = "application/msword",
}

export enum DocumentFileTypes {
  INFO = "INFO",
  ANALYSIS = "ANALYSIS",
  VALIDATION = "VALIDATION",
}

export interface IUploadDocument {
  file: Blob;
  offerReference: number;
  carOfferReference: string;
  type: DocumentFileTypes;
}

export interface IUploadDocumentResponse {
  carOfferReference: string | null;
  createdAt: string;
  createdBy: string;
  fileName: string;
  fileType: DocumentFileTypes;
  id: string;
  mimeType: string;
  offerReference: number;
}

export interface IDownloadFileResponse {
  fileContent: string;
  fileName: string;
  mimeType: FileType;
}

export interface IAcceptOfferValidationPayload {
  offerNumber: number;
  documentId: string;
}

export interface IOfferValidationResponse {
  offerId: string;
  offerNumber: number;
  offerName: string;
  offerVersion: number;
  offerStatus: OfferStatus;
  analysingCountry: string;
  offerValidationStatus: OfferValidationStatus;
  offerValidatedBy: string;
  offerValidatedAt: string;
  validationDocumentId: string;
  localOffer: boolean;
}

export interface IForwardOffer {
  offerNumber: number;
  country: string;
  email: string;
  emailContent: string;
}

export interface ISellInOtherCountryPayload {
  subofferId: string;
  holdingPeriodBeforeSelling: number;
  countryToBeSoldIn: string;
}

export interface IUserInfoResponse {
  adminUserId: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  status: any;
  roles: Roles;
  superAdmin: boolean;
  grantedAuthorities: string[];
}

export enum Acquisition {
  NEWCARS = "ADM_ACQUISITION_NEW_CARS",
  USEDCARS = "ADM_ACQUISITION_USED_CARS",
  AUCTIONS = "ADM_ACQUISITION_AUCTIONS",
}

export enum FleetControl {
  FLEETANALYSIS = "ADM_FLEET_CONTROL_FLEET_ANALYSIS",
  SALESPERFORMANCE = "ADM_FLEET_CONTROL_SALES_PERFORMANCE",
  FLEETPLANNING = "ADM_FLEET_CONTROL_FLEET_PLANNING",
  FLEETDEPRECIATION = "ADM_FLEET_CONTROL_FLEET_DEPRECIATION",
  FORECASTING = "ADM_FLEET_CONTROL_FORECASTING",
}

export enum MarketData {
  GEOSEARCH = "ADM_MARKET_DATA_GEO_SEARCH",
  GEOSALES = "ADM_MARKET_DATA_GEO_SALES",
  GEOANALYSIS = "ADM_MARKET_DATA_GEO_ANALYSIS",
  NEWCARSALES = "ADM_MARKET_DATA_NEW_CARS_SALES",
  MARKETMONITOR = "ADM_MARKET_DATA_MARKET_MONITOR",
  MARKETBENCHMARK = "ADM_MARKET_DATA_MARKET_BENCHMARK",
}

export enum FleetAdmin {
  FLEETADMINISTRATION = "ADM_FLEET_ADMIN_FLEET_ADMINISTRATION",
  ANOMALYDETECTION = "ADM_FLEET_ADMIN_ANOMALY_DETECTION",
  UPDATELOG = "ADM_FLEET_ADMIN_UPDATE_LOG",
  FLEETSETTINGS = "ADM_FLEET_ADMIN_FLEET_SETTINGS",
}

export enum Wholesale {
  WHOLESALE = "ADM_WHOLESALE_WHOLESALE",
}

export enum Reporting {
  QREVIEW = "ADM_REPORTING_Q_REVIEW",
  ABSVALUATIONS = "ADM_REPORTING_ABS_VALUATION",
}

export enum Tools {
  VINDECODE = "ADM_TOOLS_VIN_DECODE",
}

export enum Admin {
  ADMIN = "ROLE_ADMIN",
  SUPERADMIN = "ROLE_SUPER_ADMIN",
}

export enum MenuCategory {
  MENU_ACQUISITION = "MENU_ACQUISITION",
  MENU_FLEET_CONTROL = "MENU_FLEET_CONTROL",
  MENU_MARKET_DATA = "MENU_MARKET_DATA",
  MENU_FLEET_ADMIN = "MENU_FLEET_ADMIN",
  MENU_WHOLESALE = "MENU_WHOLESALE",
  MENU_REPORTING = "MENU_REPORTING",
  MENU_TOOLS = "MENU_TOOLS",
}

export type Permission =
  | Acquisition
  | MarketData
  | FleetControl
  | FleetAdmin
  | Wholesale
  | Tools
  | Reporting
  | Admin
  | MenuCategory;

export interface IVinCheckPayload {
  country: string;
  vinNumber: string;
}

export interface IMarkets {
  code: string;
  label: string;
  languages: ITranslatedConstant[];
}

export interface IAuctionsByStatus {
  value: string;
  occurrence: number;
}

export enum AuctionStatus {
  INITIATED = "INITIATED",
  PUBLISHED = "PUBLISHED",
  ANALYSING = "ANALYSING",
  ANALYSED = "ANALYSED",
  READY_FOR_AUCTION = "READY_FOR_AUCTION",
  AUCTION_FINALIZED = "AUCTION_FINALIZED",
  POST_ANALYSING = "POST_ANALYSING",
  POST_ANALYSED = "POST_ANALYSED",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
  REFUSED = "REFUSED",
  CREATED = "CREATED",
  CHECKING_VIN = "CHECKING_VIN",
  FAILED_ANALYSIS = "FAILED_ANALYSIS",
}

export enum NewAuctions {
  PUBLISHED = "PUBLISHED",
}

export enum OngoingAuctions {
  ANALYSING = AuctionStatus.ANALYSING,
  ANALYSED = AuctionStatus.ANALYSED,
  READY_FOR_AUCTION = AuctionStatus.READY_FOR_AUCTION,
  AUCTION_FINALIZED = AuctionStatus.AUCTION_FINALIZED,
  POST_ANALYSING = AuctionStatus.POST_ANALYSING,
  POST_ANALYSED = AuctionStatus.POST_ANALYSED,
  FAILED_ANALYSIS = AuctionStatus.FAILED_ANALYSIS,
}

export enum ExpiredAuctions {
  EXPIRED = "EXPIRED",
}

export enum ClosedAuctions {
  POST_ANALYSED = "POST_ANALYSED",
}

export interface IAuctionListPayload {
  page: number;
  itemsPerPage: number;
  status: string[];
  filterQuery?: Record<string, any>[];
}

export interface IAuctionListResponse {
  draw: number;
  recordsTotal: number;
  error: null | string;
  data: IAuction[];
}

export interface IAuction {
  auctionId: string;
  number: number;
  name: string;
  status: AuctionStatus;
  createdAt: Date;
  createdBy: string;
  publishedAt: Date;
  auctionStartDateTime: Date;
  supplierId: string;
  supplierName: string;
  supplierType: string; //TODO discuss this
  contactPerson: string;
  carsDestinations: string[];
  carsLocation: string;
  analysingCountry: string;
  noOfSelectedCars: number;
}

export interface IAuctionDetails {
  auctionId: string;
  number: string;
  name: string;
  status: AuctionStatus;
  createdAt: Date;
  modifiedAt: Date | null;
  publishedAt: Date;
  auctionStartDateTime: Date;
  supplierId: string;
  supplierName: string;
  supplierRating: string;
  contactPerson: string;
  createdBy: string;
  carsDestinations: string[];
  carsLocation: string;
  analysingCountry: string;
  estimatedExportTax: number;
  overwriteEstimatedExportTax: boolean;
  estimatedAuctionFee: number;
  overwriteEstimatedAuctionFee: boolean;
  baseCurrency: string;
  useVinLookup: boolean;
  postAnalysisDocumentId: string;
  importSynthesis: {
    noTotalCarsImported: number;
    noCarsSelected: number;
    noCarsVinChecked: number;
    noCarsVinDecoded: number;
    noCarsDeleted: number;
  };
  importedCarsDocumentId: string;
  processSynthesis: {
    noCarsInitialSelected: number;
    noCarsDeleted: number;
    noCarsAccepted: number;
    noCarsRefused: number;
    noCarsWon: number;
    noCarsLost: number;
  };
  chosenCarsDocumentId: string | null;
  failedAnalysisMessage: string | null;
}

export enum RegistrationStatus {
  NEW_IN_STOCK = "NEW_IN_STOCK",
  NEW_PRODUCTION = "NEW_PRODUCTION",
  SH = "SH",
}

export interface IAuctionCarsList {
  draw: number;
  recordsTotal: number;
  error: null | string;
  data: IAuctionCar[];
}

export interface IAuctionCar {
  auctionNumber: number;
  carAuctionId: string;
  status: CarAuctionStatus;
  auctionItemNo: string;
  registrationCountry: string;
  registrationNumber: string;
  vin: string;
  vinStatus: VinStatus;
  carClass: string;
  carGroup: string;
  carSegment: string;
  car: {
    make: string;
    model: string;
    generation: string;
    submodel: string;
    variant: string;
    trim: string;
    fuelType: string;
    gearbox: string;
    bodyType: string;
    enginePower: ICarDetailUnitValue;
    manufacturedYear: string;
    mileage: ICarDetailUnitValue;
    firstRegistrationDate: Date;
  };
  startingBid: number;
  vatType: VatType;
  hasCoc: boolean;
  cocCost: number;
  transport: number;
  estimatedExportTax: number;
  estimatedAuctionFee: number;
  baseCurrency: string;
  mechanicalGrading: MechanicalGrading;
  cosmeticGrading: CosmeticGrading;
  serviceHistory: ServiceHistory;
  hasSecondKey: boolean;
  secondKeyCost: number;
  vehicleSource: string;
  inspectionLink: string;
  notes: INotes[];
}

export type IAuctionCarFullDetails = IAuctionCar & {
  car: {
    drivingWheel: string;
    doors: number;
    seats: number;
    engineVolume: ICarDetailUnitValue;
    co2Nedc: ICarDetailUnitValue;
    co2Wltp: ICarDetailUnitValue;
    consumptionNedc: ICarDetailUnitValue;
    consumptionWltp: ICarDetailUnitValue;
    batteryCapacity: ICarDetailUnitValue;
    weight: ICarDetailUnitValue;
    tankCapacity: ICarDetailUnitValue;
    color: string;
    electric: boolean;
    hybrid: boolean;
    fuelTypeFromVin: string;
    fuelTypeInFile: {
      isFixed: boolean;
      isValid: boolean;
      value: string;
    };
    registrationDateAsPerVin: Date;
  };
  baseCurrency: string;
  equipmentCategories: IEquipment[];
  standardEquipments: IEquipmentDetails[];
  optionalEquipments: IEquipmentDetails[];
  vinDeliveryCountry: string | null;
  vinRequestedCountry: string;
};

interface ICarDetailUnitValue {
  value: number;
  unit: string;
}

export type IAuctionAnalysedCarsList = {
  draw: number;
  recordsTotal: number;
  error: null | string;
  data: AuctionAnalysisCar[];
};

export enum BeforeAuctionAnalysisStatus {
  NONE = "NONE",
  VALID = "VALID",
  NOT_VALID = "NOT_VALID",
  PARTIAL_VALID = "PARTIAL_VALID",
  FAILED = "FAILED",
}

export type AuctionAnalysisCar = IAuctionCar & {
  beforeAuctionAnalysisStatus: BeforeAuctionAnalysisStatus;
  beforeAuctionAnalysisDoneAt: Date | null;
  beforeAuctionAnalysisFailedMessage: string | null;
  validations: {
    modelOk: boolean;
    modelStatus: string;
    modelMessage: string;
    mandatoryAccessoriesOk: boolean;
    mandatoryAccessoriesStatus: string;
    mandatoryAccessoriesMessage: string;
    volumeOk: boolean;
    volumeStatus: string;
    volumeMessage: string;
    maxBidSelectedOk: boolean;
    maxBidSelectedStatus: string;
    maxBidSelectedMessage: string;
  } | null;
  maxBidSelectedValue: number;
  maxBidSelectedPeriod: number; //todo vezi ca este o lista
  afterAuctionAnalysisStatus: string;
  afterAuctionAnalysisDoneAt: Date | null;
  bidStatus: BidStatus;
  bidStatusDoneAt: Date | null;
};

export enum CarAuctionStatus {
  IMPORTED = "IMPORTED",
  IDENTIFIED = "IDENTIFIED",
  DELETED = "DELETED",
  INITIATED = "INITIATED",
  ANALYSED = "ANALYSED",
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED",
  POST_ANALYSED = "POST_ANALYSED",
}

export enum CosmeticGrading {
  CG1 = "CG1",
  CG2 = "CG2",
  CG3 = "CG3",
  CG4 = "CG4",
  CG5 = "CG5",
  UNGRADED = "UNGRADED",
}

export enum MechanicalGrading {
  A = "A",
  B = "B",
  c = "C",
  UNGRADED = "UNGRADED",
}

export enum ServiceHistory {
  PRESENT = "PRESENT",
  PRESENT_UNRECORDED = "PRESENT_UNRECORDED",
  INCOMPLETE = "INCOMPLETE",
  MISSING = "MISSING",
}

export enum VatType {
  EX_VAT = "EX_VAT",
  NON_REFUNDABLE_VAT = "NON_REFUNDABLE_VAT",
}

export enum VinStatus {
  DECODED = "DECODED",
  FAILED = "FAILED",
  UNATTEMPTED = "UNATTEMPTED",
}

export enum AuctionCarAnalysisValidationStatus {
  PASSED = "PASSED",
  NOT_PASSED = "NOT_PASSED",
  PARTIAL_PASSED = "PARTIAL_PASSED",
  UNKNOWN = "UNKNOWN",
}

export enum BidStatus {
  WON = "WON",
  LOST = "LOST",
  NONE = "NONE",
}

export interface CarAuctionsPayload {
  auctionNumber: number;
  page: number;
  itemsPerPage: number;
  sort?: ISortedField;
}

export interface ISortedField {
  property: string;
  ascending: boolean;
}

export type IEditAuctionCarDetailsPayload = Pick<
  IAuctionCarFullDetails,
  | "carAuctionId"
  | "transport"
  | "estimatedExportTax"
  | "estimatedAuctionFee"
  | "carClass"
  | "carGroup"
  | "carSegment"
> & { note: string; bodyType: string };

export interface ICarAuctionAnalysis {
  auctionNumber: number;
  carAuctionId: string;
  status: CarAuctionStatus;
  auctionItemNo: string;
  registrationCountry: string;
  registrationNumber: string;
  vin: string;
  vinStatus: VinStatus;
  vinFailureReason: string | null;
  vinLanguage: string | null;
  vinCountry: string | null;
  carClass: string;
  carGroup: string;
  carSegment: string;
  location: string;
  car: {
    make: string;
    model: string;
    generation: string;
    submodel: string;
    variant: string;
    trim: string;
    fuelType: string;
    gearbox: string;
    bodyType: string;
    drivingWheel: string;
    doors: number;
    seats: number;
    enginePower: ICarDetailUnitValue;
    engineVolume: ICarDetailUnitValue;
    manufacturedYear: string;
    mileage: ICarDetailUnitValue;
    firstRegistrationDate: Date;
    co2Nedc: ICarDetailUnitValue;
    co2Wltp: ICarDetailUnitValue;
    consumptionNedc: ICarDetailUnitValue;
    consumptionWltp: ICarDetailUnitValue;
    batteryCapacity: ICarDetailUnitValue;
    weight: ICarDetailUnitValue;
    tankCapacity: ICarDetailUnitValue;
    rimSize: string;
    color: string;
    electric: boolean;
    hybrid: boolean;
  };
  startingBid: number;
  vatType: VatType;
  transport: number;
  estimatedExportTax: number;
  estimatedAuctionFee: number;
  baseCurrency: string;
  equipmentCategories: IEquipment[];
  beforeAuctionAnalysisStatus: BeforeAuctionAnalysisStatus;
  beforeAuctionAnalysisFailedMessage: string | null;
  beforeAuctionAnalysisDoneAt: Date;
  makeAndModelValidation: {
    colorValidation: {
      recommendedColours: Record<string, number>;
      recommendedColoursOk: boolean;
      validationFailedMessage: string | null;
    };
    whitelistValidation: {
      modelOk: boolean;
      validationFailedMessage: string;
      modelStatus: string;
    };
    countryCarClassVolumeValidation: {
      volumeOk: boolean;
      volumeStatus: AuctionCarAnalysisValidationStatus;
      validationFailedMessage: string;
      riskTable: Array<{
        carClass: string;
        volumePercent: number;
        actualVolume: number;
        targetVolume: number;
        projectedVolume: number | null;
        analysedCarClass: boolean;
        volumeInTarget: boolean;
        maxAllowedVolume: number;
      }>;
    };
    mandatoryAccessoriesValidation: {
      mandatoryEquipment: IEquipment[];
      mandatoryAccessoriesOk: boolean;
      validationFailedMessage: string;
      mandatoryAccessoriesStatus: string;
    };
    topSalesByAccessory: Array<{
      accessory: string;
      percent: number;
    }>;
  };
  maxBidAnalysis: {
    country: string;
    carClass: string;
    targetDepreciation: number;
    maxBidSelectedValue: number;
    maxBidSelectedPeriod: number;
    maxBidSelectedOk: boolean;
    maxBidSelectedStatus: string; //todo add enum
    maxBidSelectedMessage: string;
    maxBidByPeriodList: Array<{
      period: number;
      futureValue: number;
      currentValue: number;
      maxBidValue: number;
      maxBidOk: boolean;
      maxBidStatus: string;
      validationFailedMessage: string;
    }>;
  };
  bidStatus: BidStatus;
  bidStatusDoneAt: Date | null;
  afterAuctionAnalysisStatus: string;
  afterAuctionAnalysisDoneAt: Date | null;
  afterAuctionAnalysis: string | null; // i don't know what type is this
}

interface IBasePaginatedRequest {
  draw: number;
  page: number;
  itemsPerPage: number;
  sort: ISortedField[];
  searchFields?: string[];
  search?: string;
  query?: Record<string, unknown>; //this could change
}
interface IBasePaginatedResponse {
  draw: number;
  recordsTotal: number;
  error: null | string;
}
export interface IAuctionSimilarCarsInCarClass extends IBasePaginatedResponse {
  data: Array<{
    auctionDate: Date;
    supplierName: string;
    carClass: string;
    carGroup: string;
    make: string;
    model: string;
    generation: string;
    submodel: string;
    variant: string;
    trim: string;
    fuelType: string;
    gearbox: string;
    bodyType: string;
    enginePower: ICarDetailUnitValue;
    manufacturedYear: number;
    registration: RegistrationStatus;
    maxBidSelectedPeriod: number;
    maxBidSelectedValue: number;
    baseCurrency: string;
    carDestination: string | null;
    auctionNumber: number;
    carAuctionId: string;
    carAuctionStatus: CarAuctionStatus;
  }>;
}

export interface IAuctionSimilarCarsInCarClassPayload {
  page: number;
  itemsPerPage: number;
  destination: string;
  maxBidSelectedPeriod: number;
  carClass: string;
}

export interface IResolutionReadyForAuctionCars extends IBasePaginatedResponse {
  data: IReadyForAuctionCar[];
}
export interface IReadyForAuctionCar {
  auctionNumber: number;
  carAuctionId: string;
  status: CarAuctionStatus;
  auctionItemNo: string;
  registrationCountry: string;
  registrationNumber: string;
  vin: string;
  vinStatus: VinStatus;
  vinFailureReason: string;
  carAuctionPrice: number;
  netCapCost: number;
  carClass: string;
  carGroup: string;
  carSegment: string;
  car: {
    make: string;
    model: string;
    generation: string;
    submodel: string;
    variant: string;
    trim: string;
    fuelType: string;
    gearbox: string;
    bodyType: string;
    enginePower: ICarDetailUnitValue;
    manufacturedYear: string;
    mileage: ICarDetailUnitValue;
    firstRegistrationDate: Date;
  };
  startingBid: number;
  vatType: VatType;
  hasCoc: boolean;
  cocCost: number;
  transport: number;
  estimatedExportTax: number;
  estimatedAuctionFee: number;
  baseCurrency: string;
  mechanicalGrading: MechanicalGrading;
  cosmeticGrading: CosmeticGrading;
  serviceHistory: ServiceHistory;
  hasSecondKey: boolean;
  secondKeyCost: number;
  vehicleSource: string;
  inspectionLink: string;
  notes: INotes[];
  beforeAuctionAnalysisStatus: BeforeAuctionAnalysisStatus;
  beforeAuctionAnalysisDoneAt: Date | null;
  validations: {
    modelOk: boolean;
    modelStatus: string;
    modelMessage: string;
    mandatoryAccessoriesOk: boolean;
    mandatoryAccessoriesStatus: string;
    mandatoryAccessoriesMessage: string;
    volumeOk: boolean;
    volumeStatus: string;
    volumeMessage: string;
    maxBidSelectedOk: boolean;
    maxBidSelectedStatus: string;
    maxBidSelectedMessage: string;
  };
  maxBidSelectedValue: number;
  maxBidSelectedPeriod: number; //todo vezi ca este o lista
  afterAuctionAnalysisStatus: string;
  afterAuctionAnalysisDoneAt: Date | null;
  bidStatus: BidStatus;
  bidStatusDoneAt: Date | null;
}

export interface IUpdateBidStatus {
  carAuctionId: string;
  carAuctionPrice: number;
  transport: number;
  estimatedExportTax: number;
  estimatedAuctionFee: number;
  bidStatus: BidStatus;
}

export interface IAnalysisPostAuction {
  afterAuctionAnalysis: {
    country: string;
    depreciationStatus: string;
    depreciations: IDepreciation[];
    estimatedDepreciation: number;
    estimatedDepreciationPeriod: number;
    estimatedPercentDifferenceFromTarget: number;
    marketValueWithoutVat: number;
    quantitySold: number;
    salesFactor: number;
    carClass: string;
    targetDepreciation: number;
  };
  afterAuctionAnalysisDoneAt: Date;
  afterAuctionAnalysisStatus: string;
  auctionItemNo: string;
  auctionNumber: number;
  baseCurrency: string;
  bidStatus: BidStatus;
  bidStatusDoneAt: Date;
  carAuctionId: string;
  carAuctionPrice: number;
  carGroup: string;
  carSegment: string;
  carClass: string;
  estimatedAuctionFee: number;
  estimatedExportTax: number;
  netCapCost: number;
  status: AuctionStatus;
  transport: number;
}

export interface IMarketMonitorCountry {
  iso: string;
  flag: string;
  name: string;
}

export interface IMarketMonitorVariantPayload {
  make: string;
  model: string;
  manufactureYearMin?: number;
  manufactureYearMax?: number;
}

export interface IMarketMonitorTrimPayload extends IMarketMonitorVariantPayload {
  variant: string[];
}

export interface IMarketMonitorVehicleOptionsPayload {
  make?: string;
  model?: string;
  manufactureYearMin?: number;
  manufactureYearMax?: number;
  variant?: string[];
  trim?: string;
}

export interface IIdentifiable {
  id: number;
  name: string;
}

export enum FleetSettingsTableType {
  TOF_PLANNING = "TOF_PLANNING",
  COMMITTED = "COMMITTED",
  PLANNED_DE_FLEET = "PLANNED_DE_FLEET",
  DEPRECIATION_TARGET = "DEPRECIATION_TARGET",
}

export enum FleetDepreciationTableTypes {
  TOTAL_DEP_TARGET = "TOTAL_DEP_TARGET",
  TOTAL_DEP_ACTUAL = "TOTAL_DEP_ACTUAL",
}

export enum FleetPlanningTableTypes {
  ACTIVE_FLEET = "ACTIVE_FLEET",
  MISSING_CARS = "MISSING_CARS",
  PLANNED_FLEET = "PLANNED_FLEET",
}

export interface IFleetSettingsPayload {
  country: string;
  year: number;
}

export interface IFleetIntegrationPayload extends IFleetSettingsPayload {
  tableType: FleetDepreciationTableTypes | FleetPlanningTableTypes;
}

export interface IFleetSettingsDepreciationResponse {
  depreciationTargetId: string;
  depreciationCarClassList: Array<{
    carClass: string;
    depreciationPerMonthList: Array<{
      month: string;
      depreciation: {
        value: number;
        currency: string;
        readonly: boolean;
        displayable: boolean;
      };
    }>;
    status: string;
  }>;
}

type FleetSettingsCommittedId = { committedId: string };

type FleetSettingsTOFPlanningId = { tofPlanningId: string };

type FleetSettingsPlannedDeFleetId = { plannedDeFleetId: string };

export type IFleetSettingsTableResponse = {
  fleetCarClassList: Array<{
    carClass: string;
    status: string;
    fleetCarGroupList: Array<{
      carGroup: string;
      status: string;
      carsNumberPerMonthList: Array<{
        month: string;
        noCars: {
          value: number;
          readonly: boolean;
          displayable: boolean;
        };
      }>;
    }>;
  }>;
} & FleetSettingsCommittedId &
  FleetSettingsTOFPlanningId &
  FleetSettingsPlannedDeFleetId;

export interface IFleetDepreciationResponse {
  depreciationCarClassList: Array<{
    carClass: string;
    status: string;
    depreciationPerMonthList: Array<{
      month: string;
      depreciation: {
        value: number;
        currency: string;
        readonly: boolean;
        displayable: boolean;
      };
    }>;
  }>;
}

export interface IFleetPlanningTableResponse {
  fleetCarClassList: Array<{
    carClass: string;
    status: string;
    fleetCarGroupList: Array<{
      carGroup: string;
      status: string;
      carsNumberPerMonthList: Array<{
        month: string;
        noCars: {
          value: number;
          readonly: boolean;
          displayable: boolean;
        };
      }>;
    }>;
  }>;
}

export interface IFleetSettingsDownloadFile extends IFleetSettingsPayload {
  tableType: FleetSettingsTableType;
}

export interface IFleetSettingsUpdateDepTargetPayload {
  carClass: string;
  depreciationRequestList: Array<{
    month: string;
    value: number;
    currency: string;
  }>;
}

export interface IFleetSettingsUpdateSettingsPayload {
  carClass: string;
  fleetCarGroupRequestList: Array<{
    carGroup: string;
    carsNumberPerMonthList: Array<{
      month: string;
      noCars: number;
    }>;
  }>;
}

export interface IWholesaleCarClassPayload {
  country: string;
}

export interface IWholesaleCarGroupsPayload extends IWholesaleCarClassPayload {
  carClass: string;
}

export interface IWholesaleModelCodesPayload extends IWholesaleCarGroupsPayload {
  carGroup: string;
}

export interface IWholesaleMakesPayload extends IWholesaleModelCodesPayload {
  modelCode: string;
}

export interface IWholesaleModelsPayload extends IWholesaleMakesPayload {
  make: string;
}

export interface IWholesaleBatchForAdminResponse {
  wholesaleBatchId: string;
  name: string;
  createdBy: string;
  createdAt: Date;
  modifiedBy: string | null;
  modifiedAt: Date | null;
  status: string;
  noActiveCars: number;
  noTotalCars: number;
}

export interface IWholesaleCarListByFilterPayload {
  country: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  make: string;
  model: string;
}

export interface IWholesaleCarListByFilterResponse {
  country: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  make: string;
  model: string;
  fuelType: string;
  noCarsSelected: number;
  averageBookValue: number;
  averageBbPrice: number;
  noCarsTofPlanning: number;
  noCarsCommitted: number;
  noCarsPlannedDeFleet: number;
  noCarsMissing: number;
  noCarsPlannedToBuy: number;
  noCarsActive: number;
  carStatus: string; //TODO could be an enum?
}

export interface IWholesaleListCarsByFilterIdPayload extends IWholesaleCarListByFilterPayload {
  fuelType: string;
}

export interface IWholesaleListCarsByFilterIdResponse {
  country: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  make: string;
  model: string;
  fuelType: string;
  powerHP: number;
  variant: string;
  gearbox: string;
  manufacturedYear: number;
  noCarsSelected: number;
  averageMileage: number;
  averageBookValue: number;
  averageBbPrice: number;
  averageCarsSoldLast30Days: number;
  otherCountries: Array<{
    country: string;
    averageBbPrice: number;
    averageCarsSoldLast30Days: number;
  }>;
}

export interface IGetAuctionBodyTypePayload {
  make: string;
  model: string;
  gearbox: string;
  fuelType: string;
  enginePower: number;
}

export interface IGetAuctionBodyTypeResponse {
  bodyType: string;
  count: number;
}

export interface IFleetOverallData {
  validCarsCount: number;
  invalidCarsCount: number;
}

export interface IFleetAssetsByFilterSelection {
  country: string;
  carClass?: string;
  carGroup?: string;
  modelCode?: string;
  ownershipTypes?: string[] | null;
}

export interface IFleetOwnershipType {
  valid: boolean | null;
}

export interface IFleetCountryPayload {
  valid: boolean | null;
  ownershipTypes?: string[] | null;
}

export interface IFleetCarClassesPayload extends IFleetCountryPayload {
  country: string;
}

export interface IFleetCarGroupsPayload extends IFleetCarClassesPayload {
  carClass: string;
}

export interface IFleetCarModelCodesPayload extends IFleetCarGroupsPayload {
  carGroup: string;
}

export interface IFleetMakesPayload extends IFleetCarModelCodesPayload {
  modelCode: string;
}

export interface IFleetModelsPayload extends IFleetMakesPayload {
  make: string;
}

export interface IFleetListByFilterPayload extends IBasePaginatedRequest {
  valid: boolean;
  country: string;
  carClass: string;
  carGroup?: string;
  modelCode?: string;
  make?: string;
  model?: string;
  ownershipTypes?: string[] | null;
}

export interface IFleetListByFilterResponse extends IBasePaginatedResponse {
  data: IFleetListByFilterData[];
}

export interface IFleetListByFilterData {
  country: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  make: string;
  model: string;
  noCarsSelected: string;
  valid: boolean;
  noTotalCars: string;
  ownershipTypes?: string[] | null;
}

export interface IFleetAdminIndividualCarListPayload extends IBasePaginatedRequest {
  query: {
    $and: Array<{
      active?: true;
      valid?: boolean;
      country?: string;
      carClass?: string;
      carGroup?: string;
      modelCode?: string;
      make?: string;
      model?: string;
      ownershipType?: {
        $in: string[];
      };
      vinNumber?: string;
    }>;
  };
}

export interface IFleetAdminIndividualCarListResponse extends IBasePaginatedResponse {
  data: FleetAdminIndividualCar[];
}

export type FleetAdminIndividualCar = {
  id: string;
  country: string;
  autoglassMarket: string;
  vinNumber: string;
  licensePlate: string;
  active: boolean;
  valid: boolean;
  vinSuccess: boolean;
  vinDecoded: boolean;
  matched: boolean;
  anomaly: boolean;
  anomalyMileageIncrease: boolean;
  anomalyMileageDecrease: boolean;
  anomalyFirstReg: boolean;
  anomalyBookValue: boolean;
  ownershipType: string; // todo maybe an enum,
  carClass: string;
  carGroup: string;
  modelCode: string;
  firstRegistrationDate: string;
  inFleetDate: string;
  outFleetDate: string | null;
  currency: string;
  capCost: number;
  bookValue: number;
  salePrice: number;
  capCostEur: number;
  bookValueEur: number;
  salePriceEur: number;
  exchangeRate: number;
  mileage: number;
  make: string;
  model: string;
  generation: string;
  submodel: string;
  variant: string;
  trim: string;
  fuelType: string;
  gearbox: string;
  bodyType: string;
  drivingWheel: string;
  manufacturedYear: number;
  powerHP: number;
  engineVolume: ICarDetailUnitValue;
  co2Nedc: ICarDetailUnitValue;
  co2Wltp: ICarDetailUnitValue;
  consumptionNedc: IPropertyDetails;
  consumptionWltp: IPropertyDetails;
  batteryCapacity: IPropertyDetails;
  weight: IPropertyDetails;
  tankCapacity: IPropertyDetails;
  equipmentCategories: string[];
  ageInFleetMonths: number;
  anomalyMileage: boolean;
};

export interface FleetAdminRecheckCarDetails {
  id: string;
  country: string;
  autoglassMarket: string;
  vinNumber: string;
  active: boolean;
  valid: boolean;
  validatedBy: string | null;
  validatedAt: string | null;
  vinSuccess: boolean;
  vinDecoded: boolean;
  matched: boolean;
  ownershipType: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  mileage: number;
  make: string;
  model: string;
  generation: string | null;
  submodel: string | null;
  variant: string | null;
  trim: string | null;
  fuelType: string;
  gearbox: string;
  bodyType: string | null;
  drivingWheel: string | null;
  manufacturedYear: number;
  powerHP: number;
  batteryCapacity: number | null;
}

export interface IFleetAdminCarSuggestionsPayload {
  fleetCarId: string;
  ownershipTypes?: string[];
}

export interface IFleetAdminImportedData {
  countryCode: string;
  licensePlate: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  make: string;
  model: string;
  modelDesc: string;
  kilometers: number;
  fuelType: string;
  gearbox: string;
  powerHP: number;
  engineSize: number;
  bodyType: string;
  firstRegistrationDate: string;
  bookValue: number;
  currency: string;
}
export interface IFleetTypeResponse {
  code: string;
  label: string;
}

export interface IFleetChartPayload {
  valuationType: string | null;
  valuationPeriod: number;
  countries: string[] | null;
  carClasses: string[] | null;
  makeModelPair: Array<{
    make: string;
    model: string;
  }> | null;
  splitByCountry: boolean;
  splitByCarClass: boolean;
  manufactureStart: number | null;
  manufactureEnd: number | null;
  includeAnomaly: boolean | null;
}

export interface IFleetChartResponse {
  label: string;
  fleetValues: Array<{
    date: string;
    fleetValue: number;
    noCarsSelected: number;
    noAnomalyCars: number;
    noCars: number;
    noCarsNotValuated: number;
    fleetBookValue: number;
  }>;
}

export interface IFleetCurrentValuationsPayload extends IBasePaginatedRequest {
  countries: string[] | null;
  carClasses: string[] | null;
  manufactureStart: number | null;
  manufactureEnd: number | null;
  includeAnomaly: boolean | null;
}

export interface IFleetCurrentValuationsByCarPayload extends IBasePaginatedRequest {
  countries: string[] | null;
  carClasses: string[] | null;
  makeModelPair: Array<{
    make: string;
    model: string;
  }>;
  includeAnomaly: boolean | null;
}

export interface IFleetCurrentValuationsData {
  date: string;
  country: string;
  carClass: string;
  noCarsSelected: number;
  noAnomalyCars: number;
  noCars: number;
  totalBbPrice: number;
  totalBookValue: number;
  difference: number;
  percentDifference: number;
}

export interface IFleetCurrentValuationsByCarData {
  date: string;
  country: string;
  carClass: string;
  make: string;
  model: string;
  modelCode: string;
  fuelType: string;
  gearbox: string;
  manufacturedYear: number;
  noCarsSelected: number;
  noCars: number;
  noAnomalyCars: number;
  totalBbPrice: number;
  totalBookValue: number;
  difference: number;
  percentDifference: number;
}

export interface IFleetCurrentValuationsResponse extends IBasePaginatedResponse {
  data: IFleetCurrentValuationsData[];
}

export interface IFleetCurrentValuationsByCarResponse extends IBasePaginatedResponse {
  data: IFleetCurrentValuationsByCarData[];
}

export interface IFleetAvgFleetChart {
  label: string;
  date: string[];
  fleetBookValue?: number[];
  fleetValue?: number[];
}

export interface IFleetCarsChart {
  label: string;
  date: string[];
  noCarsSelected: number[];
}

export interface IFleetCarsWithAnomalies {
  label: string;
  date: string[];
  noAnomalyCars: number[];
}

export interface IFleetCarsNotValuated {
  label: string;
  date: string[];
  noCarsNotValuated: number[];
}

export interface IFleetCarsChartActual {
  label: string;
  date: string[];
  noCars: number[];
}

export interface IFleetListMakesPayload {
  countries: string[] | null;
  carClasses: string[] | null;
}

export interface IFleetListModelsPayload {
  countries: string[] | null;
  carClasses: string[] | null;
  make: string;
}

export interface IFleetModelViewChartPayload {
  valuationPeriod: number;
  country: string | null;
  carClass: string | null;
  make: string | null;
  model: string | null;
  modelCode: string | null;
  fuelType: string | null;
  gearbox: string | null;
  manufacturedYear: number;
  manufactureStart: number | null;
  manufactureEnd: number | null;
  includeAnomaly: boolean | null;
}

export interface IFleetModelViewChartResponse {
  label: string;
  fleetValues: Array<{
    date: string;
    fleetValue: number;
    noCarsSelected: number;
    noAnomalyCars: number;
    noCars: number;
    noCarsNotValuated: number;
    fleetBookValue: number;
  }>;
}

export interface IFleetModelViewChart {
  label: string;
  date: string[];
  fleetValue: number[];
  fleetBookValue: number[];
}

export interface IFleetModelViewTablePayload {
  draw: number;
  page: number;
  itemsPerPage: number;
  searchFields: string[];
  search: string;
  query: {
    $and: [
      {
        country: string;
        carClass: string;
        make: string;
        model: string;
        modelCode: string;
        fuelType: string;
        gearbox: string;
        manufacturedYear: number;
        anomaly?: boolean;
      },
      {
        date: {
          $eq: {
            $date: string;
          };
        };
      },
      {
        $or?: [{ anomaly: null }, { anomaly: boolean }];
      },
    ];
  };
  sort: [{ property: string; ascending: boolean }];
}

export interface IFleetModelViewCar {
  fleetCarId: string;
  date: string;
  country: string;
  vinNumber: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  make: string;
  model: string;
  fuelType: string;
  gearbox: string;
  mileage: number;
  manufacturedYear: number;
  firstRegistrationDate: string;
  anomaly: boolean | null;
  bookValueEur: number;
  bbPrice: number;
  bbPriceCorrected: number;
}
export interface IRecentMakesResponse {
  logo: string;
  make: string;
  popular: boolean;
}

export interface IRecentModelsResponse {
  model: string;
  image: string;
  count: number;
}

export interface IRecentGenerationsResponse {
  generation: string;
  image: string;
  start: number;
  end: number;
  count: number;
}

export interface IRecentSubmodelsResponse {
  subModel: string;
  image: string;
  start: number;
  end: number;
  count: number;
}

export interface IAutoFillFormPayload {
  make: string;
  model: string;
  generation: string;
  submodel: string;
  variant: string;
  fuel: string;
  gearbox: string;
  enginePower: number | string;
  trim: string;
}

export interface IAutoFillFormResponse {
  chassis: string[];
  drivingWheel: string[];
  engineVolume: ICarDetailUnitValue[];
  co2Nedc: ICarDetailUnitValue[];
  co2Wltp: ICarDetailUnitValue[];
  consumptionNedc: ICarDetailUnitValue[];
  consumptionWltp: ICarDetailUnitValue[];
  batteryCapacity: ICarDetailUnitValue[];
  weight: number[];
  basePrice: number[];
  electric: boolean;
  hybrid: boolean;
}

export type SubmodelPayload = Pick<IAutoFillFormPayload, "make" | "model" | "generation">;

export type VariantsPayload = Pick<
  IAutoFillFormPayload,
  "make" | "model" | "generation" | "submodel"
>;

export type TrimsPayload = Pick<
  IAutoFillFormPayload,
  "make" | "model" | "generation" | "submodel" | "variant"
>;

export type CharacteristicsPayload = Pick<
  IAutoFillFormPayload,
  "make" | "model" | "generation" | "submodel" | "variant" | "trim"
>;

export interface IRecentVariantResponse {
  variant: string;
  count: number;
}

export interface IRecentTrimsResponse {
  trim: string;
  image: string;
  count: number;
}

export interface ICharacteristicsResponse {
  make: string;
  variant: string;
  fuelType: string;
  gearbox: string;
  enginePower: number;
  count: number;
}

export interface IFleetAdminManualIdentificationPayload {
  carId: string;
  make: string;
  model: string;
  variant: string;
  generation: string;
  submodel: string;
  trim: string;
  fuelType: string;
  gearbox: string;
  bodyType: string;
  drivingWheel: string;
  powerHP: number;
  manufacturedYear: number;
  enginePower: ICarDetailUnitValue;
  engineVolume: ICarDetailUnitValue;
  co2Nedc: ICarDetailUnitValue;
  co2Wltp: ICarDetailUnitValue;
  consumptionNedc: IPropertyDetails;
  consumptionWltp: IPropertyDetails;
  batteryCapacity: IPropertyDetails;
  weight: IPropertyDetails;
  tankCapacity: IPropertyDetails;
  equipmentCategories: string[];
}

export interface IFleetAdminCountMultiEditCarsPayload {
  vinSuccess: boolean;
  country: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  make: string;
  model: string;
  ownershipTypes?: string[] | null;
}

export interface IFleetModelViewTableResponse extends IBasePaginatedResponse {
  data: IFleetModelViewCar[];
}

export interface IFleetChartIndividualCarPayload {
  valuationPeriod: number;
  fleetCarId: string;
}

export interface IFleetCarValue {
  date: string;
  bbPrice: number;
}

export interface IFleetChartIndividualCarResponse {
  fleetCarId: string;
  modelCode: string;
  vinNumber: string;
  registrationNumber: string;
  make: string;
  model: string;
  variant: string;
  fuelType: string;
  gearbox: string;
  manufacturedYear: number;
  carValues: IFleetCarValue[];
}

export interface IFleetAdminCountMultiEditCarsResponse {
  noCarsSelected: number;
}

export interface IFleetAdminMultiEditSimilarCars {
  country: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
}

export interface IFleedAdminMultiEditConfirmCarsPayload {
  filter: IFleetAdminCountMultiEditCarsPayload;
  edit: Omit<IFleetAdminManualIdentificationPayload, "carId">;
}

export interface IFleedAdminMultiEditConfirmCarsResponse {
  item: string;
  existingValues: Array<{ value: string; occurrence: number }>;
  newValueToConvertTo: { value: string; occurrence: number };
}

export interface IFleetAdminUpdateLogPayload extends IBasePaginatedRequest {
  query: {
    importDay: {
      $gte: { $date: string };
      $lte: { $date: string };
    };
    status: string;
  };
}

export interface IFleetAdminResetBatchPayload {
  ownershipTypes?: string[] | null;
  country: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  make: string;
  model: string;
}

export interface IFleetAdminUpdateLogResponse extends IBasePaginatedResponse {
  data: IFleeteUpdateLog[];
}

export interface IFleeteUpdateLog {
  importDay: string;
  carsInFleet: number;
  deFleetedCars: number;
  newCars: number;
  successfulVinCheck: number;
  invalidatedCars: number;
  raisedAnomalies: number;
  raisedAnomaliesMileageIncrease: number;
  raisedAnomaliesMileageDecrease: number;
  raisedAnomaliesFirstReg: number;
  raisedAnomaliesBookValue: number;
  carsForManualUpdate: number;
  status: string; //this could be an ENUM
}

export interface IAnomaliesTodayResponse {
  importDay: string;
  raisedAnomalies: number;
  raisedAnomaliesMileage: number;
  raisedAnomaliesFirstReg: number;
  raisedAnomaliesBookValue: number;
}

export interface IAnomaliesPerMonthPayload {
  country: string;
  year: number;
  month: number;
}

export interface IAnomaliesPerMonthResponse {
  country: string;
  year: number;
  month: number;
  raisedAnomalies: number;
  raisedAnomaliesMileage: number;
  raisedAnomaliesFirstReg: number;
  raisedAnomaliesBookValue: number;
}

export interface IAnomayCarsPayload extends IBasePaginatedRequest {
  query: {
    $and: {
      country: string;
      anomaly: boolean;
      active: boolean;
    }[];
  };
}

export interface IAnomayCarsResponse extends IBasePaginatedResponse {
  data: FleetAdminIndividualCar[];
}

export type AnomalyType =
  | "MILEAGE_INCREASE"
  | "MILEAGE_DECREASE"
  | "FIRST_REGISTRATION_DATE"
  | "BOOK_VALUE";

//TODO this should be a discriminated union type
export type AnomaliesToFixResponse = {
  fleetCarId: string;
  anomalyType: AnomalyType;
  lastValidDate: string;
  bookValue?: number | null;
  previousBookValue?: number | null;
  firstRegistrationDate?: string | null;
  previousFirstRegistrationDate?: string | null;
  previousMileage?: number | null;
  mileage?: number | null;
  previousCurrency?: string;
  currency?: string;
};

export interface IAnomaliesImportHistoryPayload extends IBasePaginatedRequest {
  searchFields: ["createdAt"];
  query: {
    $and: [{ fleetCarId: string }];
  };
}

export interface IAnomaliesImportHistoryResponse extends IBasePaginatedResponse {
  data: Array<{
    importDay: string;
    fleetCarId: string;
    anomaly: boolean;
    anomalyMileageIncrease: boolean;
    anomalyMileageDecrease: boolean;
    anomalyFirstReg: boolean;
    anomalyBookValue: boolean;
    firstRegistrationDate: string;
    mileage: number;
    bookValue: number;
    currency: string;
    changeLogTypes: string[];
  }>;
}

export interface IMarkAnomalyAsFixedPayload {
  fleetCarId: string;
  anomalyType: AnomalyType;
}

export interface IInvalidGroupedByModelCodesPayload {
  countries: string[];
  carClasses: string[];
}

export interface IInvalidGroupedByModelCodesResponse {
  country: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  invalidCarsCount: number;
}

export interface ISalesPerformanceCountriesPayload {
  from: string;
  to: string;
}

export interface ISalesPerformanceCarClassesPayload {
  from: string;
  to: string;
  country: string;
}

export interface ISalesPerformanceCarGroupsPayload {
  from: string;
  to: string;
  country: string;
  carClass: string;
  saleChannels: string[] | null;
}

export interface ISalesPerformanceModelCodesPayload {
  from: string;
  to: string;
  country: string;
  carClass: string;
  saleChannels: string[] | null;
  carGroups: string[] | null;
}

export interface ISalesPerformanceMakesPayload {
  from: string;
  to: string;
  country: string;
  carClass: string;
  saleChannels: string[] | null;
  carGroups: string[] | null;
  modelCodes: string[] | null;
}

export interface ISalesPerformanceModelsPayload {
  from: string;
  to: string;
  country: string;
  carClass: string;
  saleChannels: string[] | null;
  carGroups: string[] | null;
  modelCodes: string[] | null;
  makes: string[] | null;
}

export interface ISalesPerformanceChartPayload {
  from?: string | null;
  to?: string | null;
  country: string;
  carClass: string;
  carGroups?: string[];
  modelCodes?: string[];
  makes?: string[];
  models?: string[];
  chartView?: string;
  manufactureStart?: number | null;
  manufactureEnd?: number | null;
}

export interface ISalesPerformanceChartResponse {
  chartView: string;
  chartViewOptions: string[];
  daysRange: number;
  saleValues: Array<{
    from: string;
    to: string;
    label: string;
    salePerformancePercent: number | null;
    noCarsSelected: number | null;
    noAnomalyCars: number | null;
  }>;
}

export interface ISalesPerformanceChart {
  label: string[];
  salePerformancePercent: (number | null)[];
}

export interface ISalesPerformanceSummaryPayload {
  from?: string | null;
  to?: string | null;
  country: string;
  carClass: string;
  carGroups?: string[];
  modelCodes?: string[];
  makes?: string[];
  models?: string[];
  manufactureStart?: number | null;
  manufactureEnd?: number | null;
}

export interface ISalesPerformanceSummaryResponse {
  from: string;
  to: string;
  country: string;
  carClass: string;
  avgBbPrice: number;
  avgBbPriceCorrected: number;
  avgSalePrice: number;
  avgMileage: number;
  avgDamage: number;
  avgEstimatedDamage: number;
  salePerformance: number;
  salePerformancePercent: number;
  noCarsSelected: number;
  noAnomalyCars: number;
}

export interface ISalesPerformanceListTablePayload extends IBasePaginatedRequest {
  query: {
    $and: {
      valid: boolean;
      saleValuation: boolean;
      outFleetDate: {
        $gte: Date;
        $lte: Date;
      };
      country: string;
      carClass: string;
      carGroup: {
        $in: string[];
      };
      modelCode: {
        $in: string[];
      };
      make: {
        $in: string[];
      };
      model: {
        $in: string[];
      };
      $or?: {
        anomaly: null | boolean;
      }[];
    }[];
  };
  manufactureStart?: number | null;
  manufactureEnd?: number | null;
}

export interface ISalesPerformanceListTableResponse extends IBasePaginatedResponse {
  data: ISalesPerformanceListTableData[];
}

export interface ISalesPerformanceListTableData {
  country: string;
  outFleetDate: string;
  vinNumber: string;
  anomaly: boolean;
  carClass: string;
  carGroup: string;
  modelCode: string;
  make: string;
  model: string;
  fuelType: string;
  gearbox: string;
  powerHP: number;
  mileage: number;
  manufacturedYear: number;
  firstRegistrationDate: string;
  date: string;
  bbPrice: number;
  bbPriceCorrected: number;
  salePriceEur: number;
  capCostEur: number;
  bookValueEur: number;
  damageCostEur: number;
  estimatedDamageCostEur: number;
  profit: number;
  actualSaleProfit: number;
  fleetCarId: string;
  inFleetDate: string;
  ageMonths: number;
  ageInFleetMonths: number;
  saleChannel: string;
  saleCountry: string;
  saleType: string;
  bbPriceCountry: string;
}

export interface ISalesPerformanceCarDetailsAndPricesResponse {
  fleetCarId: string;
  country: string;
  vinNumber: string;
  licensePlate: string;
  inFleetDate: string;
  outFleetDate: string;
  inactivatedDate: string;
  validatedAt: string | null;
  validatedBy: string | null;
  bbPriceDay: string;
  spotPrice?: number;
  spotLevel: string | null;
  spotAvgMileage: number | null;
  bbPrice: number;
  bbRatio: number | null;
  bbPriceCorrected: number;
  bbGeoPrice: number;
  bbGeoPriceCorrected: number;
  salePriceEur: number;
  capCostEur: number;
  bookValueEur: number;
  damageCostEur: number;
  profit: number;
  actualSaleProfit: number;
  anomaly: boolean;
  anomalyTypes: string[];
  ownershipType: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  firstRegistrationDate: string;
  manufacturedYear: number;
  ageMonths: number;
  ageInFleetMonths: number;
  mileage: number;
  make: string;
  model: string;
  generation: string;
  submodel: string;
  variant: string;
  trim: string;
  modelYear: number;
  fuelType: string;
  gearbox: string;
  bodyType: string;
  drivingWheel: string;
  powerHP: number;
  engineVolume: IPropertyDetails;
  co2Nedc: IPropertyDetails;
  co2Wltp: IPropertyDetails;
  consumptionNedc: null | string | number;
  consumptionWltp: null | string | number;
  batteryCapacity: IPropertyDetails;
  weight: IPropertyDetails;
  tankCapacity: IPropertyDetails;
  equipmentCategories: string[];
  saleChannel: string;
  saleCountry: string;
  saleType: string;
  bbPriceCountry: string;
}

export interface ISalesPerformanceSalesChannelPayload {
  from: string;
  to: string;
  country: string;
  carClass: string;
}

export interface IUserRolesPayload {
  draw: number;
  page: number;
  itemsPerPage: number;
  searchFields: string[];
  search: string;
  query: {
    $and: {
      admin: boolean;
      custom: boolean;
    }[];
  };
  sort: {
    property: string;
    ascending: boolean;
  }[];
}

export interface IAllUserRolesPayload {
  draw: number;
  page: number;
  itemsPerPage: number;
  searchFields: string[];
  search: string;
  query: {
    $and: Array<Record<string, any>>;
  };
  sort: Array<{
    property: string;
    ascending: boolean;
  }>;
}

export interface IUserRolesResponse {
  draw: number;
  recordsTotal: number;
  error: null | string;
  data: {
    id: string;
    name: string;
    description: string;
    admin: boolean;
    custom: boolean;
    permissions: string[];
    createdAt: string;
    createdBy: string;
    modifiedAt: string;
    modifiedBy: string;
  }[];
}

export interface ICreateCustomRolePayload {
  description: string;
  admin: boolean;
  permissions: string[];
}

export interface ICreateCustomRoleResponse {
  id: string;
  createdAt: string;
  modifiedAt: string;
  name: string;
  description: string;
  custom: boolean;
  admin: boolean;
  permissions: string[];
  createdBy: string;
  modifiedBy: string;
}

export interface IListAdminUsersPayload {
  draw: number;
  page: number;
  itemsPerPage: number;
  searchFields: string[];
  search: string;
  query: any;
  sort: {
    property: string;
    ascending: boolean;
  }[];
}

export interface IUserAdminData {
  adminUserId: string;
  createdAt: string;
  modifiedAt: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
  status: string;
  operationalCountries: string[];
  roles: string[];
  area: string;
  superAdmin: boolean;
  fullName: string;
}

export interface IListAdminUsersResponse extends IBasePaginatedResponse {
  data: IUserAdminData[];
}

export interface ICreateNewAdminPayload {
  firstName: string;
  lastName: string;
  superAdmin: boolean;
  email: string;
  area: string;
  operationalCountries: string[];
  roles: string[];
}

export interface IGetAdminUserDetailsResponse {
  adminUserId: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
  status: string;
  area: string;
  superAdmin: boolean;
  operationalCountries: string[];
  roles: string[];
  permissions: string[];
  fullName: string;
}

export interface ILightAdminUserDetail {
  firstName: string;
  lastName: string;
  email: string;
  area: string;
  fullName: string;
}

export interface ILightAdminUserDetailsResponse extends IBasePaginatedResponse {
  data: ILightAdminUserDetail[];
}

export interface IQueryCondition {
  [key: string]: any;
}

export interface ISignInLogsPayload {
  draw: number;
  page: number;
  itemsPerPage: number;
  searchFields: string[];
  search: string;
  query: {
    $and: IQueryCondition[];
  };
  sort: Array<{
    property: string;
    ascending: boolean;
  }>;
}

export interface ISignInRecord {
  properties: {
    userId: string;
    userDisplayName: string;
    userPrincipalName: string;

    deviceDetail: {
      browser: string;
      deviceId: string;
      displayName: string | null;
      operatingSystem: string;
    };
    location: {
      city: string;
      state: string;
      countryOrRegion: string;
    };
    countryCode: string;
    status: {
      additionalDetails: string | null;
      errorCode: number;
      failureReason: string | null;
    };
  };

  signInTime: string;
  signInApplication: string;
  callerIpAddress: string;
  location: string;
}

export interface ISignInLogsResponse extends IBasePaginatedResponse {
  data: ISignInRecord[];
}

export interface IForecastChartPayload {
  countries: string[] | null;
  carClasses: string[] | null;
  makeModelPair: Array<{
    make: string;
    model: string;
    modelCode: string;
  }> | null;
  splitByCountry: boolean;
  splitByCarClass: boolean;
  manufactureStart: number | null;
  manufactureEnd: number | null;
  includeAnomaly: boolean | null;
}

export interface IForecastChartResponse {
  label: string;
  fleetValues: Array<{
    date: string;
    fleetValue: number;
    noCarsSelected: number;
    noAnomalyCars: number;
    noCars: number;
    noCarsNotValuated: number;
    fleetBookValue: number;
  }>;
}

export interface IForecastMakesPayload {
  countries: string[] | null;
  carClasses: string[] | null;
}

export interface IForecastModelsPayload {
  countries: string[] | null;
  carClasses: string[] | null;
  make: string;
}

export interface IForecastModelCodesPayload {
  countries: string[] | null;
  carClasses: string[] | null;
  make: string;
  model: string;
}

export interface IForecastListByCountryAndCarClassPayload extends IBasePaginatedRequest {
  countries: string[] | null;
  carClasses: string[] | null;
  manufactureStart: number | null;
  manufactureEnd: number | null;
}

export interface IForecastListByCountryAndCarClassData {
  date: string;
  country: string;
  carClass: string;
  noCarsSelected: number;
  noAnomalyCars: number;
  noCars: number;
  forecastingPrices: {
    monthNo: number;
    date: string;
    avgBookValue: number;
    avgBbPrice: number;
    avgBbPriceCorrected: number;
  }[];
  avgCapCost: number;
  avgBookValue: number;
  avgBbPrice: number;
  avgBbPriceCorrected: number;
}

export interface IForecastListByCountryAndCarClassResponse extends IBasePaginatedResponse {
  data: IForecastListByCountryAndCarClassData[];
}

export interface IForecastListByMakeAndModelPayload extends IBasePaginatedRequest {
  countries: string[] | null;
  carClasses: string[] | null;
  makeModelPair: Array<{
    make: string;
    model: string;
  }>;
}

export interface IForecastListByMakeAndModelData {
  date: string;
  country: string;
  carClass: string;
  make: string;
  model: string;
  modelCode: string;
  fuelType: string;
  gearbox: string;
  manufacturedYear: number;
  noCarsSelected: number;
  noCars: number;
  noAnomalyCars: number;
  avgCapCost: number;
  avgBookValue: number;
  avgBbPrice: number;
  avgBbPriceCorrected: number;
  forecastingPrices: {
    monthNo: number;
    date: string;
    avgBookValue: number;
    avgBbPrice: number;
    avgBbPriceCorrected: number;
  }[];
  totalBbPrice: number;
  totalBookValue: number;
  difference: number;
  percentDifference: number;
}

export interface IForecastListByMakeAndModelResponse extends IBasePaginatedResponse {
  data: IForecastListByMakeAndModelData[];
}

export interface IForecastListByModelCodePayload {
  draw: number;
  page: number;
  itemsPerPage: number;
  searchFields: string[];
  search: string;
  query: {
    $and: [
      {
        date: {
          $eq: {
            $date: string;
          };
        };
      },
      {
        ownershipType: string;
        valid: boolean;
        futureValuation: boolean;
        country: string;
        carClass: string;
        make: string;
        model: string;
        modelCode: string;
        fuelType: string;
        gearbox: string;
        manufacturedYear: number;
      },
      {
        $or?: [
          {
            anomaly: boolean | null;
          },
          {
            anomaly: boolean | null;
          },
        ];
      },
    ];
  };
  sort: {
    property: string;
    ascending: boolean;
  }[];
}

export interface IForecastListByModelCodeData {
  fleetCarId: string;
  date: string;
  country: string;
  vinNumber: string;
  carClass: string;
  carGroup: string;
  modelCode: string;
  ownershipType: string;
  make: string;
  model: string;
  fuelType: string;
  gearbox: string;
  mileage: number;
  manufacturedYear: number;
  firstRegistrationDate: string;
  anomaly: boolean;
  capCostEur: number;
  bookValueEur: number;
  bbPrice: number;
  bbPriceCorrected: number;
  forecastingPrices: {
    monthNo: number;
    date: string;
    avgBookValue: number;
    avgBbPrice: number;
    avgBbPriceCorrected: number;
  }[];
}

export interface IForecastListByModelCodeResponse extends IBasePaginatedResponse {
  data: IForecastListByModelCodeData[];
}

export interface IForecastChartByModelCodePayload {
  country: string;
  carClass: string;
  make: string;
  model: string;
  modelCode: string;
  fuelType: string;
  gearbox: string;
  manufacturedYear: number;
  includeAnomaly: boolean;
}

export interface IForecastChartByModelCodeResponse {
  label: string;
  fleetValues: Array<{
    date: string;
    fleetValue: number;
    fleetBookValue: number;
    noCarsSelected: number;
    noAnomalyCars: number;
    noCars: number;
    noCarsNotValuated: number;
  }>;
}

export interface IForecastChartByModelCode {
  label: string;
  date: string[];
  fleetValue: number[];
  fleetBookValue: number[];
}

export interface IForecastCarValue {
  date: string;
  bbPrice: number;
  bookValue: number;
}

export interface IForecastChartForIndividualCarResponse {
  fleetCarId: string;
  modelCode: string;
  vinNumber: string;
  registrationNumber: string;
  make: string;
  model: string;
  variant: string;
  fuelType: string;
  gearbox: string;
  manufacturedYear: number;
  anomaly: boolean;
  carValues: IForecastCarValue[];
}

export interface IValuationVerificationUpload {
  file: Blob;
}

export interface IValuationVerificationResponse {
  carOfferReference: string | null;
  createdAt: string;
  createdBy: string;
  fileName: string;
  fileType: DocumentFileTypes;
  id: string;
  mimeType: string;
  offerReference: number;
}

export interface IValuationCreateUpload {
  name: string;
  file: Blob;
}

export interface ICreateValuationResponse {
  createdAt: string;
  createdBy: string;
  number: number;
  status: string;
  overallSummary: {
    noCarsImported: number;
    noCarsWithSuccessValuation: number;
    noCarsWithFailedValuation: number;
    noCarsUnidentified: number;
    noCarsInvalid: number;
    noCarsNotCalculated: number;
    totalFleetValue: number;
    averageFleetValue: number;
    currency: string;
  };
}

export interface IValuationProcessingStateResponse {
  code: string;
  message: string;
}

export interface IValuationListPayload extends IBasePaginatedRequest {
  query: {
    $and: Array<{
      createdAt: {
        $gte: {
          $date: string;
        };
        $lte: {
          $date: string;
        };
      };
    }>;
  };
}

export interface IValuationList {
  createdAt: string;
  createdBy: string;
  number: number;
  status: string;
  name: string;
  overallSummary: {
    noCarsImported: number;
    noCarsWithSuccessValuation: number;
    noCarsWithFailedValuation: number;
    noCarsUnidentified: number;
    noCarsInvalid: number;
    noCarsNotCalculated: number;
    totalFleetValue: number;
    averageFleetValue: number;
    currency: string;
  };
}

export interface IValuationListTableResponse extends IBasePaginatedResponse {
  data: IValuationList[];
}

export interface IValuationDetailResponse {
  createdAt: string;
  createdBy: string;
  number: number;
  name: string;
  status: string;
  previousValuationName: string;
  previousValuationDate: string;
  previousValuationNumber: string;
  valuationSummaryPerOwnershipList: {
    ownershipType: string;
    valuationSummaryPerCountries: {
      countryCode: string;
      noCarsDelivered: {
        previousValuation: number;
        currentValuation: number;
        evolution: number;
        evolutionPercentage: number;
      };
      noCarsNotCalculated: {
        previousValuation: number;
        currentValuation: number;
        evolution: number;
        evolutionPercentage: number;
      };
      noCarsCalculated: {
        previousValuation: number;
        currentValuation: number;
        evolution: number;
        evolutionPercentage: number;
      };
      totalFleetValue: {
        previousValuation: number;
        currentValuation: number;
        evolution: number;
        evolutionPercentage: number;
      };
      averageFleetValue: {
        previousValuation: number;
        currentValuation: number;
        evolution: number;
        evolutionPercentage: number;
      };
      currency: string;
    }[];
  }[];
}
