<script setup lang="ts">
import AppNavigation from "./components/Navigation/AppNavigation.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";

const route = useRoute();

const notAllowedPages = {
  Login: "Login",
  NotFound: "NotFound",
};

const noNavigationPages = computed(() => (route.name as string) in notAllowedPages);
</script>
<template>
  <div class="bg-grey-200 flex min-h-screen flex-col">
    <AppNavigation v-if="!noNavigationPages" />
    <router-view :class="{ 'pt-[4.5rem]': !noNavigationPages }" :key="$route.path" />
    <AppToastNotification />
  </div>
</template>
<style lang="scss" src="./assets/styles/main.scss"></style>
