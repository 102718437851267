import type { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { configureHeaders } from "@/configs/axios-configs";
import type {
  ICarGroups,
  ITranslatedConstant,
  IDepreciationAnalysisResponse,
  IHertzError,
  IMakeModelValidationResponse,
  IOfferByNumberResponse,
  IOfferListPayload,
  IOfferListResponse,
  IOffersByStatus,
  IOffersBySupplierType,
  IRejectSubOfferPayload,
  ISetSegmentPayload,
  ISimilarOffersInCountriesPayload,
  ISimilarOffersInCountriesResponse,
  ISimilarOffersInSegmentPayload,
  ISimilarOffersInCarClassResponse,
  IRequestCounterOfferPayload,
  ICountry,
  IUploadDocument,
  IAcceptOfferValidationPayload,
  IUploadDocumentResponse,
  IOfferValidationResponse,
  IDownloadFileResponse,
  IForwardOffer,
  ClosedOffers,
  ExpiredOffers,
  NewOffers,
  OngoingOffers,
  RejectedOffers,
  PendingOffers,
  ISellInOtherCountryPayload,
  IUserInfoResponse,
  IVinResponse,
  IVinCheckPayload,
  IMarkets,
  IAuctionsByStatus,
  IAuctionListPayload,
  IAuctionListResponse,
  IAuctionDetails,
  IAuctionCarsList,
  CarAuctionsPayload,
  IAuctionCarFullDetails,
  IEditAuctionCarDetailsPayload,
  IAuctionAnalysedCarsList,
  ICarAuctionAnalysis,
  IAuctionSimilarCarsInCarClass,
  IAuctionSimilarCarsInCarClassPayload,
  IResolutionReadyForAuctionCars,
  IUpdateBidStatus,
  IAnalysisPostAuction,
  IMarketMonitorCountry,
  IMarketMonitorVariantPayload,
  IMarketMonitorTrimPayload,
  IMarketMonitorVehicleOptionsPayload,
  IIdentifiable,
  IFleetSettingsPayload,
  IFleetSettingsDepreciationResponse,
  IFleetIntegrationPayload,
  IFleetDepreciationResponse,
  IFleetSettingsDownloadFile,
  IFleetSettingsTableResponse,
  IFleetPlanningTableResponse,
  IFleetSettingsUpdateDepTargetPayload,
  IFleetSettingsUpdateSettingsPayload,
  IWholesaleCarClassPayload,
  IWholesaleCarGroupsPayload,
  IWholesaleModelCodesPayload,
  IWholesaleMakesPayload,
  IWholesaleModelsPayload,
  IWholesaleBatchForAdminResponse,
  IWholesaleCarListByFilterPayload,
  IWholesaleCarListByFilterResponse,
  IWholesaleListCarsByFilterIdPayload,
  IWholesaleListCarsByFilterIdResponse,
  IGetAuctionBodyTypePayload,
  IGetAuctionBodyTypeResponse,
  IFleetOverallData,
  IFleetCountryPayload,
  IFleetCarClassesPayload,
  IFleetCarGroupsPayload,
  IFleetCarModelCodesPayload,
  IFleetMakesPayload,
  IFleetModelsPayload,
  IFleetListByFilterPayload,
  IFleetListByFilterResponse,
  IFleetAssetsByFilterSelection,
  IFleetAdminIndividualCarListPayload,
  IFleetAdminIndividualCarListResponse,
  IFleetAdminImportedData,
  IFleetTypeResponse,
  IFleetChartPayload,
  IFleetChartResponse,
  IFleetCurrentValuationsPayload,
  IFleetCurrentValuationsResponse,
  FleetAdminIndividualCar,
  IRecentMakesResponse,
  IRecentModelsResponse,
  IRecentGenerationsResponse,
  IRecentSubmodelsResponse,
  SubmodelPayload,
  IRecentVariantResponse,
  VariantsPayload,
  TrimsPayload,
  IRecentTrimsResponse,
  CharacteristicsPayload,
  ICharacteristicsResponse,
  IAutoFillFormPayload,
  IAutoFillFormResponse,
  IFleetAdminManualIdentificationPayload,
  IFleetAdminCountMultiEditCarsPayload,
  IFleetAdminCountMultiEditCarsResponse,
  IFleetAdminMultiEditSimilarCars,
  IFleedAdminMultiEditConfirmCarsPayload,
  IFleedAdminMultiEditConfirmCarsResponse,
  IFleetAdminUpdateLogPayload,
  IFleetAdminUpdateLogResponse,
  IFleetListMakesPayload,
  IFleetListModelsPayload,
  IFleetModelViewChartPayload,
  IFleetModelViewChartResponse,
  IFleetModelViewTablePayload,
  IFleetModelViewTableResponse,
  IFleetChartIndividualCarPayload,
  IFleetChartIndividualCarResponse,
  IFleetCurrentValuationsByCarPayload,
  IFleetCurrentValuationsByCarResponse,
  IAnomaliesTodayResponse,
  IAnomaliesPerMonthPayload,
  IAnomaliesPerMonthResponse,
  IAnomayCarsPayload,
  IAnomayCarsResponse,
  AnomaliesToFixResponse,
  IAnomaliesImportHistoryPayload,
  IAnomaliesImportHistoryResponse,
  IMarkAnomalyAsFixedPayload,
  IInvalidGroupedByModelCodesPayload,
  IInvalidGroupedByModelCodesResponse,
  ISalesPerformanceCountriesPayload,
  ISalesPerformanceCarClassesPayload,
  ISalesPerformanceCarGroupsPayload,
  ISalesPerformanceModelCodesPayload,
  ISalesPerformanceMakesPayload,
  ISalesPerformanceModelsPayload,
  ISalesPerformanceChartPayload,
  ISalesPerformanceChartResponse,
  ISalesPerformanceSummaryPayload,
  ISalesPerformanceSummaryResponse,
  ISalesPerformanceListTablePayload,
  ISalesPerformanceListTableResponse,
  ISalesPerformanceCarDetailsAndPricesResponse,
  ISalesPerformanceSalesChannelPayload,
  IUserRolesPayload,
  IAllUserRolesPayload,
  IUserRolesResponse,
  ICreateCustomRolePayload,
  ICreateCustomRoleResponse,
  IListAdminUsersPayload,
  IListAdminUsersResponse,
  ICountryExtra,
  ICreateNewAdminPayload,
  IGetAdminUserDetailsResponse,
  ILightAdminUserDetailsResponse,
  ISignInLogsPayload,
  ISignInLogsResponse,
  IForecastMakesPayload,
  IForecastModelsPayload,
  IForecastModelCodesPayload,
  IForecastChartPayload,
  IForecastChartResponse,
  IForecastListByCountryAndCarClassPayload,
  IForecastListByCountryAndCarClassResponse,
  IForecastListByMakeAndModelPayload,
  IForecastListByMakeAndModelResponse,
  IForecastChartByModelCodePayload,
  IForecastChartByModelCodeResponse,
  IForecastListByModelCodePayload,
  IForecastListByModelCodeResponse,
  IForecastChartForIndividualCarResponse,
  IValuationVerificationUpload,
  IValuationVerificationResponse,
  IValuationCreateUpload,
  ICreateValuationResponse,
  IValuationProcessingStateResponse,
  IValuationListPayload,
  IValuationListTableResponse,
  IValuationDetailResponse,
  IFleetAdminCarSuggestionsPayload,
  IFleetOwnershipType,
  IFleetAdminResetBatchPayload
} from "@/libs/types/AppTypes";

import { CarOfferStatus, SupplierType } from "@/libs/types/AppTypes";
import { userSessionService } from "@/main";

export class HttpClient {
  private baseURI = import.meta.env.VITE_API_SERVER;

  constructor(private axios: AxiosInstance) {
    configureHeaders(axios);

    // if the token has been refreshed just set the axios headers with the new access token
    userSessionService.tokenWasRefreshed$.subscribe(() => configureHeaders(axios));
  }

  getUserInfo(): Promise<IUserInfoResponse> {
    return this.axios
      .get(`${this.baseURI}/admin/user/current`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getOffersByStatus(): Promise<IOffersByStatus[]> {
    return this.axios
      .get(`${this.baseURI}/offer/status/occurrences`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getOffersByType(
    payload: Record<
      string,
      NewOffers | OngoingOffers | ExpiredOffers | ClosedOffers | RejectedOffers | PendingOffers
    >,
  ): Promise<IOffersBySupplierType> {
    const data = { statuses: Object.keys(payload) };

    return this.axios
      .post<IOffersByStatus[]>(`${this.baseURI}/offer/status/supplierType/occurrences`, data)
      .then((response) => {
        const output = {} as IOffersBySupplierType;

        response.data.forEach((type) => {
          // TODO use a switch case here
          if (type.value === SupplierType.BROKER) {
            output.broker = type.occurrence;
          } else if (type.value === SupplierType.DEALER) {
            output.dealer = type.occurrence;
          } else if (type.value === SupplierType.OEM) {
            output.oem = type.occurrence;
          }
        });

        return output;
      })
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getOfferListByStatusAndSupplierType(
    page: number = 1,
    itemsPerPage: number = 10,
    status:
      | NewOffers[]
      | OngoingOffers[]
      | ExpiredOffers[]
      | ClosedOffers[]
      | RejectedOffers[]
      | PendingOffers[],
    supplyerType: SupplierType,
  ): Promise<IOfferListResponse> {
    const payload: IOfferListPayload = {
      draw: 1,
      page: page,
      itemsPerPage: itemsPerPage,
      searchFields: [],
      search: "",
      query: {
        $and: [
          { status: { $in: status } },
          { supplierType: { $eq: supplyerType } },
          { outdated: { $ne: true } },
        ],
      },
      sort: [
        { property: "number", ascending: false },
        { property: "version", ascending: false },
      ],
    };

    return this.axios
      .post<IOfferListResponse>(`${this.baseURI}/offer/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getOfferByNumber(offerNumber: number): Promise<IOfferByNumberResponse> {
    return this.axios
      .get<IOfferByNumberResponse>(`${this.baseURI}/offer/number/${offerNumber}`)
      .then((response) => response.data)
      .catch((err) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  carOfferMakeAndModelValidation(carId: string): Promise<IMakeModelValidationResponse> {
    return this.axios
      .post<IMakeModelValidationResponse>(
        `${this.baseURI}/makeAndModelValidation/validateCarOffer/${carId}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  carOfferDepreciationAnalysis(carId: string): Promise<IDepreciationAnalysisResponse> {
    return this.axios
      .get(`${this.baseURI}/offer/car/${carId}/depreciationAnalysis`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  similarOffersInCarClass(
    page: number = 1,
    itemsPerPage: number = 10,
    destinations: string,
    carClass: string,
    estimatedDepreciationPeriod: number,
  ): Promise<ISimilarOffersInCarClassResponse> {
    const payload: ISimilarOffersInSegmentPayload = {
      draw: 1,
      page: page,
      itemsPerPage: itemsPerPage,
      searchFields: [],
      search: "",
      query: {
        $and: [
          { destinations: { $in: [destinations] } },
          { carClass: { $eq: carClass } },
          { estimatedDepreciationPeriod: { $eq: estimatedDepreciationPeriod } },
          { status: { $eq: CarOfferStatus.CONFIRMED } },
          { outdated: { $ne: true } },
        ],
      },
      sort: [{ property: "initiatedAt", ascending: false }],
    };

    return this.axios
      .post(`${this.baseURI}/offer/car/listInCarClass`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  similarOffersInOtherCountries(
    page: number = 1,
    itemsPerPage: number = 10,
    make: string,
    model: string,
    fuel: string,
    gearbox: string,
    enginePower: number,
    manufacturedYear: number,
    depreciationPeriod: number,
  ): Promise<ISimilarOffersInCountriesResponse> {
    const payload: ISimilarOffersInCountriesPayload = {
      draw: 1,
      page: page,
      itemsPerPage: itemsPerPage,
      searchFields: [],
      search: "",
      query: {
        $and: [
          { "car.make": { $eq: make } },
          { "car.model": { $eq: model } },
          { "car.fuelType": { $eq: fuel } },
          { "car.gearbox": { $eq: gearbox } },
          { "car.enginePower": { $eq: enginePower } },
          { "car.manufacturedYear": { $eq: manufacturedYear } },
          { estimatedDepreciationPeriod: { $eq: depreciationPeriod } },
          { status: { $eq: CarOfferStatus.CONFIRMED } },
          {
            destination: {
              $ne: "GB",
            },
          },
        ],
      },
      sort: [{ property: "initiatedAt", ascending: false }],
    };

    return this.axios
      .post(`${this.baseURI}/offer/car/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getDepreciationPeriods(): Promise<number[]> {
    return this.axios
      .get(`${this.baseURI}/offer/car/depreciationPeriods`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarGroups(): Promise<ICarGroups[]> {
    return this.axios
      .get(`${this.baseURI}/carGroup/all`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getOfferTypes(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/offer/types`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarClassCodes(country: string, offerType: string): Promise<string[]> {
    return this.axios
      .get(`${this.baseURI}/carClass/codes?country=${country}&offerType=${offerType}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  setCarClass(payload: ISetSegmentPayload): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/offer/car/carClass`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarSegments(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/offer/car/segments`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  rejectSubOffer(payload: IRejectSubOfferPayload): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/offer/car/reject`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getRegistrationStatus(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/offer/car/types`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getColors(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/offer/car/colors`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getDocumentTypes(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/documentFile/types`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  requestCounterOffer(payload: IRequestCounterOfferPayload): Promise<unknown> {
    return this.axios
      .post(`${this.baseURI}/offer/car/requestCounterOffer`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getHoldingPeriods(): Promise<number[]> {
    return this.axios
      .get(`${this.baseURI}/offer/car/holdingPeriods`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarOfferStatuses(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/offer/car/statuses`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getOfferStatuses(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/offer/statuses`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getDepreciationStatuses(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/offer/car/depreciationStatuses`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCountries(): Promise<ICountry[]> {
    return this.axios
      .get(`${this.baseURI}/country?scope=FLEET&fields=name&fields=alpha2Code`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAllCountries(): Promise<ICountry[]> {
    return this.axios
      .get(`${this.baseURI}/country?scope=ALL&fields=name&fields=alpha2Code`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getOfferValidationStatuses(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/offer/validation/statuses`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  resendOffer(offerNumber: number): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/offer/number/${offerNumber}/resend`, offerNumber)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  rejectOffer(offerNumber: number): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/offer/number/${offerNumber}/reject`, offerNumber)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  acceptOffer(offerNumber: number): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/offer/number/${offerNumber}/accept`, offerNumber)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  acceptSubOffer(offerNumber: string): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/offer/car/number/${offerNumber}/accept`, offerNumber)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  startOfferValidation(offerNumber: number): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/offer/number/${offerNumber}/startValidation`, offerNumber)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  refuseOfferValidation(offerNumber: number): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/offer/number/${offerNumber}/refuseValidation`, offerNumber)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  acceptOfferValidation(payload: IAcceptOfferValidationPayload): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/offer/number/${payload.offerNumber}/acceptValidation`, {
        documentId: payload.documentId,
      })
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadDocument(documentId: string): Promise<IDownloadFileResponse> {
    return this.axios
      .get(`${this.baseURI}/documentFile/base/${documentId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  uploadDocument(payload: IUploadDocument): Promise<IUploadDocumentResponse> {
    const headers = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("offerReference", payload.offerReference.toString());
    formData.append("carOfferReference", payload.carOfferReference);
    formData.append("type", payload.type);

    return this.axios
      .post(`${this.baseURI}/documentFile`, formData, headers)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getOfferValidation(offerNumber: number): Promise<IOfferValidationResponse> {
    return this.axios
      .get(`${this.baseURI}/offer/number/${offerNumber}/validation`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  forwardOffer(payload: IForwardOffer): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/offer/number/${payload.offerNumber}/forward`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAccessories(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/offer/car/accessories`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAccessories(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/fleetCar/accessories`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  sellInOtherCountry(payload: ISellInOtherCountryPayload): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/offer/car/${payload.subofferId}/setupSellingConditions`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  checkVinNumber(payload: IVinCheckPayload): Promise<IVinResponse> {
    return this.axios
      .post(`${this.baseURI}/vinCheck`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getMarkets(): Promise<IMarkets[]> {
    return this.axios
      .get(`${this.baseURI}/vinCheck/markets`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getActionStatuses(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/auction/statuses`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionsByStatus(): Promise<IAuctionsByStatus[]> {
    return this.axios
      .get(`${this.baseURI}/auction/status/occurrences`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionList(payload: IAuctionListPayload): Promise<IAuctionListResponse> {
    const query = {
      draw: 1,
      page: payload.page,
      itemsPerPage: payload.itemsPerPage || 10,
      searchFields: [],
      search: "",
      query: {
        $and: [
          {
            status: {
              $in: [...payload.status],
            },
          },
          ...((payload.filterQuery as Record<string, any>[]) || []),
        ],
      },
      sort: [
        {
          property: "number",
          ascending: false,
        },
      ],
    };

    return this.axios
      .post(`${this.baseURI}/auction/list`, query)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  deleteAuction(auctionNumber: number): Promise<unknown> {
    return this.axios
      .delete(`${this.baseURI}/auction/${auctionNumber}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionDetails(auctionNumber: number): Promise<IAuctionDetails> {
    return this.axios
      .get(`${this.baseURI}/auction/${auctionNumber}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionCars(payload: CarAuctionsPayload): Promise<IAuctionCarsList> {
    const query = {
      draw: 1,
      page: payload.page,
      itemsPerPage: payload.itemsPerPage,
      searchFields: [],
      search: "",
      query: {
        $and: [
          {
            status: {
              $ne: "DELETED",
            },
          },
        ],
      },
      sort: [payload.sort],
    };
    return this.axios
      .post(`${this.baseURI}/auction/${payload.auctionNumber}/car/list`, query)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionCarsNoCarClass(payload: CarAuctionsPayload): Promise<IAuctionCarsList> {
    const query = {
      draw: 1,
      page: payload.page,
      itemsPerPage: payload.itemsPerPage,
      searchFields: [],
      search: "",
      query: {
        $and: [
          {
            status: {
              $ne: "DELETED",
            },
            carClass: {
              $eq: "",
            },
          },
        ],
      },
      sort: [payload.sort],
    };
    return this.axios
      .post(`${this.baseURI}/auction/${payload.auctionNumber}/car/list`, query)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarCosmeticGrading(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/auction/car/cosmeticGradings`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarMechanicalGrading(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/auction/car/mechanicalGradings`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarServiceHistory(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/auction/car/serviceHistory`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarVatTypes(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/auction/car/vatTypes`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getVinStatus(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/auction/car/vinStatuses`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  deleteCarFromAuction(carAuctionId: string): Promise<unknown> {
    return this.axios
      .delete(`${this.baseURI}/auction/car/${carAuctionId}`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionCarFullDetails(carAuctionId: string): Promise<IAuctionCarFullDetails> {
    return this.axios
      .get(`${this.baseURI}/auction/car/${carAuctionId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  editAuctionCarDetails(payload: IEditAuctionCarDetailsPayload): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/auction/car/editDetails`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionAnalysedCars(payload: CarAuctionsPayload): Promise<IAuctionAnalysedCarsList> {
    const query = {
      draw: 1,
      page: payload.page,
      itemsPerPage: payload.itemsPerPage,
      searchFields: [],
      search: "",
      query: {
        $and: [
          {
            status: {
              $ne: "DELETED",
            },
          },
        ],
      },
      sort: [payload.sort],
    };
    return this.axios
      .post(`${this.baseURI}/auction/${payload.auctionNumber}/car/listAnalysis`, query)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionAnalysedCarsWithErr(payload: CarAuctionsPayload): Promise<IAuctionAnalysedCarsList> {
    const query = {
      draw: 1,
      page: payload.page,
      itemsPerPage: payload.itemsPerPage,
      searchFields: [],
      search: "",
      query: {
        $and: [
          {
            status: {
              $ne: "DELETED",
            },
            beforeAuctionAnalysisStatus: {
              $eq: "FAILED",
            },
          },
        ],
      },
      sort: [payload.sort],
    };
    return this.axios
      .post(`${this.baseURI}/auction/${payload.auctionNumber}/car/listAnalysis`, query)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionAnalysedCarsRowRequest(payload: CarAuctionsPayload): Promise<AxiosResponse<any, any>> {
    const query = {
      draw: 1,
      page: (payload.page ??= 1),
      itemsPerPage: (payload.itemsPerPage ??= 100),
      searchFields: [],
      search: "",
      query: {
        $and: [
          {
            status: {
              $ne: "DELETED",
            },
          },
        ],
      },
      sort: [(payload.sort ??= { property: "auctionItemNo", ascending: false })],
    };
    return this.axios
      .post(`${this.baseURI}/auction/${payload.auctionNumber}/car/listAnalysis`, query)
      .then((response) => response)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  startAuctionAnalysis(auctionNumber: number): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/auction/${auctionNumber}/startBeforeAuctionAnalysis`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarAuctionAnalysis(carAuctionId: string): Promise<ICarAuctionAnalysis> {
    return this.axios
      .get(`${this.baseURI}/auction/car/${carAuctionId}/beforeAuctionAnalysis`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionSimilarCarsInCarClass(
    payload: IAuctionSimilarCarsInCarClassPayload,
  ): Promise<IAuctionSimilarCarsInCarClass> {
    const query = {
      draw: 1,
      page: payload.page,
      itemsPerPage: payload.itemsPerPage,
      searchFields: [],
      search: "",
      query: {
        $and: [
          { destination: { $eq: payload.destination } },
          { carClass: { $eq: payload.carClass } },
          { "maxBidAnalysis.maxBidSelectedPeriod": { $eq: payload.maxBidSelectedPeriod } },
          { status: { $eq: "POST_ANALYSED" } },
          { bidStatus: { $eq: "WON" } },
        ],
      },
      sort: [{ property: "auctionDate", ascending: false }],
    };

    return this.axios
      .post(`${this.baseURI}/auction/car/listInCarClass`, query)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAuctionMaxBidPeriods(): Promise<number[]> {
    return this.axios
      .get(`${this.baseURI}/auction/car/maxBidPeriods`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAnalysisValidationStatuses(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/auction/car/analysisValidationStatuses`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  acceptCarAuction(carAuctionId: string): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/auction/car/${carAuctionId}/accept`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  refuseCarAuction(carAuctionId: string): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/auction/car/${carAuctionId}/refuse`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  refuseAllCarsAuction(auctionNumber: number): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/auction/${auctionNumber}/refuseAll`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  acceptAllCarsAuction(auctionNumber: number): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/auction/${auctionNumber}/acceptAll`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  completeAuction(auctionNumber: number): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/auction/${auctionNumber}/complete`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  finalizeAuction(auctionNumber: number): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/auction/${auctionNumber}/finalize`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  resolutionReadyForAuctionCars(
    payload: CarAuctionsPayload,
  ): Promise<IResolutionReadyForAuctionCars> {
    const query = {
      draw: 1,
      page: (payload.page ??= 1),
      itemsPerPage: (payload.itemsPerPage ??= 100),
      searchFields: [],
      search: "",
      query: {
        $and: [
          {
            status: {
              $eq: "ACCEPTED",
            },
          },
        ],
      },
      sort: [(payload.sort ??= { property: "auctionItemNo", ascending: false })],
    };

    return this.axios
      .post(`${this.baseURI}/auction/${payload.auctionNumber}/car/list`, query)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  carAuctionUpdateBidStatus(payload: IUpdateBidStatus): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/auction/car/updateBidStatus`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadVinReport(documentId: string): Promise<IDownloadFileResponse> {
    return this.axios
      .get(`${this.baseURI}/vinCheck/pdfReport/${documentId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  auctionDownloadExcelReport(auctionNumber: number): Promise<IDownloadFileResponse> {
    return this.axios
      .get(`${this.baseURI}/auction/${auctionNumber}/excelReport`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  startPostAuctionAnalysis(auctionNumber: number): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/auction/${auctionNumber}/startPostAnalysis`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  carListPostAuction(payload: CarAuctionsPayload): Promise<AxiosResponse<any, any>> {
    const query = {
      draw: 1,
      page: (payload.page ??= 1),
      itemsPerPage: (payload.itemsPerPage ??= 100),
      searchFields: [],
      search: "",
      query: {
        $and: [
          {
            bidStatus: {
              $eq: "WON",
            },
          },
        ],
      },
      sort: [(payload.sort ??= { property: "auctionItemNo", ascending: false })],
    };

    return this.axios
      .post(`${this.baseURI}/auction/${payload.auctionNumber}/car/listAcceptance`, query)
      .then((response) => response)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getDepreciationAuctionPostAnalysis(carAuctionId: string): Promise<IAnalysisPostAuction> {
    return this.axios
      .get(`${this.baseURI}/auction/car/${carAuctionId}/postAnalysis`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCarAuctionStatuses(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/auction/car/statuses`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  carAuctionVINDecode(carAuctionId: string): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/auction/car/${carAuctionId}/vinDecode`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  // getMarketMonitorNomenclators<T extends string[] | IIdentifiable[]>(
  //   payload: IMarketMonitorNomenclatorPayload,
  // ): Promise<T> {
  //   return this.axios
  //     .post(`${this.baseURI}/marketMonitor/nomenclatures`, payload)
  //     .then((response) => response.data)
  //     .catch((err: AxiosError<IHertzError>) => {
  //       return Promise.reject(err.response!.data.message);
  //     });
  // }

  getMarketMonitorMakes(): Promise<string[]> {
    return this.axios
      .get(`${this.baseURI}/geo/nomenclator/makes`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getMarketMonitorModels(make: string): Promise<string[]> {
    return this.axios
      .get(`${this.baseURI}/geo/nomenclator/models?make=${encodeURIComponent(make)}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getMarketMonitorVariant(payload: IMarketMonitorVariantPayload): Promise<string[]> {
    let query = `make=${encodeURIComponent(payload.make)}&model=${encodeURIComponent(payload.model)}`;

    if (payload.manufactureYearMin) query += `&manufactureYearMin=${payload.manufactureYearMin}`;
    if (payload.manufactureYearMax) query += `&manufactureYearMax=${payload.manufactureYearMax}`;

    return this.axios
      .get(`${this.baseURI}/geo/nomenclator/variants?${query}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getMarketMonitorTrim(payload: IMarketMonitorTrimPayload): Promise<string[]> {
    let query = `make=${encodeURIComponent(payload.make)}&model=${encodeURIComponent(payload.model)}`;

    if (payload.manufactureYearMin) query += `&manufactureYearMin=${payload.manufactureYearMin}`;
    if (payload.manufactureYearMax) query += `&manufactureYearMax=${payload.manufactureYearMax}`;

    if (Array.isArray(payload.variant) && payload.variant.length) {
      query += payload.variant.reduce(
        (prev, curr) => prev + `&variant=${encodeURIComponent(curr)}`,
        "",
      );
    }

    return this.axios
      .get(`${this.baseURI}/geo/nomenclator/trims?${query}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getMarketMonitorFuelType(payload: IMarketMonitorVehicleOptionsPayload): Promise<IIdentifiable[]> {
    let query = "";
    if (payload.make) query += `make=${encodeURIComponent(payload.make)}`;
    if (payload.model) query += `&model=${encodeURIComponent(payload.model)}`;

    if (Array.isArray(payload.variant) && payload.variant.length)
      query += payload.variant.reduce(
        (prev, curr) => prev + `&variant=${encodeURIComponent(curr)}`,
        "",
      );

    if (payload.trim) query += `&trim=${encodeURIComponent(payload.trim)}`;

    if (payload.manufactureYearMin) query += `&manufactureYearMin=${payload.manufactureYearMin}`;
    if (payload.manufactureYearMax) query += `&manufactureYearMax=${payload.manufactureYearMax}`;

    return this.axios
      .get(`${this.baseURI}/geo/nomenclator/fuelTypes?${query}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getMarketMonitorGearbox(payload: IMarketMonitorVehicleOptionsPayload): Promise<IIdentifiable[]> {
    let query = "";

    if (payload.make) query += `make=${encodeURIComponent(payload.make)}`;
    if (payload.model) query += `&model=${encodeURIComponent(payload.model)}`;
    //it can be an array of variants
    if (Array.isArray(payload.variant) && payload.variant.length)
      query += payload.variant.reduce(
        (prev, curr) => prev + `&variant=${encodeURIComponent(curr)}`,
        "",
      );

    if (payload.trim) query += `&trim=${encodeURIComponent(payload.trim)}`;
    if (payload.manufactureYearMin) query += `&manufactureYearMin=${payload.manufactureYearMin}`;
    if (payload.manufactureYearMax) query += `&manufactureYearMax=${payload.manufactureYearMax}`;

    return this.axios
      .get(`${this.baseURI}/geo/nomenclator/gearboxes?${query}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getMarketCountries(): Promise<IMarketMonitorCountry[]> {
    return this.axios
      .get(`${this.baseURI}/geo/nomenclator/countries`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetSettingsTableTypes(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/fleetSettings/tables`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetSettingsDepreciationTarget(
    payload: IFleetSettingsPayload,
  ): Promise<IFleetSettingsDepreciationResponse> {
    return this.axios
      .get(
        `${this.baseURI}/fleetSettings/depreciationTarget/country/${payload.country}/year/${payload.year}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetSettingsCommitted(payload: IFleetSettingsPayload): Promise<IFleetSettingsTableResponse> {
    return this.axios
      .get(
        `${this.baseURI}/fleetSettings/committed/country/${payload.country}/year/${payload.year}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetSettingsTOFPlanning(
    payload: IFleetSettingsPayload,
  ): Promise<IFleetSettingsTableResponse> {
    return this.axios
      .get(
        `${this.baseURI}/fleetSettings/tofPlanning/country/${payload.country}/year/${payload.year}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetSettingsPlannedDefleet(
    payload: IFleetSettingsPayload,
  ): Promise<IFleetSettingsTableResponse> {
    return this.axios
      .get(
        `${this.baseURI}/fleetSettings/plannedDeFleet/country/${payload.country}/year/${payload.year}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetDepreciationTableTypes(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/fleetDepreciation/tables`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetDepreciationByTableType(
    payload: IFleetIntegrationPayload,
  ): Promise<IFleetDepreciationResponse> {
    return this.axios
      .get(
        `${this.baseURI}/fleetDepreciation/country/${payload.country}/year/${payload.year}/table/${payload.tableType}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetPlanningTableTypes(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/fleetPlanning/tables`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetPlanningPlannedDefleet(
    payload: IFleetIntegrationPayload,
  ): Promise<IFleetPlanningTableResponse> {
    return this.axios
      .get(
        `${this.baseURI}/fleetPlanning/country/${payload.country}/year/${payload.year}/table/${payload.tableType}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadFleetDepreciationFile(payload: IFleetIntegrationPayload): Promise<IDownloadFileResponse> {
    return this.axios
      .get(
        `${this.baseURI}/fleetDepreciation/country/${payload.country}/year/${payload.year}/table/${payload.tableType}/download`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadFleetSettingsFile(payload: IFleetSettingsDownloadFile): Promise<IDownloadFileResponse> {
    return this.axios
      .get(
        `${this.baseURI}/fleetSettings/country/${payload.country}/year/${payload.year}/table/${payload.tableType}/download`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadFleetPlanningFile(payload: IFleetIntegrationPayload): Promise<IDownloadFileResponse> {
    return this.axios
      .get(
        `${this.baseURI}/fleetPlanning/country/${payload.country}/year/${payload.year}/table/${payload.tableType}/download`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetSettingsUpdateDepreciationTarget(payload: {
    data: IFleetSettingsUpdateDepTargetPayload[];
    depreciationTargetId: string;
  }): Promise<unknown> {
    return this.axios
      .put(
        `${this.baseURI}/fleetSettings/depreciationTarget/${payload.depreciationTargetId}`,
        payload.data,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetSettingsUpdatePlannedDefleet(payload: {
    data: IFleetSettingsUpdateSettingsPayload[];
    plannedDeFleetId: string;
  }): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/fleetSettings/plannedDeFleet/${payload.plannedDeFleetId}`, payload.data)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetSettingsUpdateComitted(payload: {
    data: IFleetSettingsUpdateSettingsPayload[];
    committedId: string;
  }): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/fleetSettings/committed/${payload.committedId}`, payload.data)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetSettingsUpdateTOFPlanning(payload: {
    data: IFleetSettingsUpdateSettingsPayload[];
    tofPlanningId: string;
  }): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/fleetSettings/tofPlanning/${payload.tofPlanningId}`, payload.data)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  wholesaleListCountries(): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarPriceFilter/listCountries`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  wholesaleGetCarClasses(payload: IWholesaleCarClassPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarPriceFilter/listCarClasses`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  wholesaleGetCarGroups(payload: IWholesaleCarGroupsPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarPriceFilter/listCarGroups`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  wholesaleGetModelCodes(payload: IWholesaleModelCodesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarPriceFilter/listModelCodes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  wholesaleGetMakes(payload: IWholesaleMakesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarPriceFilter/listMakes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  wholesaleGetModels(payload: IWholesaleModelsPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarPriceFilter/listModels`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  wholesaleGetLastThreeBatches(): Promise<{ data: IWholesaleBatchForAdminResponse[] }> {
    const payload = {
      draw: 1,
      page: 1,
      itemsPerPage: 3,
      searchFields: [],
      search: "",
      query: { $and: [{ status: { $eq: "INITIATED" } }] },
      sort: [{ property: "createdAt", ascending: false }],
    };

    return this.axios
      .post(`${this.baseURI}/wholesaleBatch/listForAdmin`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  wholesaleGetCarsByFilterIdentification(
    payload: IWholesaleCarListByFilterPayload,
  ): Promise<IWholesaleCarListByFilterResponse[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarPriceFilter/listCarsByFilterIdentification`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  wholesaleGetListCarsDetailsByFilterIdentification(
    payload: IWholesaleListCarsByFilterIdPayload,
  ): Promise<IWholesaleListCarsByFilterIdResponse[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarPriceFilter/listCarsDetailsByFilterIdentification`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  auctionResetAnalysis(auctionNumber: number): Promise<void> {
    return this.axios
      .put(`${this.baseURI}/auction/${auctionNumber}/resetBeforeAuctionAnalysis`, auctionNumber)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  auctionGetBodyTypes(payload: IGetAuctionBodyTypePayload): Promise<IGetAuctionBodyTypeResponse[]> {
    let query = `make=${encodeURIComponent(payload.make)}&model=${encodeURIComponent(payload.model)}`;

    if (payload.gearbox) query += `&gearbox=${encodeURIComponent(payload.gearbox)}`;
    if (payload.fuelType) query += `&fuelType=${encodeURIComponent(payload.fuelType)}`;
    if (payload.enginePower) query += `&enginePower=${encodeURIComponent(payload.enginePower)}`;

    return this.axios
      .get(`${this.baseURI}/masterCarData/recentBodyTypes?${query}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAssetsOverall(): Promise<IFleetOverallData> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/countValidAllFleet`, {})
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAdminOwnershipType(payload: IFleetOwnershipType): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetFilter/listOwnershipTypes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCountries(payload: IFleetCountryPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetFilter/listCountries`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarClasses(payload: IFleetCarClassesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetFilter/listCarClasses`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarGroups(payload: IFleetCarGroupsPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetFilter/listCarGroups`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetModelCodes(payload: IFleetCarModelCodesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetFilter/listModelCodes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetMakes(payload: IFleetMakesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetFilter/listMakes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetModels(payload: IFleetModelsPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetFilter/listModels`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetListByFilterSelection(
    payload: IFleetListByFilterPayload,
  ): Promise<IFleetListByFilterResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/listByFilterSelection`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAssetsByFilterSelection(
    payload: IFleetAssetsByFilterSelection,
  ): Promise<IFleetOverallData> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/countValid`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAdminIndividualCarList(
    payload: IFleetAdminIndividualCarListPayload,
  ): Promise<IFleetAdminIndividualCarListResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAdminImportedData(carId: string): Promise<IFleetAdminImportedData> {
    return this.axios
      .get(`${this.baseURI}/fleetCar/importData/${carId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetTypes(): Promise<IFleetTypeResponse[]> {
    return this.axios
      .get(`${this.baseURI}/fleetCarValuation/types`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getPeriods(): Promise<number[]> {
    return this.axios
      .get(`${this.baseURI}/fleetCarValuation/periods`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarValuationChart(payload: IFleetChartPayload): Promise<IFleetChartResponse[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/chart`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarValuationCurrentValuations(
    payload: IFleetCurrentValuationsPayload,
  ): Promise<IFleetCurrentValuationsResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/listCurrentValuations`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarValuationCurrentValuationsByCars(
    payload: IFleetCurrentValuationsByCarPayload,
  ): Promise<IFleetCurrentValuationsByCarResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/listCurrentValuationsByCars`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAdminCarSuggestions(carId: string): Promise<FleetAdminIndividualCar[]> {
    return this.axios
      .get(`${this.baseURI}/fleetCar/similarCars/${carId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAdminCarSuggestionsByOwnershipType(payload: IFleetAdminCarSuggestionsPayload): Promise<FleetAdminIndividualCar[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/similarCarsSelection/byOwnershipType`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAdminResetBatch(payload: IFleetAdminResetBatchPayload) {
    return this.axios
    .post(`${this.baseURI}/fleetCar/resetList`, payload)
    .then((response) => response.data)
    .catch((err: AxiosError<IHertzError>) => {
      return Promise.reject(err.response!.data.message);
    });
  }

  getFleetAdminResetCar(carId: string) {
    return this.axios
    .put(`${this.baseURI}/fleetCar/resetValidCar/${carId}`)
    .then((response) => response.data)
    .catch((err: AxiosError<IHertzError>) => {
      return Promise.reject(err.response!.data.message);
    });
  }

  getFleetAdminRecheckVin(vin: string): Promise<any> {
    return this.axios
    .put(`${this.baseURI}/fleetCar/recheckVin/${vin}`)
    .then((response) => response.data)
    .catch((err: AxiosError<IHertzError>) => {
      return Promise.reject(err.response!.data.message);
    });
  }

  getFleetAdminVINImportedData(carId: string): Promise<IVinResponse> {
    return this.axios
      .get(`${this.baseURI}/fleetImportVinCheck/${carId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAdminOwnershipTypeLabels(): Promise<IFleetTypeResponse[]> {
    return this.axios
      .get(`${this.baseURI}/fleetCar/ownershipTypes`)
      .then((response) => response.data)
      .catch((err) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarByVinNumber(vinNumber: string): Promise<IFleetListByFilterResponse> {
    return this.axios
      .get(`${this.baseURI}/fleetCar/listByVinNumber/${vinNumber}`)
      .then((response) => response.data)
      .catch((err) => {
        return Promise.reject(err.response?.data.message || err.message);
      });
  }

  getRecentMakes(): Promise<IRecentMakesResponse[]> {
    return this.axios
      .get(`${this.baseURI}/masterCarData/recentMakes`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getRecentModels(make: string): Promise<IRecentModelsResponse[]> {
    make = encodeURIComponent(make);

    return this.axios
      .get(`${this.baseURI}/masterCarData/recentModels?make=${make}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getRecentGeneration(make: string, model: string): Promise<IRecentGenerationsResponse[]> {
    make = encodeURIComponent(make);
    model = encodeURIComponent(model);

    return this.axios
      .get(`${this.baseURI}/masterCarData/recentGenerations?make=${make}&model=${model}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getRecentSubmodels(payload: SubmodelPayload): Promise<IRecentSubmodelsResponse[]> {
    let { make, model, generation } = payload;

    make = encodeURIComponent(make);
    model = encodeURIComponent(model);
    generation = encodeURIComponent(generation);

    return this.axios
      .get(
        `${this.baseURI}/masterCarData/recentSubModels?make=${make}&model=${model}&generation=${generation}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getRecentVariants(payload: VariantsPayload): Promise<IRecentVariantResponse[]> {
    let { make, model, generation, submodel } = payload;

    make = encodeURIComponent(make);
    model = encodeURIComponent(model);
    generation = encodeURIComponent(generation);
    submodel = encodeURIComponent(submodel);

    return this.axios
      .get(
        `${this.baseURI}/masterCarData/recentVariantsGeneric?make=${make}&model=${model}&generation=${generation}&subModel=${submodel}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getRecentTrims(payload: TrimsPayload): Promise<IRecentTrimsResponse[]> {
    let { make, model, generation, submodel, variant } = payload;

    make = encodeURIComponent(make);
    model = encodeURIComponent(model);
    generation = encodeURIComponent(generation);
    submodel = encodeURIComponent(submodel);
    variant = encodeURIComponent(variant);

    return this.axios
      .get(
        `${this.baseURI}/masterCarData/recentTrimsGeneric?make=${make}&model=${model}&generation=${generation}&subModel=${submodel}&variant=${variant}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCharacteristics(payload: CharacteristicsPayload): Promise<ICharacteristicsResponse[]> {
    let { make, model, generation, submodel, variant, trim } = payload;

    make = encodeURIComponent(make);
    model = encodeURIComponent(model);
    generation = encodeURIComponent(generation);
    submodel = encodeURIComponent(submodel);
    variant = encodeURIComponent(variant);
    trim = encodeURIComponent(trim);

    return this.axios
      .get(
        `${this.baseURI}/masterCarData/characteristics?make=${make}&model=${model}&generation=${generation}&subModel=${submodel}&variant=${variant}&trim=${trim}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  carIdentificationAutoFillForm(payload: IAutoFillFormPayload): Promise<IAutoFillFormResponse> {
    let { make, model, generation, submodel, variant, fuel, gearbox, enginePower, trim } = payload;

    make = encodeURIComponent(make);
    model = encodeURIComponent(model);
    generation = encodeURIComponent(generation);
    submodel = encodeURIComponent(submodel);
    variant = encodeURIComponent(variant);
    fuel = encodeURIComponent(fuel);
    gearbox = encodeURIComponent(gearbox);
    enginePower = encodeURIComponent(enginePower);
    trim = encodeURIComponent(trim);

    return this.axios
      .get(
        `${this.baseURI}/masterCarData/autoFillForm?make=${make}&model=${model}&generation=${generation}&subModel=${submodel}&variant=${variant}&fuelType=${fuel}&gearbox=${gearbox}&enginePower=${enginePower}&trim=${trim}`,
      )
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetAdminManualIdentification(
    payload: IFleetAdminManualIdentificationPayload,
  ): Promise<unknown> {
    return this.axios
      .put(`${this.baseURI}/fleetCar/manualIdentification/${payload.carId}`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetAdminCountMultiEditCars(
    payload: IFleetAdminCountMultiEditCarsPayload,
  ): Promise<IFleetAdminCountMultiEditCarsResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/countMultiEditCars`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetAdminMultiEditSimilarCars(
    payload: IFleetAdminMultiEditSimilarCars,
  ): Promise<FleetAdminIndividualCar[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/similarCars`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetAdminConfirmMultiEditCars(
    payload: IFleedAdminMultiEditConfirmCarsPayload,
  ): Promise<IFleedAdminMultiEditConfirmCarsResponse[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/confirmMultiEditCars`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getMultiEditFields(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/fleetCar/multiEditFields`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetAdminConfirmMultiEitIdentification(
    payload: IFleedAdminMultiEditConfirmCarsPayload,
  ): Promise<{ editedCarsCount: number }> {
    return this.axios
      .put(`${this.baseURI}/fleetCar/multiManualIdentification`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetAdminVINCheck(carId: string): Promise<IVinResponse> {
    return this.axios
      .get(`${this.baseURI}/fleetImportVinCheck/${carId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetAdminDownloadVINReport(responseId: string): Promise<IDownloadFileResponse> {
    return this.axios
      .get(`${this.baseURI}/fleetImportVinCheck/pdfReport/${responseId}?originalLanguage=false`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  fleetAdminGetUpdateLog(
    payload: IFleetAdminUpdateLogPayload,
  ): Promise<IFleetAdminUpdateLogResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetImportAnalysis/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarValuationListMakes(payload: IFleetListMakesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/listMakes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarValuationListModels(payload: IFleetListModelsPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/listModels`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarValuationChartByModelCode(
    payload: IFleetModelViewChartPayload,
  ): Promise<IFleetModelViewChartResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/chartByModelCode`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarValuationTableByModelCode(
    payload: IFleetModelViewTablePayload,
  ): Promise<IFleetModelViewTableResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetCarValuationChartForIndividualCar(
    payload: IFleetChartIndividualCarPayload,
  ): Promise<IFleetChartIndividualCarResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/chartForIndividualCar`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getFleetAnomaliesToday(): Promise<IAnomaliesTodayResponse> {
    return this.axios
      .get(`${this.baseURI}/fleetAnomaly/anomaliesToday`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAnomaliesPerMonth(payload: IAnomaliesPerMonthPayload): Promise<IAnomaliesPerMonthResponse[]> {
    const queryParams = `country/${payload.country}/year/${payload.year}/month/${payload.month}`;

    return this.axios
      .get(`${this.baseURI}/fleetAnomaly/totalsPerMonth/${queryParams}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAnomalyCars(payload: IAnomayCarsPayload): Promise<IAnomayCarsResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAnomalyTypes(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/fleetAnomaly/anomalyTypes`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAnomaliesToFix(fleetId: string): Promise<AnomaliesToFixResponse[]> {
    return this.axios
      .get(`${this.baseURI}/fleetAnomaly/anomaliesToFix/fleetCarId/${fleetId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAnomaliesImportHistory(
    payload: IAnomaliesImportHistoryPayload,
  ): Promise<IAnomaliesImportHistoryResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/importLog/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  markAnomalyAsFixed(payload: IMarkAnomalyAsFixedPayload): Promise<unknown> {
    return this.axios
      .post(`${this.baseURI}/fleetAnomaly/fixAnomaly`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getInvalidGroupedByModelCodes(
    payload: IInvalidGroupedByModelCodesPayload,
  ): Promise<IInvalidGroupedByModelCodesResponse[]> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/invalidGroupedByModelCodes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadFleetAdminIndividualList(
    payload: IFleetAdminIndividualCarListPayload,
  ): Promise<IDownloadFileResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCar/excelReport`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadFleetCarValuation(
    payload: IFleetCurrentValuationsPayload,
  ): Promise<IDownloadFileResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/excelReport`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadFleetCarValuationByCar(
    payload: IFleetCurrentValuationsByCarPayload,
  ): Promise<IDownloadFileResponse> {
    return this.axios
      .post(`${this.baseURI}/fleetCarValuation/excelReport`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceCountries(payload: ISalesPerformanceCountriesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/listCountries`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceCarClasses(payload: ISalesPerformanceCarClassesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/listCarClasses`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceCarGroups(payload: ISalesPerformanceCarGroupsPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/listCarGroups`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceModelCodes(payload: ISalesPerformanceModelCodesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/listModelCodes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceMakes(payload: ISalesPerformanceMakesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/listMakes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceModels(payload: ISalesPerformanceModelsPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/listModels`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceSalesChannel(
    payload: ISalesPerformanceSalesChannelPayload,
  ): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/listSaleChannels`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceChart(
    payload: ISalesPerformanceChartPayload,
  ): Promise<ISalesPerformanceChartResponse> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/chart`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceSummary(
    payload: ISalesPerformanceSummaryPayload,
  ): Promise<ISalesPerformanceSummaryResponse> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/summary`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceListTable(
    payload: ISalesPerformanceListTablePayload,
  ): Promise<ISalesPerformanceListTableResponse> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesPerformanceCarDetailsAndPrices(
    carId: string,
  ): Promise<ISalesPerformanceCarDetailsAndPricesResponse> {
    return this.axios
      .get(`${this.baseURI}/salesPerformance/carDetailsAndPrices/${carId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadSalesPerformanceList(
    payload: ISalesPerformanceListTablePayload,
  ): Promise<IDownloadFileResponse> {
    return this.axios
      .post(`${this.baseURI}/salesPerformance/excelReport`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getGetUserRoles(payload: IUserRolesPayload): Promise<IUserRolesResponse> {
    return this.axios
      .post(`${this.baseURI}/appRole/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAllUserRoles(payload: IAllUserRolesPayload): Promise<IUserRolesResponse> {
    return this.axios
      .post(`${this.baseURI}/appRole/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  createCustomRole(payload: ICreateCustomRolePayload): Promise<ICreateCustomRoleResponse> {
    return this.axios
      .post(`${this.baseURI}/appRole`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  editCustomRole(
    payload: ICreateCustomRolePayload,
    roleId: string,
  ): Promise<ICreateCustomRoleResponse> {
    return this.axios
      .put(`${this.baseURI}/appRole/${roleId}`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCustomRole(roleId: string): Promise<ICreateCustomRoleResponse> {
    return this.axios
      .get(`${this.baseURI}/appRole/${roleId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  deleteCustomRole(roleId: string) {
    return this.axios
      .delete(`${this.baseURI}/appRole/${roleId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  listAdminUsers(payload: IListAdminUsersPayload): Promise<IListAdminUsersResponse> {
    return this.axios
      .post(`${this.baseURI}/admin/user/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  listAdminUsersLight(payload: IListAdminUsersPayload): Promise<ILightAdminUserDetailsResponse> {
    return this.axios
      .post(`${this.baseURI}/admin/user/listAdmins`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getCountriesExtra(): Promise<ICountryExtra[]> {
    return this.axios
      .get(`${this.baseURI}/country?scope=FLEET&fields=name,alpha2Code,alpha3Code`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  createNewAdmin(payload: ICreateNewAdminPayload) {
    return this.axios
      .post(`${this.baseURI}/admin/user`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAdminUserDetails(userId: string): Promise<IGetAdminUserDetailsResponse> {
    return this.axios
      .get(`${this.baseURI}/admin/user/${userId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  updateAdminUserDetails(payload: ICreateNewAdminPayload, userId: string) {
    return this.axios
      .put(`${this.baseURI}/admin/user/${userId}`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  deleteAdminUser(userId: string) {
    return this.axios
      .delete(`${this.baseURI}/admin/user/${userId}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }
  getAllStatuses() {
    return this.axios
      .get(`${this.baseURI}/admin/user/statuses`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getAllPermissions() {
    return this.axios
      .get(`${this.baseURI}/appRole/adminPermissions`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSignInLogList(payload: ISignInLogsPayload): Promise<ISignInLogsResponse> {
    return this.axios
      .post(`${this.baseURI}/signInLog/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesChannels(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/fleetCar/saleChannels`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getSalesTypes(): Promise<ITranslatedConstant[]> {
    return this.axios
      .get(`${this.baseURI}/fleetCar/saleTypes`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getForecastingListMakes(payload: IForecastMakesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/forecasting/listMakes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getForecastingListModels(payload: IForecastModelsPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/forecasting/listModels`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getForecastingListModelCodes(payload: IForecastModelCodesPayload): Promise<string[]> {
    return this.axios
      .post(`${this.baseURI}/forecasting/listModelCodes`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getForecastingChart(payload: IForecastChartPayload): Promise<IForecastChartResponse[]> {
    return this.axios
      .post(`${this.baseURI}/forecasting/chart`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getForecastingListByCountryAndCarClass(
    payload: IForecastListByCountryAndCarClassPayload,
  ): Promise<IForecastListByCountryAndCarClassResponse> {
    return this.axios
      .post(`${this.baseURI}/forecasting/listForecastingByCountryAndCarClass`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getForecastingListByMakeAndModel(
    payload: IForecastListByMakeAndModelPayload,
  ): Promise<IForecastListByMakeAndModelResponse> {
    return this.axios
      .post(`${this.baseURI}/forecasting/listForecastingByMakeAndModel`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getForecastingChartByModelCode(
    payload: IForecastChartByModelCodePayload,
  ): Promise<IForecastChartByModelCodeResponse> {
    return this.axios
      .post(`${this.baseURI}/forecasting/chartByModelCode`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getForecastingTableByModelCode(
    payload: IForecastListByModelCodePayload,
  ): Promise<IForecastListByModelCodeResponse> {
    return this.axios
      .post(`${this.baseURI}/forecasting/listForecastingByModelCode`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  getForecastingChartForIndividualCar(id: string): Promise<IForecastChartForIndividualCarResponse> {
    return this.axios
      .get(`${this.baseURI}/forecasting/chartForIndividualCar/${id}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadForecasting(payload: IFleetCurrentValuationsPayload): Promise<IDownloadFileResponse> {
    return this.axios
      .post(`${this.baseURI}/forecasting/excelReport`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadForecastingByModelCodel(
    payload: IForecastListByModelCodePayload,
  ): Promise<IDownloadFileResponse> {
    return this.axios
      .post(`${this.baseURI}/forecasting/excelReportByModelCode`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadValuationTemplate(): Promise<IDownloadFileResponse> {
    return this.axios
      .get(`${this.baseURI}/valuation/template`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  checkValuation(payload: IValuationVerificationUpload): Promise<IValuationVerificationResponse> {
    const headers = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    return this.axios
      .post(`${this.baseURI}/valuation/checkInputValuationFile`, payload, headers)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  createValuation(payload: IValuationCreateUpload): Promise<ICreateValuationResponse> {
    const headers = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    return this.axios
      .post(`${this.baseURI}/valuation/create`, payload, headers)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  valuationProcessingState(id: string | string[]): Promise<IValuationProcessingStateResponse> {
    return this.axios
      .get(`${this.baseURI}/valuation/${id}/processingState`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  listValuations(payload: IValuationListPayload): Promise<IValuationListTableResponse> {
    return this.axios
      .post(`${this.baseURI}/valuation/list`, payload)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  valuationDetail(id: string | string[]): Promise<IValuationDetailResponse> {
    return this.axios
      .get(`${this.baseURI}/valuation/${id}`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }

  downloadValuationReport(id: number | null): Promise<IDownloadFileResponse> {
    return this.axios
      .get(`${this.baseURI}/valuation/${id}/valuationReport`)
      .then((response) => response.data)
      .catch((err: AxiosError<IHertzError>) => {
        return Promise.reject(err.response!.data.message);
      });
  }
}
