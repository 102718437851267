<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import AppCustomCheckbox from "./AppCustomCheckbox.vue";

interface Props {
  options: any[];
  id: string;
  placeholder?: string;
  optionValue?: string | number;
  optionLabel?: string | number;
  disabled?: boolean;
  errorMessage?: string;
  invalid?: boolean;
  resetModels?: boolean;
  savedFilter?: any;
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  placeholder: "Placeholder",
  disabled: false,
  errorMessage: "Error",
  optionLabel: "",
  optionValue: "",
  savedFilter: [],
});

const emit = defineEmits<{ (e: "select", payload: unknown[]): void }>();

const wrapper = ref<HTMLElement>();

const showOptions = ref(false);

let models = ref<Record<string, boolean>>({});

const checkedItems = ref<any[]>([]);

const toggleAll = ref(false);

onMounted(() => {
  if (props.options && !props.savedFilter.length) {
    createModels();
  } else {
    checkedItems.value = props.savedFilter;
    completeModels();
  }
});

onBeforeUnmount(() => document.removeEventListener("click", handleClickOutside));

watch(showOptions, () => {
  !showOptions.value ? document.removeEventListener("click", handleClickOutside) : "";
});

watch(
  () => props.options,
  () => {
    //clear models if the data prop changes then create new ones
    toggleAll.value = false;
    models.value = {};
    createModels();
  },
);

watch(
  models,
  () => {
    checkedItems.value.length = 0;

    if (optionsAreNested.value) {
      props.options.forEach((item, idx) => {
        if (models.value[`${props.optionValue}${idx}`]) {
          checkedItems.value.push(props.options[idx]);
        }
      });
    } else {
      props.options.forEach((item, idx) => {
        if (models.value[`checkbox${idx}`]) checkedItems.value.push(props.options[idx]);
      });
    }

    emit("select", checkedItems.value);
  },
  { deep: true },
);

watch(
  () => props.resetModels,
  () => {
    models.value = {};
    createModels();
    checkedItems.value = [];
  },
);

watch(toggleAll, (checked: boolean) => {
  for (const key in models.value) {
    models.value[key] = checked;
  }
});

const completeModels = () => {
  if (optionsAreNested.value) {
    props.options.forEach((item, idx) => {
      if (checkedItems.value.includes(item)) models.value[`${props.optionValue}${idx}`] = true;
    });
  } else {
    props.options.forEach((item, idx) => {
      if (checkedItems.value.includes(item)) models.value[`checkbox${idx}`] = true;
    });
  }
};

const optionsAreNested = computed(() => props.optionLabel !== "" && props.optionValue !== "");

const canToggleAll = computed(() => props.options.length > 1);

const toggleAllLabel = computed(() => (toggleAll.value ? "Unselect all" : "Select all"));

const createModels = () => {
  if (optionsAreNested.value) {
    props.options.forEach((item, idx) => {
      models.value[`${props.optionValue}${idx}`] = false;
    });
    return;
  }

  props.options.forEach((item, idx) => {
    models.value[`checkbox${idx}`] = false;
  });
};

const invalidCss = computed(() => {
  return props.invalid ? "border-red" : "border-grey-400";
});

const inputValue = computed(() => {
  if (optionsAreNested.value) {
    if (checkedItems.value.length === 0) return null;
    let output = "";
    if (checkedItems.value.length >= 2) {
      output = `${checkedItems.value[0][props.optionLabel]}, ${checkedItems.value[1][props.optionLabel]}`;
      if (checkedItems.value.length > 2) {
        output += ` and ${checkedItems.value.length - 2} more`;
      }
    } else if (checkedItems.value.length === 1) {
      output = `${checkedItems.value[0][props.optionLabel]}`;
    }
    return output;
  } else {
    if (checkedItems.value.length === 0) return null;
    let output = "";
    if (checkedItems.value.length >= 2) {
      output = `${checkedItems.value[0]}, ${checkedItems.value[1]}`;
      if (checkedItems.value.length > 2) {
        output += ` and ${checkedItems.value.length - 2} more`;
      }
    } else if (checkedItems.value.length === 1) {
      output = `${checkedItems.value[0]}`;
    }
    return output;
  }
});

const toggle = () => {
  showOptions.value = !showOptions.value;

  if (showOptions.value) {
    document.addEventListener("click", handleClickOutside);
  }
};

const handleClickOutside = (evt: MouseEvent) => {
  const target = evt.target as HTMLElement;

  !wrapper.value?.contains(target) ? (showOptions.value = false) : "";
};
</script>
<template>
  <div class="relative" ref="wrapper">
    <input
      :id="id"
      :data-cy="id"
      type="text"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="inputValue"
      readonly
      class="w-full border bg-white pr-[35px] pl-2 pt-2 pb-2 lg:pl-3 lg:pt-3 lg:pb-3 text-xs lg:text-sm truncate focus:outline-none hover:cursor-pointer placeholder:text-black placeholder:disabled:text-grey-500 disabled:text-grey-500 disabled:bg-grey-200 disabled:cursor-not-allowed"
      :class="[invalidCss]"
      @click.stop="toggle"
    />
    <!-- carret -->
    <div class="text-xs lg:text-sm absolute top-[2px] right-0 p-2 lg:p-3">
      <font-awesome-icon class="w-[.7rem]" v-show="!showOptions" :icon="['fas', 'angle-down']" />
      <font-awesome-icon class="w-[.7rem]" v-show="showOptions" :icon="['fas', 'angle-up']" />
    </div>
    <!-- options -->
    <ul
      v-show="showOptions"
      class="absolute z-[1] bg-white w-full shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-52 overflow-auto custom-scrollbar"
    >
      <li v-if="canToggleAll" class="text-xs lg:text-sm p-3 cursor-pointer hover:bg-grey-100">
        <AppCustomCheckbox :id="`dropdown-item-${id}-select-all`" class="!my-0" v-model="toggleAll">
          <template #default>
            <div class="ml-2">{{ toggleAllLabel }}</div>
          </template>
        </AppCustomCheckbox>
      </li>

      <template v-if="optionsAreNested">
        <li
          v-for="(item, idx) in options"
          :key="idx"
          class="text-xs lg:text-sm p-3 cursor-pointer hover:bg-grey-100"
        >
          <AppCustomCheckbox
            :id="`dropdown-item-${id}-${idx}`"
            class="!my-0"
            v-model="models[`${optionValue}${idx}`]"
          >
            <template #default>
              <div class="ml-2">{{ item[optionLabel] }}</div>
            </template>
          </AppCustomCheckbox>
        </li>
      </template>
      <template v-else>
        <li
          v-for="(item, idx) in options"
          :key="idx"
          class="text-xs lg:text-sm p-3 cursor-pointer hover:bg-grey-100"
        >
          <AppCustomCheckbox
            :id="`dropdown-item-${id}-${idx}`"
            class="!my-0"
            v-model="models[`checkbox${idx}`]"
          >
            <template #default>
              <div class="ml-2">{{ item }}</div>
            </template>
          </AppCustomCheckbox>
        </li>
      </template>
    </ul>
    <!-- error message -->
    <div v-show="invalid" class="text-red text-xs lg:text-sm text-left absolute">
      {{ errorMessage }}
    </div>
  </div>
</template>
