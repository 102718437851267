import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import {
  faBell,
  faArrowDownToLine,
  faCircleExclamation,
  faArrowUpToLine,
  faFilePlus,
  faBadgeCheck,
  faCircleInfo,
  faXmark,
  faCircleCheck,
  faCircleXmark,
  faArrowRight,
  faEllipsis,
  faLayerGroup,
  faGear,
  faSpinnerThird,
  faCircleArrowUpRight,
  faCircleArrowDownRight,
  faArrowsRotate,
  faSquareCheck
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faDash,
  faPlusLarge,
  faPlus,
  faMinus,
  faCheck,
  faStar,
  faCircleCheck as faCircleCheckFull,
  faChevronUp,
  faChevronDown,
  faCircleX,
  faCircleQuestion,
  faTriangleExclamation,
  faCaretDown,
} from "@fortawesome/pro-solid-svg-icons";
// only import and use the icons that you need
library.add(
  faBars,
  faAngleDown,
  faBell,
  faBadgeCheck,
  faAngleLeft,
  faDash,
  faPlusLarge,
  faCheck,
  faStar,
  faArrowDownToLine,
  faCircleExclamation,
  faCircleInfo,
  faAngleRight,
  faTriangleExclamation,
  faXmark,
  faCircleCheck,
  faCircleXmark,
  faArrowUpToLine,
  faFilePlus,
  faCircleCheckFull,
  faAngleUp,
  faChevronUp,
  faChevronDown,
  faCircleX,
  faCircleQuestion,
  faCircleCheckFull,
  faAngleUp,
  faArrowRight,
  faEllipsis,
  faPlus,
  faMinus,
  faLayerGroup,
  faCaretDown,
  faGear,
  faSpinnerThird,
  faCircleArrowUpRight,
  faCircleArrowDownRight,
  faArrowsRotate,
  faSquareCheck
);
export default FontAwesomeIcon;

/**
 * in Vue3 you must use the component like this <font-awesome-icon :icon="['fal', 'calendar-arrow-up']"></font-awesome-icon>
 */
