<script setup lang="ts">
import { computed } from "vue";
interface Props {
  id: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  modelValue?: any;
  invalid?: boolean;
  errorMessage?: string;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "Placeholder",
  disabled: false,
  readonly: false,
  invalid: false,
  modelValue: "",
  errorMessage: "Error",
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
    emit("keyPress", value);
  },
});

const emit = defineEmits<{
  (e: "update:modelValue", payload: any): void;
  (e: "keyPress", payload: string | number | null): void;
}>();

const invalidCss = computed(() => {
  return props.invalid ? "border-red" : "border-grey-300";
});
</script>
<template>
  <div class="relative">
    <textarea
      :id="id"
      :data-cy="id"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :class="[invalidCss]"
      class="w-full h-24 xl:h-40 border p-2 lg:p-3 text-xs lg:text-sm placeholder:text-grey-600 disabled:cursor-not-allowed disabled:text-grey-500 disabled:bg-grey-400 focus:outline-none read-only:bg-grey-200"
      v-model="value"
    ></textarea>
    <div v-show="invalid" class="text-red text-xs md:text-sm text-left absolute">
      {{ errorMessage }}
    </div>
  </div>
</template>
