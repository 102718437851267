<script setup lang="ts">
import { ButtonTabTheme } from "../../libs/types/AppTypes";

interface Props {
  tabs: unknown[];
  theme?: ButtonTabTheme;
  active: number;
  disabled?: number[];
}

const props = withDefaults(defineProps<Props>(), {
  theme: ButtonTabTheme.Yellow,
  active: 0,
  disabled: () => [],
});

const onTabClick = (tabIdx: number) => {
  if (!props.disabled.includes(tabIdx)) emit("changeTab", tabIdx);
};

const emit = defineEmits<{ (event: "changeTab", payload: number): void }>();

const activeCss = (idx: number) => {
  switch (props.theme) {
    case ButtonTabTheme.Yellow:
      return idx === props.active ? "bg-yellow border-transparent" : "bg-white border-grey-400";

    case ButtonTabTheme.Black:
      return idx === props.active
        ? "bg-black border-transparent text-white"
        : "bg-white border-grey-400";

    case ButtonTabTheme.Transparent:
      return idx === props.active ? "bg-grey-200" : "";

    default:
      return idx === props.active ? "bg-yellow border-transparent" : "bg-white border-grey-400";
  }
};

const disabledCss = (idx: number) =>
  props.disabled.includes(idx) ? "!bg-grey-200 !text-grey-500 cursor-not-allowed" : "";
</script>
<template>
  <section>
    <button
      v-for="(item, idx) in tabs"
      :key="idx"
      :id="`button-tab-${idx}`"
      :data-cy="`button-tab-${idx}`"
      :class="[activeCss(idx), theme, disabledCss(idx)]"
      class="text-xs"
      @click="onTabClick(idx)"
    >
      {{ item }}
    </button>
  </section>
</template>
