<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { computed, inject } from "vue";
import type { UserSession } from "../services/UserSession/UserSession";
import type { Constants } from "../services/Constants/Constants";

const session = inject("userSession") as UserSession;
const constants = inject("constants", {}) as Constants; // {} is a default

const fullName = computed(() => `${constants.userInfo.firstName} ${constants.userInfo.lastName}`);

const initials = computed(() => {
  return constants.userInfo
    ? `${constants.userInfo.firstName[0]}${constants.userInfo.lastName[0]}`
    : null;
});

const onLogout = () => session.logout();
</script>
<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="inline-flex justify-between w-full rounded-md py-2 text-sm font-medium focus:outline-none"
      >
        <div class="flex items-center">
          <div class="flex items-center">
            <div class="rounded-full bg-blue-200 text-white p-2 flex items-center justify-center">
              {{ initials }}
            </div>
          </div>
        </div>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-96 origin-top-left bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }" class="mb-2">
            <div href="#" class="w-100" :class="[active ? '' : 'text-grey-700', 'block px-4 py-2']">
              <div class="flex">
                <div
                  class="rounded-full w-16 h-16 bg-blue-200 text-white text-2xl flex items-center justify-center mr-6"
                >
                  {{ initials }}
                </div>
                <div>
                  <h4 class="font-bold text-base">{{ fullName }}</h4>
                  <p class="text-sm text-grey-600 mb-4">{{ constants.userInfo.email }}</p>
                  <p class="text-sm underline">My account</p>
                </div>
              </div>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" class="border-t mt-2 border-grey-300">
            <div href="#" class="w-100" :class="[active ? '' : 'text-gray-700', 'block px-4 py-2']">
              <h6 class="text-sm cursor-pointer" @click="onLogout">Log out</h6>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
