<script lang="ts" setup>
import { AuctionCarAnalysisValidationStatus } from "@/libs/types/AppTypes";
import { computed } from "vue";

interface IProps {
  status: string | null;
  id: string | number;
}

const props = defineProps<IProps>();

const colorCss = computed(() => {
  if (props.status === AuctionCarAnalysisValidationStatus.PASSED) return "text-green";
  if (props.status === AuctionCarAnalysisValidationStatus.NOT_PASSED) return "text-red";
  if (props.status === AuctionCarAnalysisValidationStatus.PARTIAL_PASSED) return "text-orange";
  return "text-grey-700";
});

const iconCss = computed(() => {
  if (props.status === AuctionCarAnalysisValidationStatus.PASSED) return "circle-check";
  if (props.status === AuctionCarAnalysisValidationStatus.NOT_PASSED) return "circle-x";
  if (props.status === AuctionCarAnalysisValidationStatus.PARTIAL_PASSED) return "circle-check";
  return "circle-question";
});
</script>
<template>
  <div>
    <font-awesome-icon
      :id="`validation-pill-${id}`"
      :data-cy="`validation-pill-${id}`"
      :icon="['fas', iconCss]"
      :class="[colorCss]"
      class="cursor-pointer text-xl"
    />
  </div>
</template>
