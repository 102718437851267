<script setup lang="ts">
import { computed } from "vue";

interface Props {
  id: string;
  options: unknown[] | unknown;
  modelValue?: any;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  optionValue?: string | number;
  optionLabel?: string | number;
  errorMessage?: string;
  invalid?: boolean;
  showRemoveButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  readonly: false,
  id: "id",
  placeholder: "Placeholder",
  optionLabel: "",
  optionValue: "",
  modelValue: null,
  showRemoveButton: false
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const removeValue = () => {
  emit("update:modelValue", null);
};

const emit = defineEmits<{ (e: "update:modelValue", payload: any): void }>();

const invalidCss = computed(() => {
  return props.invalid ? "border-red" : "border-grey-400";
});
</script>
<template>
  <div>
    <div class="flex items-center">
      <select
        :id="id"
        :data-cy="id"
        :class="[invalidCss, readonly ? 'read-only:bg-grey-200 read-only:pointer-events-none' : '']"
        :disabled="disabled"
        class="w-full border bg-white pr-[36px] pl-2 pt-2 pb-2 lg:pl-3 lg:pt-3 lg:pb-3 truncate text-xs lg:text-sm focus:outline-none disabled:text-grey-500 disabled:bg-grey-200 disabled:cursor-not-allowed appearance-none"
        v-model="value"
      >
        <option selected disabled :value="null">
          {{ placeholder }}
        </option>
        <template v-if="optionValue && optionLabel">
          <option v-for="(option, idx) in options" :key="idx" :value="option[optionValue]">
            {{ option[optionLabel] }}
          </option>
        </template>
        <template v-else>
          <option v-for="(option, idx) in options" :key="idx" :value="option">
            {{ option }}
          </option>
        </template>
      </select>
      <div class="flex items-center" :class="{ '-ml-10': value && showRemoveButton, '-ml-6': !value || !showRemoveButton}">
        <font-awesome-icon
          v-if="showRemoveButton && value"
          class="icon-remove icon w-[.6rem] cursor-pointer"
          :icon="['far', 'xmark']"
          @click="removeValue"
        >
        </font-awesome-icon>
        <font-awesome-icon :icon="['fas', 'angle-down']" class="icon w-[.7rem]"></font-awesome-icon>
      </div>
    </div>
    <div v-show="invalid" class="text-red text-xs lg:text-sm text-left absolute">
      {{ errorMessage }}
    </div>
  </div>
</template>
<style scoped>
select:disabled ~ .icon {
  color: #d9d9d9;
}

.icon-remove {
  margin-right: 7px;
}
</style>
