<script lang="ts" setup>
import { Chart, registerables, type LayoutPosition } from "chart.js";
import { debounce } from "lodash";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
Chart.register(...registerables);

export type DoughnutData = { label: string; percent: number };

interface IProps {
  data: DoughnutData[];
  id: string;
  colors?: string[];
  legendPlacement?: LayoutPosition;
  inactiveDatasets?: number[];
}

const props = withDefaults(defineProps<IProps>(), {
  data: () => [],
  id: "doughnut-chart",
  colors: () => ["#000000", "#FFD100", "#308DFF", "#00E3AD", "#C1C1C1", "#ff6347"],
  legendPlacement: "bottom",
  inactiveDatasets: () => [],
});

watch(
  () => props.data,
  () => {
    updateChart();
  },
);

const doughnut = ref();
let chart: Chart<"doughnut", number[], string>;

onMounted(() => {
  drowChart();
  window.addEventListener("resize", rezieChart);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", rezieChart);
});

watch(
  () => props.inactiveDatasets,
  () => {
    datasets();
    updateChart();
  },
);

const rezieChart = debounce(() => {
  chart.resize();
}, 500);

const lablels = computed(() => props.data.map((data) => data.label));

function datasets() {
  const datasets = structuredClone(props.data);

  if (props.inactiveDatasets.length > 0) {
    return datasets
      .map((data) => data.percent)
      .map((data, idx) => (props.inactiveDatasets.includes(idx) ? NaN : data));
  }

  return props.data.map((data) => data.percent);
}

const drowChart = () => {
  chart = new Chart(doughnut.value, {
    type: "doughnut",
    data: {
      labels: lablels.value,
      datasets: [
        {
          label: "Hertz data",
          data: datasets(),
          backgroundColor: props.colors,
          hoverOffset: 4,
          // to be more design accurate
          borderColor: "#F0F0F0",
          borderAlign: "inner",
          borderWidth: 1,
        },
      ],
    },
    options: {
      spacing: 0,
      responsive: true,
      onResize: (ctx) => ctx.update(),
      plugins: {
        legend: {
          position: props.legendPlacement,
          labels: {
            boxWidth: 10,
            font: {
              size: 12,
              weight: "400",
              family: "Ride",
            },
            padding: 10,
          },
        },
      },
    },
  });
};

const updateChart = () => {
  chart.data.datasets[0].data = datasets();
  chart.data.labels = lablels.value;
  chart.update();
};
</script>
<template>
  <section style="width: 100%; height: auto; position: relative">
    <canvas :id="id" :data-cy="id" ref="doughnut" height="500" width="500"></canvas>
  </section>
</template>
