import saveAs from "file-saver";
import { decode } from "base64-arraybuffer";
import toastNotificationService, {
  ToastNotificationType,
} from "@/services/ToastNotification/ToastNotification";
import {
  AuctionStatus,
  BeforeAuctionAnalysisStatus,
  CarAuctionStatus,
  PillTheme,
  ServiceHistory,
  VinStatus,
} from "@/libs/types/AppTypes";
import moment, { type MomentInput } from "moment";
import { uniqueId } from "lodash";

export const dateDDMMYY = <T extends MomentInput>(date: T): string => {
  if (!date) return "n/a";
  return moment.utc(date).local().format("DD/MM/YYYY");
};

export const dateDMYHMS = <T extends MomentInput>(date: T): string => {
  if (!date) return "n/a";
  return moment.utc(date).local().format("DD/MM/YYYY[-]hh:mm a");
};

export const calculateDaysToDate = (date: string): string | number => {
  if (!date) return "n/a";

  const now = new Date().getTime();
  const secondsInDay = 86400;
  const then = Date.parse(date);

  return Math.floor((then - now) / 1000 / secondsInDay);
};

export const formatAvailableCountries = (country: string[]): string =>
  country.reduce((prev, curr) => `${prev}, ${curr}`);

export const handleInputPrice = (price: number) => price / 100;

export const handleOutputPrice = (price: number) => price * 100;

export const numberToCommaFormat = (unformattedNo: number | string) => {
  if (!unformattedNo) return unformattedNo;
  return unformattedNo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function onDownloadFile(name: string, file: any, type: any) {
  const list = new File([decode(file)], name, { type: type });
  saveAs(list, name);
}

export const truncateString = (string = "", maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

export function toastSuccess(message: string): void {
  toastNotificationService.signal$.next({
    type: ToastNotificationType.Success,
    message,
  });
}

export function toastError(message: string): void {
  toastNotificationService.signal$.next({
    type: ToastNotificationType.Error,
    message,
  });
}

export function toastWarning(message: string): void {
  toastNotificationService.signal$.next({
    type: ToastNotificationType.Warning,
    message,
  });
}

export function isNullValue(value: any, noDecimals?: number) {
  if (value) {
    return noDecimals ? value.toFixed(noDecimals) : value;
  }
  return "-";
}

export function auctionStatusColor(status: AuctionStatus) {
  switch (status) {
    case AuctionStatus.INITIATED:
      return PillTheme.Yellow;
    case AuctionStatus.PUBLISHED:
      return PillTheme.Blue;
    case AuctionStatus.ANALYSING:
      return PillTheme.Red;
    case AuctionStatus.ANALYSED:
      return PillTheme.Brown;
    case AuctionStatus.READY_FOR_AUCTION:
      return PillTheme.Green;
    case AuctionStatus.AUCTION_FINALIZED:
      return PillTheme.DarkGreen;
    case AuctionStatus.POST_ANALYSING:
      return PillTheme.MediumBlue;
    case AuctionStatus.POST_ANALYSED:
      return PillTheme.DarkBlue;
    case AuctionStatus.EXPIRED:
      return PillTheme.DarkGrey;
    case AuctionStatus.DELETED:
      return PillTheme.Pink;
    case AuctionStatus.FAILED_ANALYSIS:
      return PillTheme.Red;
  }
}

export function dateTomorrow() {
  const today = moment();

  return moment(today.add(1, "days")).toDate();
}

export function dateToday() {
  return moment().toDate();
}

export function vinStatusColor(status: VinStatus) {
  switch (status) {
    case VinStatus.DECODED:
      return PillTheme.Green;
    case VinStatus.FAILED:
      return PillTheme.Red;
    case VinStatus.UNATTEMPTED:
      return PillTheme.DarkGrey;
    default:
      return PillTheme.DarkGrey;
  }
}

export function serviceHistoryStatusColor(status: ServiceHistory) {
  switch (status) {
    case ServiceHistory.PRESENT:
      return PillTheme.Green;
    case ServiceHistory.PRESENT_UNRECORDED:
      return PillTheme.LightGreen;
    case ServiceHistory.INCOMPLETE:
      return PillTheme.Orange;
    case ServiceHistory.MISSING:
      return PillTheme.Red;
  }
}

export function formatYesNoResponse(status: boolean) {
  return status ? "Yes" : "No";
}

export function analysisStatusColor(status: string) {
  if (status === CarAuctionStatus.INITIATED) return PillTheme.DarkGrey;
  if (status === CarAuctionStatus.REFUSED) return PillTheme.Red;
  if (status === CarAuctionStatus.ANALYSED) return PillTheme.Green;
  if (status === CarAuctionStatus.ACCEPTED) return PillTheme.Blue;
  if (status === CarAuctionStatus.IDENTIFIED) return PillTheme.DarkBlue;
  if (status === CarAuctionStatus.IMPORTED) return PillTheme.DarkGrey;
  if (status === CarAuctionStatus.POST_ANALYSED) return PillTheme.Yellow;

  return PillTheme.Red;
}

export function beforeAuctionAnalysisStatusColor(status: string) {
  if (status === BeforeAuctionAnalysisStatus.FAILED) return PillTheme.Red;
  if (status === BeforeAuctionAnalysisStatus.NONE) return PillTheme.GreyWhite;
  if (status === BeforeAuctionAnalysisStatus.NOT_VALID) return PillTheme.Orange;
  if (status === BeforeAuctionAnalysisStatus.PARTIAL_VALID) return PillTheme.LightGreen;
  return PillTheme.Green;
}

export function acceptanceStatusColor(status: string) {
  if (status === "a") return PillTheme.Red;
  return PillTheme.Green;
}

export function generateUniqueId(prefix?: string): string {
  return uniqueId(prefix);
}

export function calculateRoundPercentage(fraction: number, total: number) {
  const percentage = (fraction * 100) / total;

  return Math.round(percentage * 10) / 10; // round to one decimal
}

// generate iso strings for every 1st day of the month starting firstOct2022
export function generateMonthRange(): string[] {
  const range: string[] = [];

  // since i don't like working with dates,
  // I will generate the date range using iso strings
  const firstOct2022 = new Date("2022-10-01T00:00:00.000");
  const firstMonth = firstOct2022.getMonth() + 1;
  const firstYear = firstOct2022.getFullYear();

  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  //first generate isodates from the begining to the end of the year
  for (let i = firstMonth; i <= 12; i++) {
    range.push(`2022-${i}-01T00:00:00.000`);
  }

  //then start from the begining of the next year
  for (let i = firstYear + 1; i <= currentYear; i++) {
    // if the first year is less then current year, then generate dates until the end of the year
    if (i < currentYear) {
      for (let j = 1; j <= 12; j++) {
        range.push(`${i}-${j > 9 ? `${j}` : `0${j}`}-01T00:00:00.000`);
      }
    }
    //if the next year is the current year then generate dates to the current month
    else if (i === currentYear) {
      for (let j = 1; j <= currentMonth; j++) {
        range.push(`${i}-${j > 9 ? `${j}` : `0${j}`}-01T00:00:00.000`);
      }
    }
  }

  return range;
}

export function removeNullOrEmptyProp(obj: Record<string, any>) {
  for (const key in obj) {
    if (obj[key] === false) {
      delete obj[key];
    }

    if (obj[key] === null) {
      delete obj[key];
    } else {
      if (typeof obj[key] === "object") {
        // if is an empty array delete it
        if (Array.isArray(obj[key]) && obj[key].length === 0) delete obj[key];
        //if is an empty object delete it
        else if (Object.keys(obj[key]).length === 0) delete obj[key];
        //else call the fn again
        else removeNullOrEmptyProp(obj[key]);
      }
    }
  }
  //after removing each null prop check to see if there are some empty objects after prop deletion
  //because i did not manage to remove it within the loop
  const containsEmptyObj = Object.values(obj).some(
    (i) => typeof i === "object" && i.constructor === Object && Object.keys(i).length === 0,
  );

  //if there are just call the fn recursively
  if (containsEmptyObj) {
    removeNullOrEmptyProp(obj);
  }

  return obj;
}
