<script lang="ts" setup>
import { ButtonTheme, TapedCardTheme } from "../libs/types/AppTypes";
import { useMsal } from "@/composables/useMsal";

const { instance } = useMsal();
const openLoginRedirect = () => instance.loginRedirect();
</script>
<template>
  <main class="bg-grey-800 h-screen flex items-center">
    <div class="mx-auto min-w-[40rem]">
      <AppTapedCard :theme="TapedCardTheme.Grey">
        <template #header>
          <div class="py-3 flex justify-between">
            <section class="flex items-center">
              <img
                class="cursor-pointer"
                src="../assets/img/hertz-logo-black.svg"
                width="77"
                height="21"
                alt="hertz logo"
                aria-details="logo"
              />
              <h2 class="text-base border-l-2 border-black ml-3 pl-3">Admin web</h2>
            </section>
          </div>
        </template>
        <template #body>
          <div class="text-center">
            <h1 class="font-bold text-xl mb-5 xl-mb-9">Admin Web - Log in</h1>
            <p class="mb-9 xl:mb-10">
              Hertz Admin Web is the centralized onboarding and account <br />management tool for
              Hertz admins.
            </p>
            <AppActionButton
              id="login-to-admin"
              class="mb-3"
              label="Log in to admin account"
              :theme="ButtonTheme.Yellow"
              @click="openLoginRedirect"
            />
            <button class="text-sm block mx-auto">Login help</button>
          </div>
        </template>
      </AppTapedCard>
    </div>
  </main>
</template>
