import { filter, from, interval, mergeMap, Subject } from "rxjs";
import type { Router } from "vue-router";
import { msalInstance, loginRequest } from "@/auth/authConfig";

interface IJwtParsed {
  exp: number;
  id?: string;
}

export enum SesstioStatus {
  OPEN = "Open",
  CLOSED = "Closed",
}

export class UserSession {
  private authenticated$ = new Subject<SesstioStatus>();
  private tokenWasRefreshedSink$ = new Subject<string>();

  constructor(private router: Router) {
    const timer = interval(1000).subscribe(() => {
      this.authenticated$.next(
        this.tokenIsNotExpiredOrMissing() ? SesstioStatus.OPEN : SesstioStatus.CLOSED,
      );
    });

    this.sessionStatus$
      .pipe(filter((status) => status === SesstioStatus.CLOSED))
      .pipe(mergeMap(() => this.acquireSilentLogin$()))
      .subscribe({
        next: (response) => {
          localStorage.setItem("b2cToken", response.idToken);
          this.tokenWasRefreshed$.next("");
        },
        error: () => {
          //show logout pop up window if no logged in accounts
          if (msalInstance.getAllAccounts().length) {
            this.logout();
          }

          this.authenticated$.complete();
          timer.closed = true;
        },
      });
  }

  get isAuthenticated(): boolean {
    return this.tokenIsNotExpiredOrMissing();
  }

  get sessionStatus$() {
    return this.authenticated$;
  }

  get tokenWasRefreshed$() {
    return this.tokenWasRefreshedSink$;
  }

  logout() {
    msalInstance.logoutPopup();
    localStorage.removeItem("b2cToken");
    this.router.push({ name: "Login" });
  }

  private parseJwt(token: string): IJwtParsed | null {
    if (!token) return null;

    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  }

  private tokenExpirationDate(): number | null {
    const token = localStorage.getItem("b2cToken");

    if (!token) return null;
    return this.parseJwt(token)!.exp * 1000;
  }

  private tokenIsNotExpiredOrMissing(): boolean {
    if (!this.tokenExpirationDate()) return false;

    const now = new Date(new Date().toUTCString()).getTime();
    const tokenDate = new Date(
      new Date(this.tokenExpirationDate() as number).toUTCString(),
    ).getTime();
    const oneMinute = 60 * 1000;
    // if the session is still active get a new token 1 minute before it expires
    return now + oneMinute < tokenDate;
  }

  private acquireSilentLogin$() {
    return from(msalInstance.acquireTokenSilent({ ...loginRequest }));
  }
}
