import * as Sentry from "@sentry/vue";
import type { App } from "vue";
import type { Router } from "vue-router";


export default function sentryInit(app: App, router: Router) {
  Sentry.init({
    app,
    dsn: "https://85b0b627b655f78d81d0610b5378a367@o4508289854734336.ingest.de.sentry.io/4508324299931728",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/admin.assetmanagement\.site/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
