<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

import { computed } from "vue";
import { useRoute } from "vue-router";
import { router } from "@/router/routes";

const links = {
  userManagement: ["RulesAndPermissions", "AdminUserManagement", "SignInLogs"],
};

const route = useRoute();

const currentRoute = computed(() => {
  return route.name as string;
});

const goToRulesAndPermissions = () => router.push({ name: "RulesAndPermissions" });
const goToAdminUserManagement = () => router.push({ name: "AdminUserManagement" });
const goToSignInLogs = () => router.push({ name: "SignInLogs" });

</script>
<template>
  <Menu as="div" class="relative inline-block text-left mr-2">
    <div>
      <MenuButton
        class="inline-flex justify-between w-full rounded-md py-2 text-sm font-medium focus:outline-none"
      >
        <div class="flex items-center">
          <div class="flex items-center">
            <div class="text-white p-2 flex items-center justify-center">
              <font-awesome-icon
                :icon="['far', 'gear']"
                class="icon text-lg"
                :class="[
                  links.userManagement.includes(currentRoute) ? 'text-yellow' : 'text-white',
                ]"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-56 origin-top-left bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <MenuItem
          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
        >
          <div href="#" class="w-100 text-gray-700 block px-4 py-2">
            <h6 class="text-sm cursor-pointer" @click="goToAdminUserManagement">
              Admin user management
            </h6>
          </div>
        </MenuItem>
        <MenuItem
          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
        >
          <div href="#" class="w-100 text-gray-700 block px-4 py-2">
            <h6 class="text-sm cursor-pointer" @click="goToRulesAndPermissions">
              Roles and permissions
            </h6>
          </div>
        </MenuItem>
        <MenuItem
          class="w-full text-sm block px-4 py-3 text-black hover:text-grey-700 border-b border-grey-300"
        >
          <div href="#" class="w-100 text-gray-700 block px-4 py-2">
            <h6 class="text-sm cursor-pointer" @click="goToSignInLogs">Sign in logs</h6>
          </div>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>
