<script setup lang="ts">
import { computed } from "vue";

interface IProps {
  size?: number;
  color: string;
}
const props = withDefaults(defineProps<IProps>(), {
  size: 16,
  color: "text-red",
});

const sizeCss = computed(() => {
  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
  };
});
</script>
<template>
  <div :class="color" :style="sizeCss" class="rounded-full"></div>
</template>
