<script setup lang="ts">
import { computed } from "vue";

interface Props {
  id?: string;
  modelValue?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  id: "id",
  disabled: false,
  modelValue: false,
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const emit = defineEmits<{ (e: "update:modelValue", payload: boolean): void }>();
</script>
<template>
  <label class="checkbox-container cursor-pointer flex items-center my-3" :for="id">
    <input
      type="checkbox"
      :id="id"
      :data-cy="id"
      class="hidden h-0 w-0 opacity-0"
      :disabled="disabled"
      v-model="value"
    />
    <span
      class="checkmark w-[1rem] h-[1rem] min-h-[1rem] min-w-[1rem] lg:min-h-[1.3rem] lg:min-w-[1.3rem] lg:w-[1.3rem] lg:h-[1.3rem] rounded bg-white border-blue border-2 after:bg-orange disabled:after:bg-red after:hidden after:w-[.6rem] after:h-[.6rem] after:m-[.08rem] lg:after:w-[.9rem] lg:after:h-[.9rem] lg:after:m-[.09rem]"
    ></span>
    <span class="label">
      <slot></slot>
    </span>
  </label>
</template>
<style scoped>
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
  background-color: #308dff;
  border-radius: 0.25rem;
}

/* Style the checkmark/indicator */

.checkbox-container input:disabled ~ .checkmark {
  border: 0.1rem solid #d7d7d7;
  background-color: #fff;
  cursor: not-allowed;
}

.checkbox-container input:disabled ~ .label {
  color: #d7d7d7;
  cursor: not-allowed;
}

.checkbox-container input:checked:disabled ~ .checkmark:after {
  border: 0.09rem solid #d9d9d9;
  cursor: not-allowed;
  background-color: #d7d7d7;
}
</style>
