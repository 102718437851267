import { userSessionService } from "../main";
import type { Router } from "vue-router";

export default function configureRouterHooks(router: Router) {
  //if the route requires auth and is not logged in go to Login route
  router.beforeEach((to) => {
    if (
      to.matched.some((route) => route.meta.requiresAuth) &&
      !userSessionService.isAuthenticated
    ) {
      return { name: "Login" };
    } else return true;
  });

  // if the user is logged in do not access below routes
  router.beforeEach((to) => {
    const unreachableRoutes = ["Login"];
    if (userSessionService.isAuthenticated && unreachableRoutes.includes(to.name as string)) {
      return { name: "Home" };
    }

    return true;
  });

  // set page title on each route change
  router.beforeEach((to) => {
    document.title = `Hertz - ${to.meta.title}`;
    return true;
  });
}
