<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

interface Props {
  id: string;
  modalActive: boolean;
  title?: string;
  customCss?: string;
}

withDefaults(defineProps<Props>(), {
  id: "id",
  modalActive: false,
  title: "Title",
});

const emits = defineEmits(["closeModal"]);

function close() {
  emits("closeModal");
}
</script>
<template>
  <TransitionRoot as="template" :show="modalActive" :id="id" :data-cy="id">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity"></div>
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto custom-scrollbar">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden bg-white text-left shadow-xl transition-all p-9 sm:my-8 sm:w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl"
              :class="[customCss]"
            >
              <div class="flex justify-between">
                <DialogTitle as="h3" class="text-lg leading-6">{{ title }}</DialogTitle>
                <font-awesome-icon :icon="['far', 'xmark']" @click="close" class="cursor-pointer">
                </font-awesome-icon>
              </div>

              <!-- body modal content -->
              <section class="pt-9">
                <slot name="body"></slot>
              </section>

              <div class="mt-5 sm:mt-12 mb-2 sm:flex justify-between">
                <slot name="footer"></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
