import { catchError, from, lastValueFrom, of, zip } from "rxjs";
import type {
  ICarGroups,
  ITranslatedConstant,
  IDepreciationPeriods,
  ICountry,
  IUserInfoResponse,
} from "../../libs/types/AppTypes";
import { userSessionService } from "../../main";
import type { HttpClient } from "../HttpClient/HttpClient";
import { permissions } from "@/store/permissions";

export class Constants {
  private userDetails = {} as IUserInfoResponse;
  private allDepreciationPeriods: number[] = [];
  private allCarGroups: ICarGroups[] = [];
  private allOfferTypes: ITranslatedConstant[] = [];
  private allCarSegments: ITranslatedConstant[] = [];
  private allRegistrationStatuses: ITranslatedConstant[] = [];
  private allColors: ITranslatedConstant[] = [];
  private allHoldingPeriods: number[] = [];
  private allCarOfferStatuses: ITranslatedConstant[] = [];
  private allOfferStatuses: ITranslatedConstant[] = [];
  private allDepreciationStatuses: ITranslatedConstant[] = [];
  private allCountriesFleet: ICountry[] = [];
  private allCountries: ICountry[] = [];
  private allOfferValidationStatuses: ITranslatedConstant[] = [];
  private allDocumentTypes: ITranslatedConstant[] = [];
  private allAccessories: ITranslatedConstant[] = [];
  private allAuctionStatuses: ITranslatedConstant[] = [];
  private allCarAuctionStatuses: ITranslatedConstant[] = [];
  private allCosmeticGradings: ITranslatedConstant[] = [];
  private allMechanicalGradings: ITranslatedConstant[] = [];
  private allServiceHistory: ITranslatedConstant[] = [];
  private allVatTypes: ITranslatedConstant[] = [];
  private allVinStatuses: ITranslatedConstant[] = [];
  private allSalesChannels: ITranslatedConstant[] = [];
  private allSalesTypes: ITranslatedConstant[] = [];
  private allBidPeriods: number[] = [];

  constructor(private http: HttpClient) {}

  async fetchConstants(): Promise<unknown> {
    const requests = [
      from(this.fetchUserInfo()),
      from(this.fetchDepreciationPeriods()),
      from(this.fetchCarGroups()),
      from(this.fetchCarGroups()),
      from(this.fetchOfferTypes()),
      from(this.fetchCarSegments()),
      from(this.fetchRegistrationStatuses()),
      from(this.fetchColors()),
      from(this.fetchHoldingPeriods()),
      from(this.fetchCarStatuses()),
      from(this.fetchOfferStatuses()),
      from(this.fetchDepreciationStatuses()),
      from(this.fetchCountries()),
      from(this.fetchAllCountries()),
      from(this.fetchOfferValidationStatuses()),
      from(this.fetchDocumentTypes()),
      from(this.fetchAccessories()),
      from(this.fetchAuctionStatuses()),
      from(this.fetchCosmeticGrading()),
      from(this.fetchMechanicalGrading()),
      from(this.fetchServiceHistory()),
      from(this.fetchVatType()),
      from(this.fetchVinStatuses()),
      from(this.fetchBidPeriods()),
      from(this.fetchCarAuctionStatuses()),
      from(this.fetchSalesChannels()),
      from(this.fetchSalesTypes()),
    ];

    return await lastValueFrom(
      zip(requests).pipe(
        //logout if there is another incompatible token
        catchError((err) => {
          userSessionService.logout();
          return of(err);
        }),
      ),
    );
  }

  get userInfo() {
    return this.userDetails;
  }

  get depreciationPeriods(): IDepreciationPeriods[] {
    return this.allDepreciationPeriods.map((interval) => {
      return {
        label: `${interval} Months`,
        value: interval,
      };
    });
  }

  get carGroups(): ICarGroups[] {
    return this.allCarGroups;
  }

  get offerTypes(): ITranslatedConstant[] {
    return this.allOfferTypes;
  }

  get carSegments(): ITranslatedConstant[] {
    return this.allCarSegments;
  }

  get registrationStatuses(): ITranslatedConstant[] {
    return this.allRegistrationStatuses;
  }

  get colors(): ITranslatedConstant[] {
    return this.allColors;
  }

  get holdingPeriods(): ITranslatedConstant[] {
    return this.allHoldingPeriods.map((period, idx) => {
      return {
        code: `${period}`,
        label: `${period} ${idx === 0 ? "Month" : "Months"}`,
      };
    });
  }

  get carOfferStatuses(): ITranslatedConstant[] {
    return this.allCarOfferStatuses;
  }

  get offerStatuses(): ITranslatedConstant[] {
    return this.allOfferStatuses;
  }

  get depreciationStatuses(): ITranslatedConstant[] {
    return this.allDepreciationStatuses;
  }

  get countries(): ICountry[] {
    return this.allCountriesFleet;
  }

  get getAllCountries(): ICountry[] {
    return this.allCountries;
  }

  get offerValidationStatuses(): ITranslatedConstant[] {
    return this.allOfferValidationStatuses;
  }

  get documentTypes(): ITranslatedConstant[] {
    return this.allDocumentTypes;
  }

  get accessories(): ITranslatedConstant[] {
    return this.allAccessories;
  }

  get auctionStatuses(): ITranslatedConstant[] {
    return this.allAuctionStatuses;
  }

  get cosmeticGradings(): ITranslatedConstant[] {
    return this.allCosmeticGradings;
  }

  get mechanicalGradings(): ITranslatedConstant[] {
    return this.allMechanicalGradings;
  }

  get serviceHistory(): ITranslatedConstant[] {
    return this.allServiceHistory;
  }

  get vatTypes(): ITranslatedConstant[] {
    return this.allVatTypes;
  }

  get vinStatuses(): ITranslatedConstant[] {
    return this.allVinStatuses;
  }

  get maxBidPeriods(): number[] {
    return this.allBidPeriods;
  }

  get carAuctionStatuses(): ITranslatedConstant[] {
    return this.allCarAuctionStatuses;
  }

  get salesChannels(): ITranslatedConstant[] {
    return this.allSalesChannels;
  }

  get salesTypes(): ITranslatedConstant[] {
    return this.allSalesTypes;
  }

  private async fetchUserInfo() {
    return this.http.getUserInfo().then((response) => {
      this.userDetails = response;
      //also set permissions to pseudo "store"
      permissions.list = response.grantedAuthorities;
    });
  }

  private async fetchDepreciationPeriods() {
    return this.http
      .getDepreciationPeriods()
      .then((response) => (this.allDepreciationPeriods = response));
  }

  private async fetchCarGroups() {
    return this.http.getCarGroups().then((response) => (this.allCarGroups = response));
  }

  private async fetchOfferTypes() {
    return this.http.getOfferTypes().then((response) => (this.allOfferTypes = response));
  }

  private async fetchCarSegments() {
    return this.http.getCarSegments().then((response) => (this.allCarSegments = response));
  }

  private async fetchRegistrationStatuses() {
    return this.http
      .getRegistrationStatus()
      .then((response) => (this.allRegistrationStatuses = response));
  }

  private async fetchColors() {
    return this.http.getColors().then((response) => (this.allColors = response));
  }

  private async fetchHoldingPeriods() {
    return this.http.getHoldingPeriods().then((response) => (this.allHoldingPeriods = response));
  }

  private async fetchCarStatuses() {
    return this.http
      .getCarOfferStatuses()
      .then((response) => (this.allCarOfferStatuses = response));
  }

  private async fetchOfferStatuses() {
    return this.http.getOfferStatuses().then((response) => (this.allOfferStatuses = response));
  }

  private async fetchDepreciationStatuses() {
    return this.http
      .getDepreciationStatuses()
      .then((response) => (this.allDepreciationStatuses = response));
  }

  private async fetchCountries() {
    return this.http.getCountries().then((response) => (this.allCountriesFleet= response));
  }

  private async fetchAllCountries() {
    return this.http.getAllCountries().then((response) => (this.allCountries = response));
  }

  private async fetchOfferValidationStatuses() {
    return this.http
      .getOfferValidationStatuses()
      .then((response) => (this.allOfferValidationStatuses = response));
  }

  private async fetchDocumentTypes() {
    return this.http.getDocumentTypes().then((response) => (this.allDocumentTypes = response));
  }

  private async fetchAccessories() {
    return this.http.getAccessories().then((response) => (this.allAccessories = response));
  }

  private async fetchAuctionStatuses() {
    return this.http.getActionStatuses().then((response) => (this.allAuctionStatuses = response));
  }

  private async fetchCosmeticGrading() {
    return this.http
      .getCarCosmeticGrading()
      .then((response) => (this.allCosmeticGradings = response));
  }

  private async fetchMechanicalGrading() {
    return this.http
      .getCarMechanicalGrading()
      .then((response) => (this.allMechanicalGradings = response));
  }

  private async fetchServiceHistory() {
    return this.http.getCarServiceHistory().then((response) => (this.allServiceHistory = response));
  }

  private async fetchVatType() {
    return this.http.getCarVatTypes().then((response) => (this.allVatTypes = response));
  }

  private async fetchVinStatuses() {
    return this.http.getVinStatus().then((response) => (this.allVinStatuses = response));
  }

  private async fetchBidPeriods() {
    return this.http.getAuctionMaxBidPeriods().then((response) => (this.allBidPeriods = response));
  }

  private async fetchCarAuctionStatuses() {
    return this.http
      .getCarAuctionStatuses()
      .then((response) => (this.allCarAuctionStatuses = response));
  }

  private async fetchSalesChannels() {
    return this.http.getSalesChannels().then((response) => (this.allSalesChannels = response));
  }

  private async fetchSalesTypes() {
    return this.http.getSalesTypes().then((response) => (this.allSalesTypes = response));
  }
}
