import { upperFirst, camelCase } from "lodash";
import FontAwesomeIcon from "@/libs/fontawesome-icons";

export default {
  install(app: { component: (arg0: string, arg1: any) => void }) {
    const componentFiles: Record<string, any> = import.meta.glob("./*.vue", { eager: true });

    // register all components globally that are in this folder
    Object.entries(componentFiles).forEach(([path, m]) => {
      const pathStr = path.split("/").pop() as string;
      const componentName = upperFirst(camelCase(pathStr.replace(/\.\w+$/, "")));

      app.component(`${componentName}`, m.default);
    });

    // register font awesome component globally
    app.component("font-awesome-icon", FontAwesomeIcon);
  },
};
