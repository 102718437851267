<script setup lang="ts">
import { computed, inject, onUpdated, onMounted, ref } from "vue";

interface IProps {
  idx: number;
}

const props = withDefaults(defineProps<IProps>(), {
  idx: 0,
});

onMounted(() => {
  pannelHeight.value = pannel.value!.scrollHeight;
});

onUpdated(() => {
  pannelHeight.value = pannel.value!.scrollHeight;
});

const pannel = ref<HTMLElement>();
const pannelHeight = ref(0);
const activeTabIdx = ref(inject("activeTab"));

const isActive = computed(() => props.idx === activeTabIdx.value);

const activeCss = computed(() => (isActive.value ? pannelHeight.value : 0));

const emit = defineEmits<{ (e: "tabChange", payload: number): void }>();

const onTabChange = (payload: number) => {
  isActive.value ? emit("tabChange", NaN) : emit("tabChange", payload);
};
</script>
<template>
  <div>
    <dl>
      <dt class="w-full flex items-center" @click="onTabChange(props.idx)">
        <div class="text-left text-sm w-full flex items-center cursor-pointer">
          <div class="pr-3">
            <font-awesome-icon
              v-if="!isActive"
              :icon="['fas', 'angle-down']"
              class="w-3"
            ></font-awesome-icon>
            <font-awesome-icon
              v-if="isActive"
              :icon="['fas', 'angle-up']"
              class="w-3"
            ></font-awesome-icon>
          </div>
          <div class="w-full">
            <slot name="title"></slot>
          </div>
        </div>
      </dt>
    </dl>

    <dd
      ref="pannel"
      :id="`id-accordion-${idx}`"
      :data-cy="`id-accordion-${idx}`"
      class="overflow-hidden transition-all duration-300 bg-white"
      :style="{ 'max-height': `${activeCss}px` }"
    >
      <div class="w-full pl-6 py-2">
        <slot name="body"></slot>
      </div>
    </dd>
  </div>
</template>
